.popTab {
    $indicator-width: 6px;

    display: flex;
    height: 6rem;
    width: 100%;
    justify-content: space-between;

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-left: $indicator-width;
        width: 100%;
        color: $disable-color;
        height: 100%;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            border-radius: 999px;
        }

        &--ok {
            color: $success-color;
            &::after {
                width: 24px;
                height: 24px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2356ccb4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
            }
        }

        &--active {
            color: $success-color;
            &::after {
                height: calc(100% - 0.5rem);
                background-color: $success-color;
                width: $indicator-width;
            }
        }
        &__legend {
            margin-right: 1rem;
            font-size: 1.75rem;
            font-weight: 600;
        }
    }
}

.tab-content {
    background-color: transparent !important;
}
