/* poppins-300-regular */
@font-face {
    font-family: 'Poppins_PL';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Poppins-Light.ttf');
}
/* poppins-300-italic */
@font-face {
    font-family: 'Poppins_PL';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/Poppins-LightItalic.ttf');
}
/* poppins-400-regular */
@font-face {
    font-family: 'Poppins_PL';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Poppins-Regular.ttf');
}
/* poppins-500-regular */
@font-face {
    font-family: 'Poppins_PL';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Poppins-Medium.ttf');
}
/* poppins-500-italic */
@font-face {
    font-family: 'Poppins_PL';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Poppins-MediumItalic.ttf');
}
/* poppins-600-regular */
@font-face {
    font-family: 'Poppins_PL';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/Poppins-SemiBold.ttf');
}
/* poppins-600-italic */
@font-face {
    font-family: 'Poppins_PL';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/Poppins-SemiBoldItalic.ttf');
}
/* poppins-700-regular */
@font-face {
    font-family: 'Poppins_PL';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Poppins-Bold.ttf');
}
/* poppins-700-italic */
@font-face {
    font-family: 'Poppins_PL';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/Poppins-BoldItalic.ttf');
}
/* poppins-800-regular */
@font-face {
    font-family: 'Poppins_PL';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/Poppins-ExtraBold.ttf');
}
/* poppins-800-italic */
@font-face {
    font-family: 'Poppins_PL';
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/Poppins-ExtraBoldItalic.ttf');
}

  .font-weight-bolder, .font-weight-800{
     font-weight:800;
 }
 
 .font-size-4rem{
     font-size:3.5rem;
     line-height:3rem;
 }
 
 .font-size-3rem{
     font-size:2.75rem;
     line-height:2.5rem;
 }

 .font-size-20px{
     font-size:20px;
 }
 
 .font-size-16px{
     font-size:16px;
     line-height:16px;
 }

 .font-size-16px-nolh{
     font-size:16px;
 }
 
 .font-size-14px{
     font-size:14px;
     line-height:14px;
 }

 .font-size-14px-nolh{
    font-size:14px;
}
 
 .font-size-12px{
     font-size:10px;
 }

 .font-size-12px-nolh{
    font-size:12px;
}
 
 .font-size-10px{
     font-size:10px;
 }
 
 .font-weight-semibold{
     font-weight:600 !important;
 }

.font-size-24px{
     font-size:24px;
}

.font-color-a1a1a1{
    color:#A1A1A1;
}

.font-color-darkred{
    color:darkred;
}