.customInput {
    border-radius: $card-border-radius;
    border: $common-border-style $border-rest-color;
    font-weight: 400;
    background: $card-background-color;
    color: $secondary-color;
    box-shadow: $common-box-shadow $box-shadow-color;
    outline: none;
    height: 44px;
    padding: 0 1.5rem;
}
