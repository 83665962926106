.sticky-first-column {
    position: relative;
    overflow: auto;

    table {
        width: 100%;
        border-spacing: 0;
        margin: 0;

        .selectedRow {
            background-color: lighten($success-color, 25);
            td:first-child,
            th:first-child,
            .table-counter + td,
            .table-counter + th {
                background-color: lighten($success-color, 25);
            }
        }

        td:first-child,
        th:first-child,
        .table-counter + td,
        .table-counter + th {
            position: sticky;
            left: 0;
            background-color: $card-background-color;

            @media (min-width: 768px) {
                min-width: 18rem;
            }
        }

        .table-counter {
            min-width: auto !important;
        }
    }
}

.table-action-button {
    width: 44px;
    height: 42px;
    box-shadow: $card-box-shadow;
    border: $common-border-style $secondary-color;
    border-radius: $card-border-radius;
    background-color: $card-background-color;
}

.tablePL td a {
    margin-left: 1.875rem;
    &:first-child {
        margin-left: 0;
    }
}

.tablePL td,
.tablePL th {
    text-align: start !important;
    vertical-align: bottom;
    padding: 1.25rem 1.125rem;
}

.tablePL {
    thead {
        tr {
            border-bottom: none;
        }
    }

    tr {
        border-bottom: $common-border-style hsl(212, 25%, 72%, 35%);
    }

    tr:last-child {
        border-bottom: none;
    }

    .table-counter {
        padding-left: 0;
    }
}

#preCampaignsTable {
    overflow-y: hidden;
    .country,
    .city,
    .type {
        min-width: 5rem !important;
    }
    .basTarih,
    .bitTarih {
        min-width: 8.75rem !important;
    }

    .trainer,
    .projectManager,
    .idpPerson {
        min-width: 7rem !important;
    }
    .fullPrice,
    .country,
    .city,
    .type,
    .trainer,
    .projectManager,
    .idpPerson,
    .courseName,
    .basTarih,
    .bitTarih {
        font-size: 0.875rem;
    }
}

.decreaseLimit{
    @media (max-width: 1441px){
        div:nth-child(n + 9){
            display: none !important;
        }
    }
}

.mobile-numbered-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    width: 32px;
    height: 32px;
    border: 1.5px solid;
    border-color: #A6B7CA;
    border-radius: 8px;
    margin-bottom: 8px;
}