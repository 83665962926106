@import "design_2.0/variables";
@import "../ui-kit/init.scss";
@import "./fluidFunctions.scss";
@import "./filter.scss";
@import "./newFilter.scss";
@import "./topSalesByAccount.scss";
@import "./icons.scss";
@import "./sales.scss";
@import "./salesByCampaignType.scss";
@import "./login.scss";
@import "./viewAll.scss";
@import "./pdf.scss";
@import "./aside-menu.scss";
@import "./accounts.scss";
@import "./table.scss";
@import "./fonts.scss";
@import "./idInquiries.scss";
@import "./trainings.scss";
@import "./campaigns.scss";
@import "./asideFilter.scss";
@import "./survey.scss";
@import "./component.scss";
@import "./checkboxInput.scss";
@import "./deals.scss";
@import "./checkRadioBtn.scss";
@import "design_2.0/main.scss";
@import "./print.scss";
@import "./trainersCalendar.scss";
@import "./activities.scss";
@import "./travels.scss";
@import "./courses.scss";
@import "./settings.scss";
@import "design_3.0/text.scss";


.vertical-align-none {
    vertical-align: unset !important;
}

.fitRow {
    height: 100%;
}

.padding-3px {
    padding: 0 3px;
}

@media (min-width: 1100px) and (max-width: 1299px) {
    .font-size-4rem {
        font-size: 2.5rem;
        line-height: 2rem;
    }

    .font-size-3rem {
        font-size: 2.25rem;
        line-height: 1.75rem;
    }
}

@media (min-width: 993px) and (max-width: 1099px) {
    .font-size-4rem {
        font-size: 2.25rem;
        line-height: 1.75rem;
    }

    .font-size-3rem {
        font-size: 2rem;
        line-height: 1.5rem;
    }
}

@media (max-width: 992px) {
    .font-size-4rem {
        font-size: 3rem;
        line-height: 2.5rem;
    }

    .font-size-3rem {
        font-size: 2.5rem;
        line-height: 2.25rem;
    }
}

@media (max-width: 500px) {
    .font-size-4rem {
        font-size: 2.5rem;
        line-height: 2rem;
    }

    .font-size-3rem {
        font-size: 2rem;
        line-height: 1.75rem;
    }
}

.apexcharts-pie-label {
    font-weight: 600 !important;
}

.apexcharts-datalabel {
    font-family: "Poppins_PL" !important;
    font-weight: 300;
}

.apexcharts-yaxis-title-text {
    fill: #b5b5c3;
}

.cardFluid {
    padding: 0 1vw !important;
    min-height: 200px !important;
}
.usersIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 17px;
    color: $secondary-color;
    font-size: 3rem !important;
}

.userPlusIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $success-color;
    gap: 16px;
    font-size: 3rem !important;
}

.noMinHeight {
    min-height: auto !important;
}

.cardToolbarMuted {
    color: #464e5f;
    font-family: "Poppins_PL";
}

.cardToolbarMutedLH50 {
    line-height: 50px;
}

.card-toolbar {
    margin: 0 !important;
}

.cardHeader {
    padding-top: 1.25rem !important;
}

.cardNoTitle {
    margin-top: -70px;
    padding: 70px 0 !important;
}

#Performance {
    min-height: 200px !important;
}

#Performance .apexcharts-canvas,
#Performance .apexcharts-canvas svg {
    height: 200px !important;
}

.performanceTarget {
    text-align: center;
    align-self: center;
    padding: 5px 15px;
    border-radius: 5px;
    margin-top: -50px;
    margin-bottom: 20px;
}

.performanceTargetCount {
    color: $secondary-color;
    font-weight: bold;
    font-size: 22px;
}

.performanceTargetSubtitle {
    color: $secondary-color;
    font-size: 12px;
    margin-top: -5px;
}

.errorIcon {
    display: block;
}

.errorBox,
.indicatorBox {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    height: calc(100% + 70px);
    flex-direction: row;
    font-weight: normal !important;
    letter-spacing: 0px;
    cursor: pointer;
}

.indicatorBox {
    @include fluid-FontSize($width_lg, $width_xxl, 16px, 16px);
    width: 100%;
    height: 100%;
    min-height: 70px;
    margin: 0 auto;
    text-align: left;
    color: $secondary-color;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.indicatorBox_centered {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    .errorSign {
        position: static;
        padding: 1.5rem;
    }
}

.indicatorTable {
    min-height: 200px;
    height: 100%;
    margin-top: 0;
}

.tdAmount {
    margin: 5px;
}

.trSelected {
    background: #c9f7f5;
}

.trSelected .tdAmount,
.trSelected .tdName {
    color: #3699ff !important;
}

.minHeight660 {
    min-height: 660px;
}

.svg-icon.svg-icon-new svg g [fill] {
    transition: fill 0.3s ease;
    fill: #38cd20 !important;
}

.svg-icon.svg-icon-darkblue svg g [fill] {
    transition: fill 0.3s ease;
    fill: #265f9a !important;
}

.svg-icon.svg-icon-darkgrey svg g [fill] {
    transition: fill 0.3s ease;
    fill: #464e5f !important;
}

.svg-icon.svg-icon-lightgrey svg g [fill] {
    transition: fill 0.3s ease;
    fill: #a1a1a1 !important;
}

.svg-icon.svg-icon-grey svg g [fill] {
    transition: fill 0.3s ease;
    fill: #8d8d8d !important;
}

.svg-icon.svg-icon-darkgreen svg g [fill] {
    transition: fill 0.3s ease;
    fill: #40b953 !important;
}

.symbolBackground svg g [fill] {
    transition: fill 0.3s ease;
    fill: #aa7762 !important;
}

.svg-plBlue svg g [fill] {
    transition: fill 0.3s ease;
    fill: #3699ff !important;
}

.new-green {
    color: #38cd20;
}

.dark-green {
    color: #40b953 !important;
}

.color-primary {
    color: #3699ff !important;
}
.color-secondary {
    color: $secondary-color !important;
}
.color-tertiary {
    color: $tertiary-color !important;
}

.contactByRank {
    color: #3699ff;
    font-weight: bold;
}

@media (max-width: 1449px) {
    .tableSalesByCampaign tbody tr.trLong {
        display: none;
    }
}

@media (max-width: 1650px) {
    #YoYGrowth .apexcharts-data-labels {
        display: none !important;
    }
}
@media (max-width: 1440px) {
    .homeTitle {
        font-size: 20px;
    }
    .detailTitle {
        font-size: 18px !important;
    }
}
.contactImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.42rem;
}

.pointer:hover {
    cursor: pointer;
}

.noTitle_editButton {
    margin-right: 25px;
    margin-top: 15px;
    margin-bottom: -35px;
    text-align: right;
    z-index: 90;
}

.marginRightZero {
    margin-right: 0px !important;
}

.alignItemsNormal {
    align-items: "normal" !important;
}

.searchTableAlign {
    margin-left: 30px !important;
}
.viewAllTitle {
    margin-left: 30px;
    font-size: 1.375rem;
    font-weight: 600;
    color: $secondary-color;
    text-align: left;
    font: normal normal 600 1.375rem/33px;
    letter-spacing: 0px;
    opacity: 1;
}

.viewAllTitleReports {
    font-size: 1.375rem;
    color: $secondary-color;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 600;
}

.noMinHeight .errorBox,
.noMinHeight .indicatorBox {
    margin: 0px 0px 30px;
}

.clear {
    clear: both;
}

.google-visualization-tooltip-item {
    white-space: nowrap;
}

.breadcrumb_subheaderTitle {
    font-size: 1.125em !important;
    color: $secondary-color !important;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.ck-balloon-panel {
    z-index: 1050 !important;
}

.checkbox-label input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    margin-top: 11px;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    height: 9px;
    width: 9px;
    border-radius: 5px;
    border: solid #009bff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
}

.checkbox-label input:checked ~ .checkbox-custom {
    background-color: #009bff;
    border: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 1px solid lightgrey;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    left: 6px;
    top: 3px;
    width: 4.5px;
    height: 9px;
    opacity: 1;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
}

.z-index-9 {
    z-index: 9;
}

.simpleModalBody {
    color: $secondary-color;
    margin-top: 10px;
}

.subHeaderPL {
    display: flex;
}

@media (max-width: 1301px) {
    .subHeaderPL {
        display: flex;
        flex-direction: column;
    }

    .subHeaderPL .breadcrumb_subheaderTitle,
    .subHeaderPL .breadcrumb {
        margin: 0 !important;
    }
}

.newButton {
    color: #6a83ff;
    background: #eff2ff;
    font-size: 13px;
    font-weight: 500;
    // border: 0;
    height: 43px;
    line-height: 43px;
    border-radius: 5px;
    padding: 0 10px;

    .svg-icon svg g [fill] {
        transition: fill 0.3s ease;
        fill: #6a83ff !important;
    }
}

#InquiriesByTable {
    margin: 0;
    width: 100% !important;
}
#InquiriesByTable th {
    text-align: center !important;
    font-size: 12px;
}
#InquiriesByTable td div {
    text-align: center;
    justify-content: center;
    font-size: 14px;
}
#InquiriesByTable td:first-child {
    padding-left: 0;
}
#InquiriesByTable td:first-child div {
    text-align: start;
    justify-content: start;
}

#C-W-InquiriesByTable {
    margin: 0;
    width: 100% !important;

    .amount,
    .closedWon,
    .numberOfInquiries {
        width: 100px;
        min-width: 100px;
    }

    .button {
        width: 120px;
    }
}
#C-W-InquiriesByTable th {
    text-align: center !important;
    font-size: 12px;
}
#C-W-InquiriesByTable td div {
    text-align: center;
    justify-content: center;
    font-size: 14px;
}
#C-W-InquiriesByTable td:first-child {
    padding-left: 0;
}
#C-W-InquiriesByTable td:first-child div {
    text-align: start;
    justify-content: start;
}
#cardHeadertop {
    min-height: 20px !important;
}

.wiev-Topics {
    color: #3f4254;
}
#card-height {
    min-height: 150px !important;
    margin-left: 10px;
}
.amount-span {
    background-color: #c9f7f5;
    padding: 4px 8px;
    font-size: 14px !important;
    border-radius: 5px;
}
.status-span {
    background-color: #e3c41d;
    color: white;
    padding: 6px;
    width: 111px !important;
    font-size: 12px !important;
    border-radius: 20px;
}

.inquiryViewAll {
    display: block;
    width: 90px;
    margin: 30px 10px 30px 10px;
    text-align: center;
}

.ratio-buttons {
    background-color: #c4c4c4;
    width: 47px;
    min-width: 47px;
    max-width: 47px;
    height: 28px;
    border-radius: 5px;
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    font-weight: 600;
}

.ratio-explanations {
    color: var(--color-secondary);
    font-size: 0.875rem;
    font-weight: 400;
}

.searchInputPage {
    max-width: 192px;
    background-color: #f3f6f9;
    font-size: 14px;
    height: 27px;
    line-height: 27px;
    border: 0;
    border-radius: 5px;
    padding: 0 10px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-left: 2.15rem;
}

.searchInputPage::placeholder {
    color: #d6d6d6;
    opacity: 1;
}

.cardTitle .cardSubtitle {
    font-size: 13px;
    color: #b5b5c3;
}

.textInputErrorMessage {
    color: red;
    font-size: 14px;
    font-weight: 600;
}

.errorMessage {
    margin-bottom: 10px;
    margin-top: 10px;
    color: darkred;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    left: 6px;
    top: 3px;
    width: 4.5px;
    height: 9px;
    opacity: 1;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
}

.z-index-9 {
    z-index: 9;
}

.simpleModalBody {
    color: #464e5f;
    margin-top: 10px;
}

.simpleModalTitle {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: $secondary-color !important;
}

.subHeaderPL {
    display: flex;
}

@media (max-width: 1301px) {
    .subHeaderPL {
        display: flex;
        flex-direction: column;
    }

    .subHeaderPL .breadcrumb_subheaderTitle,
    .subHeaderPL .breadcrumb {
        margin: 0 !important;
    }
}

.newButton {
    color: #6a83ff;
    background: #eff2ff;
    font-size: 13px;
    font-weight: 500;
    // border: 0;
    height: 43px;
    line-height: 43px;
    border-radius: 5px;
    padding: 0 10px;

    .svg-icon svg g [fill] {
        transition: fill 0.3s ease;
        fill: #6a83ff !important;
    }
}

.cardTitle .cardSubtitle {
    font-size: 13px;
    color: #b5b5c3;
}

.textInputErrorMessage {
    color: red;
    font-size: 14px;
    font-weight: 600;
}

.errorMessage {
    margin-bottom: 10px;
    margin-top: 10px;
    color: darkred;
}
.rank-span {
    font-size: 16px;
    color: $secondary-color;
    font-weight: 600;
}
.number-span {
    color: $secondary-color !important;
    white-space: nowrap;
}
.apexcharts-legend {
    display: flow-root !important;
}
.legent-name {
    color: $secondary-color;
    font-size: 0.875rem;
}

.legent-number {
    color: $disable-color;
    font-size: 0.875rem;
}
.legent-marker {
    display: inline-block;
    background-color: red;
    height: 15px;
    border-radius: 7.5px;
    width: 15px;
}
.kt_Select_Button {
    border: $common-border-style $primary-color;
    border-radius: 12px;
}
.kt_title {
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    color: $primary-color !important;
}

.special-p {
    padding: 0px !important;
}

.symbolBackground-a6b7ca {
    background-color: $disable-color !important;

    svg g [fill] {
        fill: $secondary-color;
    }
}

.primary-line-input {
    display: flex;
    justify-content: end;
    position: absolute;
    right: 10%;
    top: 50px;
    // margin-bottom: 50px;
    input {
        min-width: 200px;
        width: 15vw;
        background: $card-background-color 0% 0% no-repeat padding-box;
        box-shadow: $common-box-shadow $box-shadow-color !important;
        border: $common-border-style $border-rest-color;
        text-align: left;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0px;
        color: $disable-color;
        padding-left: 5px;
    }

    input:focus {
        text-align: left;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0px;
        color: $disable-color;
    }

    input::placeholder {
        text-align: left;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0px;
        color: $disable-color;
        padding-left: 5px;
    }

    .search-icon {
        box-shadow: 3px 0px 8px #5a81ab1a;
    }
}

@media (max-width: 768px) {
    .primary-line-input {
        left: 0;
    }
}

.custom-input-icon {
    display: flex;
    input {
        // min-width: 200px;
        width: 17.5vw;
        background: $card-background-color 0% 0% no-repeat padding-box;
        box-shadow: $common-box-shadow $box-shadow-color !important;
        border-radius: 12px;
        font-weight: 600;
        font-size: 0.875rem;
        letter-spacing: 0px;
        text-align: left;
        @media (max-width: 575px) {
            flex: 1;
        }
    }
    input:focus {
        background: $card-background-color 0% 0% no-repeat padding-box;
        font-weight: 600;
        font-size: 0.875rem;
        letter-spacing: 0px;
    }

    input::placeholder {
        color: $disable-color;
    }

    .custom-search-icon {
        box-shadow: 3px 0px 8px #5a81ab1a;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        height: 44px;
    }

    .form-control {
        border-radius: $card-border-radius;
        padding-left: calc(#{$input-line-height * 1em} + #{$input-height-border} + 2rem);
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.rest-outline-input {
    input {
        border: $common-border-style $border-rest-color;
        color: $border-rest-color;
    }
    input:focus {
        border: $common-border-style $border-rest-color;
        color: $secondary-color;
    }

    .custom-search-icon {
        border-right: $common-border-style $border-rest-color;
        svg {
            #Path_86 {
                stroke: $border-rest-color;
            }
            g path:nth-child(2) {
                fill: $border-rest-color;
            }
        }
    }
}

.disable-outline-input {
    input {
        border: $common-border-style $disable-color;
        color: $disable-color;
    }
    input:focus {
        border: $common-border-style $disable-color;
        color: $secondary-color;
    }

    .custom-search-icon {
        border-right: $common-border-style $disable-color;

        svg {
            #Path_86 {
                stroke: $disable-color;
            }
            g path:nth-child(2) {
                fill: $disable-color;
            }
        }
    }
}

.primary-outline-input {
    input {
        border: $common-border-style $primary-color;
        color: $primary-color;
        min-height: 44px;
    }
    input:focus {
        border: $common-border-style $primary-color;
        color: $primary-color;
    }

    .custom-search-icon {
        border-right: $common-border-style $primary-color;
        svg {
            #Path_86 {
                stroke: $primary-color;
            }
            g path:nth-child(2) {
                fill: $primary-color;
            }
        }
    }
}

.secondary-outline-input {
    input {
        border: $common-border-style $secondary-color;
        color: $secondary-color;
    }
    input:focus {
        border: $common-border-style $secondary-color;
        color: $secondary-color;
    }

    .custom-search-icon {
        border-right: $common-border-style $secondary-color;
        svg {
            #Path_86 {
                stroke: $secondary-color;
            }
            g path:nth-child(2) {
                fill: $secondary-color;
            }
        }
    }
}

.primary-filled-input {
    input {
        background: $primary-color;
        border: $common-border-style $primary-color;
        color: $outline-background-color;
    }
    input:focus {
        border: $common-border-style $primary-color;
        background: $primary-color;
        color: $outline-background-color;
    }
    input::placeholder {
        color: $outline-background-color;
    }

    .custom-search-icon {
        background-color: #83a6cb;
        svg {
            #Path_86 {
                stroke: $outline-background-color;
            }
            g path:nth-child(2) {
                fill: $outline-background-color;
            }
        }
    }
}

.price-input {
    color: #c0cbd8;
    border-right: 1px solid #ddd;
    cursor: default;
}

.secondary-filled-input {
    input {
        background: $secondary-color;
        border: $common-border-style $secondary-color;
        color: $outline-background-color;
    }
    input:focus {
        border: $common-border-style $secondary-color;
        background: $secondary-color;
        color: $outline-background-color;
    }

    input::placeholder {
        color: $outline-background-color;
    }

    .custom-search-icon {
        background-color: #59728f;

        svg {
            #Path_86 {
                stroke: $outline-background-color;
            }
            g path:nth-child(2) {
                fill: $outline-background-color;
            }
        }
    }
}

.symbol-label-305073 {
    color: $secondary-color !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
}

.background-fcfcfc {
    background: $card-background-color 0% 0% no-repeat padding-box;
}

.card-toolbar {
    align-content: center;
    .viewAllButton {
        text-decoration: underline;
        color: $primary-color;
    }
    a:hover {
        text-decoration: none;
        color: $primary-color !important;
    }
}

.container-fluid {
    > div {
        padding: 4.5px;
    }

    padding: 0 0.5rem !important;

    @media screen and (min-width: 576px) {
        padding: 0 1rem !important;
    }

    @media screen and (min-width: 768px) {
        padding: 0 2rem !important;
    }
}

.min-width-120px {
    min-width: 120px;
}

.auto-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-footer {
    justify-content: center !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
    &.apexcharts-active {
        background: $secondary-color !important;
        border-radius: $common-border-radius !important;
        color: $card-background-color;
    }
    .apexcharts-tooltip-title {
        background: transparent !important;
        font-weight: 600;
    }
}

// Override Modal component's default styles
.modal-content {
    background-color: $card-background-color !important;
    .modal-header {
        border-bottom: none !important;
        &__close {
            .close {
                &::before {
                    display: inline-block !important;
                    width: 25px !important;
                    height: 25px !important;
                    color: $secondary-color !important;
                }
            }
        }
    }
    .modal-footer {
        border-top: none !important;
    }
    .modal-title {
        color: $secondary-color !important;
        font-size: 28px !important;
        font-weight: 600 !important;
    }

    .modal-body {
        .textInputSurveyAdd {
            ::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: $disable-color !important;
            }
            ::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: $disable-color !important;
                opacity: 1;
            }
            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $disable-color !important;
            }
            ::placeholder {
                /* Most modern browsers support this now. */
                color: $disable-color !important;
            }
        }
    }
}
@media (max-width: $mobile-breakpoint) {
    .pdf-mobile {
        margin-right: 0 !important;
        .btn-outline-danger {
            border-width: 2px !important;
            background-color: $card-background-color !important;
        }
    }
    .tooltip-inner {
        display: none;
    }
    .tooltip {
        .arrow {
            display: none;
        }
    }
}

.sticky-card-header {
    position: sticky;
    top: 88px;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.025);
    z-index: 1;
    background: inherit;
    margin: 0 -32px 10px;
    border-radius: 12px 12px 0 0;
}

.overflow-y-auto {
    overflow-y: auto;
}

.common-drag-and-drop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 1.75rem 1.25rem;
    background: var(--color-card-background);
    box-shadow: var(--common-box-shadow) var(--color-box-shadow);
    border-radius: 12px;
    border: 2px solid var(--color-rest-border);

    .remove-file {
        width: 20px;
        height: 20px;
    }
}

.blocking-spinner {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    left:0;
    top:0;
}

// 5.01.2024 genel css çalışmaları
.ct-training {
    font-size: 0.75rem;
    line-height: 1.5;
    color: $primary-color !important;
    text-decoration: underline;
}

.mini-title {
    color: $disable-color !important;
    @extend .small-text;
    line-height: 1.5;
    font-weight: normal;
    display: block;
    text-align: start;
}

.name-tag {
    position: relative;
    font-size: 1rem;
    background-color: $disable-color;
    line-height: 3em;
    height: 3em;
    width: 3em;
    min-width: 3em;
    min-height: 3em;
    overflow: hidden;
    text-align: center;
    border-radius: 12px;
    color: $secondary-color;
    margin-right: 1.5rem;
    font-weight: 600;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: $mobile-breakpoint) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
        margin-right: 1rem;
        height: 2rem;
        width: 2rem;
        min-width: 2rem;
        min-height: 2rem;
        border-radius: 8px;
        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
}

.drawer-close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #c0cbd8;
    color: #305073;
    width: 30px;
    height: 30px;
    outline: 0;
    background: transparent;
    overflow: hidden;
    padding: 0;
    border-radius: 10px;
    svg {
        transform: rotate(45deg);
    }
}

.filterbar-heading {
    font-size: 1rem;
    font-weight: 600;
    color: #305073;
}

.btn-filters {
    background-color: transparent !important;
    border: 0 !important;
    font-size: 12px !important;
    box-shadow: none !important;
    &:hover:not([disabled]) {
        opacity: 0.8;
    }
    &:disabled {
        pointer-events: none;
    }
    &.clearAll {
        color: #8a9eb4;
        font-weight: normal !important;
        padding: 8px 12px !important;
    }
    &.saveFilter {
        font-weight: 600 !important;
        color: $primary-color !important;
        .icon-button2 {
            svg {
                width: 12px !important;
                height: 12px !important;
            }
            background-color: transparent !important;
            border-color: transparent !important;
        }
        .icon-button-span {
            padding-left: 0px !important;
        }
    }
}

.existingFilter {
    background-color: transparent;
    color: $secondary-color;
    border: 2px solid $primary-color;
    border-radius: 12px;
    cursor: pointer;
    &.active {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: $card-background-color;
    }
    padding: 6px 12px;
    font-size: 0.875rem;
    font-weight: 600;
    svg {
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
    }
}

.save-filter-input {
    border: none;
    border-radius: 12px;
    background-color: $secondary-color;
    color: $card-background-color;
    padding: 6px 12px;
    &:focus {
        outline: none;
    }
}
.card-table-value {
    @extend .text-body;
    color: $secondary-color !important;
    line-height: 1.5;
}

.row-with-gap {
    gap: 20px 0px;
}
