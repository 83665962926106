@import "./reset-flex.scss";

.page {
    height: 1122px;
    page-break-after: always;
    page-break-inside: avoid;
    background-color: var(--contrast) !important;
    color: var(--contrast-inverse) !important;
    -webkit-print-color-adjust: exact;
}

.content {
    padding-left: var(--gutter-x);
    padding-right: var(--gutter-x);
    padding-top: var(--gutter-y);
    padding-bottom: var(--gutter-y);
}

.headerBar {
    padding-left: var(--gutter-x);
    padding-right: var(--gutter-x);
    padding-top: 48px;
    padding-bottom: 36px;
}

.header,
.subheader {
    padding-left: var(--gutter-x);
    padding-right: var(--gutter-x);
    background-color: var(--contrast-inverse) !important;
    color: var(--contrast) !important;
    font-weight: bold;
    margin: 0;
    -webkit-print-color-adjust: exact;
}

.header {
    line-height: 84px;
    font-size: 42px;
}

.subheader {
    font-size: 27px;
    line-height: 56px;
}

.question {
    font-size: 27px;
    font-weight: 500;
    span {
        font-weight: 800;
    }
}

.table {
    width: 100%;
    tr {
        border-bottom: 1px solid #abb0b3;
        td {
            padding-bottom: 8px;
            padding-top: 8px;
            font-size: 12px;
            vertical-align: bottom;
        }
    }
}

.emphasize {
    font-size: 16px;
}
