.wrapper {
    position: relative;
    width: fit-content;
    border-radius: var(--common-border-radius);
    background-color: var(--color-card-background);
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 44px;
        height: 44px;
        background-repeat: no-repeat;
        background-position: center;
    }

    &::before {
        // opacity: .4;
        right: 2px;
        border-left: var(--common-border-style) var(--color-rest-border);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23305073' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    }

    &::after {
        //  opacity: .4;
        left: 2px;
        border-right: var(--common-border-style) var(--color-rest-border);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23305073' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-calendar'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
    }

    &.inline {
        &::before,
        &::after {
            display: none;
        }
    }

    :global {
        .react-datepicker {
            border: var(--common-border-style) var(--color-rest-border);
            box-shadow: var(--common-box-shadow) var(--color-box-shadow);
            border-radius: var(--common-border-radius);
            &__triangle {
                display: none;
            }

            &__header {
                background: none;
                border: none;
                padding: 0;
            }

            &__month {
                margin-top: 0;
            }
            &__day {
                --day-border-radius: 6px;
                font-weight: 600;
                color: var(--color-secondary);
                font-size: 0.75rem;
                line-height: 1.7rem;
                border-radius: var(--day-border-radius);

                &-name {
                    font-weight: 600;
                    color: var(--color-secondary);
                    font-size: 0.75rem;
                    line-height: 1.7rem;
                }
                &.react-datepicker__day--keyboard-selected {
                    background-color: white;
                    color: #305073;
                }
                &.react-datepicker__day--in-range {
                    color: #fff !important;
                    background-color: #305073 !important;
                }
                &.react-datepicker__day--in-selecting-range {
                    color: #fff !important;
                    background-color: #305073 !important;
                }
                &.react-datepicker__day--in-range:hover {
                    color: #fff !important;
                    background-color: #a6b7ca !important;
                }
                &.react-datepicker__day--highlighted {
                    background-color: #30507329;
                }
                .react-datepicker__day--highlighted:hover {
                    color: #fff !important;
                    background-color: #a6b7ca !important;
                }

                &:hover {
                    position: relative;

                    // Following code is a bit ugly but it's the only way to
                    // "transparentize" a hex color with pure CSS
                    // if you know a better way, please let me know :)
                    background: none;
                    &::after {
                        border-radius: var(--day-border-radius);
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: var(--color-secondary);
                        opacity: 0.1;
                    }
                }

                &--disabled {
                    color: var(--color-disable) !important;
                    opacity: 0.5;
                    &:hover {
                        background: transparent;
                    }
                }

                &--selected {
                    color: var(--color-card-background) !important;
                    background: var(--color-secondary) !important;
                    &:hover {
                        background: var(--color-secondary);
                    }
                }

                &--keyboard-selected {
                    background: var(--color-primary);
                    color: var(--color-card-background);
                    &:hover {
                        background: var(--color-primary);
                    }
                }

                &--outside-month {
                    opacity: 0.5;
                }
            }
        }
    }
}

.dateInput {
    background-color: transparent;
    border: var(--common-border-style) var(--color-rest-border);
    box-shadow: var(--common-box-shadow) var(--color-box-shadow);
    border-radius: var(--common-border-radius);
    outline: none;
    height: 44px;
    // opacity: .4;
    color: var(--color-secondary);
    font-size: 0.875rem;
    font-weight: 600;
    padding-left: calc(2rem + 44px);
    padding-right: 44px;
    min-width: 210px;
    position: relative;
    bottom: "20px";
}
.dateRangeInput {
    @extend .dateInput;
    cursor: default;
    font-weight: initial;
    height: 54px;
    padding-right: 23px;
    padding-top: 20px;
    padding-left: calc(2rem + 24px);
    min-width: 290px;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
}

.button {
    border: var(--common-border-style) var(--color-rest-border);
    background-color: var(--color-card-background);
    box-shadow: var(--common-box-shadow) var(--color-box-shadow);
    color: var(--color-secondary);

    border-radius: 6px;
    height: 24px;
    font-size: 0.75rem;
    outline: none;
    &:disabled {
        opacity: 0.5;
    }
}

.nextButton,
.prevButton {
    width: 24px;
    padding: 0;
}

.prevButton {
    :global {
        svg {
            transform: rotate(90deg);
            width: 20px;
            height: 20px;
        }
    }
    margin-right: 4px;
}

.nextButton {
    :global {
        svg {
            transform: rotate(270deg);
            width: 20px;
            height: 20px;
        }
    }
}

.monthSelect {
    margin-right: 4px;
}
