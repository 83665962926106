//
// Modal
//

.modal {
    // Header
    .modal-header {
        display: flex;
        align-items: center;
        &__close {
            .close {
                width: 44px;
                height: 44px;
                padding: 4px;
                border-radius: 12px;
                box-shadow: 3px 0px 8px #459b9b1a;
                border: 2px solid #c0cbd8;
                &::before {
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23305073' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 368L144 144M368 144L144 368'/%3E%3C/svg%3E");
                }
            }
        }

        .modal-title {
            font-weight: $modal-title-font-weight;
            font-size: $modal-title-font-size;
            color: $modal-title-color;

            small {
                font-weight: $modal-title-small-font-weight;
                font-size: $modal-title-small-font-size;
                color: $modal-title-small-color;
            }
        }

        .close {
            outline: none !important;
            color: $modal-title-close-color;
            transition: $transition-link;
            padding: 0;
            margin: 0;
            line-height: 0;

            i,
            .ki {
                transition: $transition-link;
                color: $dark-75;
                font-size: 0.8rem;
            }

            span {
                display: none;
            }

            &:hover {
                transition: $transition-link;
                color: $modal-title-close-hover-color;

                i,
                .ki {
                    transition: $transition-link;
                    color: $primary;
                }
            }
        }
    }

    // Sticky Modal
    &.modal-sticky {
        &.modal-sticky-bottom-right {
            $sticky-width: 500px;
            $sticky-width-lg: 600px;

            padding: 0 !important;
            max-width: $sticky-width;

            height: auto;
            position: fixed;
            left: auto;
            top: auto;
            bottom: 25px;
            right: 25px;
            margin: 0;
            box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);
            @include border-radius($border-radius);

            .modal-dialog {
                position: static;
                max-width: $sticky-width;
                width: auto;
                margin: 0;
                @include border-radius($border-radius);

                .modal-content {
                    border: 0;
                    @include border-radius($border-radius);
                    width: max-content !important;
                }
            }

            &.modal-sticky-lg {
                max-width: $sticky-width-lg;

                .modal-dialog {
                    max-width: $sticky-width-lg;
                }
            }

            // Tablet & Mobile Modess
            @include media-breakpoint-down(md) {
                bottom: 10px;
                right: 10px;
            }

            // Mobile mode
            @include media-breakpoint-down(sm) {
                max-width: 90%;
            }
        }
    }
}

// Open state
.modal-open {
    overflow: auto !important;
    padding: 0 !important;
}

// Responsive breakpoints
@include media-breakpoint-up(xl) {
    .modal-dialog.modal-xl {
        max-width: map-get($grid-breakpoints, xl) - 100px;
    }
}

.grayish-backdrop {
    opacity: 1 !important;
    background-color: #0000002e !important;
}

.modal-body-without-padding,
.modal-header-without-padding,
.modal-footer-without-padding {
    padding: 0 !important;
}

.fullscreen-modal {
    display: flex !important;
    padding: 0 !important;
    justify-content: center !important;
    align-items: center !important;
    .modal-dialog {
        margin: 0 auto !important;
        height: calc(100vh - 6rem) !important;
        width: 100% !important; 
        max-width: calc(100vw - 7rem) !important;
        @media (min-width: 1920px) {
            max-width: calc(100vw - 9rem) !important;
        }
        .modal-content {
            width: 100%;
            height: 100%;
        }
    }
}

.close-btn-outside {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -34px;
    right: -34px;
    width: 36px;
    height: 36px;
    padding: 4px;
    border-radius: 12px;
    box-shadow: 3px 0px 8px #459b9b1a;
    border: 2px solid #c0cbd8;
}