// Primary colors
$primary-50: 											#F2F8FB !default;
$primary-100: 											#E5F2F8 !default;
$primary-200: 											#CCE6F2 !default;
$primary-300: 										    #B2DAEB !default;
$primary-400: 											#99CEE5 !default;
$primary-500: 											#7FC2DE !default;
$primary-600: 											#66B5D8 !default;
$primary-700: 											#4CA9D1 !default;
$primary-800: 											#329DCB !default;
$primary-900: 											#0085BE !default;
$primary-950: 											#006A98 !default;

// Neutral colors
$neutral-50: 											#FBFCFC !default;
$neutral-100: 											#EEF2F3 !default;
$neutral-200: 											#DEE5E7 !default;
$neutral-300: 										    #C2CCCF !default;
$neutral-400: 											#A7B2B7 !default;
$neutral-500: 											#8B999F !default;
$neutral-600: 											#6F8086 !default;
$neutral-700: 											#53676E !default;
$neutral-800: 											#384D56 !default;
$neutral-900: 											#1C343E !default;
$neutral-950: 											#001B26 !default;

//Danger colors
$danger-50: 								  			#FDF5F5 !default;
$danger-100: 											#FCECEC !default;
$danger-200: 											#F9D9D9 !default;
$danger-300: 										    #F6C6C6 !default;
$danger-400: 											#F3B4B4 !default;
$danger-500: 											#F0A1A1 !default;
$danger-600: 											#ED8E8E !default;
$danger-700: 											#EA7C7C !default;
$danger-800: 											#E76969 !default;
$danger-900: 											#E24444 !default;
$danger-950: 											#B43636 !default;

//Danger colors
$danger-50: 								  			#FDF5F5 !default;
$danger-100: 											#FCECEC !default;
$danger-200: 											#F9D9D9 !default;
$danger-300: 										    #F6C6C6 !default;
$danger-400: 											#F3B4B4 !default;
$danger-500: 											#F0A1A1 !default;
$danger-600: 											#ED8E8E !default;
$danger-700: 											#EA7C7C !default;
$danger-800: 											#E76969 !default;
$danger-900: 											#E24444 !default;
$danger-950: 											#B43636 !default;

//Warning colors
$warning-50: 								  			#FCF8F6 !default;
$warning-100: 											#F9EFEA !default;
$warning-200: 											#F4DFD6 !default;
$warning-300: 										    #EFCFC1 !default;
$warning-400: 											#EABFAD !default;
$warning-500: 											#E5AF98 !default;
$warning-600: 											#DF9F84 !default;
$warning-700: 											#DA8F6F !default;
$warning-800: 											#D57F5A !default;
$warning-900: 											#CB6032 !default;
$warning-950: 											#A24C28 !default;

//Success colors
$success-50: 								  			#F2F8F7 !default;
$success-100: 											#E5F3F0 !default;
$success-200: 											#CCE6E1 !default;
$success-300: 										    #B2DAD2 !default;
$success-400: 											#99CEC3 !default;
$success-500: 											#7FC2B4 !default;
$success-600: 											#65B5A5 !default;
$success-700: 											#4CA996 !default;
$success-800: 											#329D87 !default;
$success-900: 											#00856A !default;
$success-950: 											#006A54 !default;

//Other colors
$other-50: 								      			#F9F2FD !default;
$other-100: 											#F3E6FB !default;
$other-200: 											#E8CEF7 !default;
$other-300: 										    #DCB6F3 !default;
$other-400: 											#D19EEF !default;
$other-500: 											#C586EC !default;
$other-600: 											#BA6EE8 !default;
$other-700: 											#AE56E4 !default;
$other-800: 											#A33EE0 !default;
$other-900: 											#8C0ED9 !default;
$other-950: 											#700BAD !default;




