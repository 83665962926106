.mobile-drawer-wrapper {
    position: relative;
    @media (max-width: 767px) {
        width: 100vw;
        height: 100%;
        align-items: flex-end;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9999;
        transition: all 3s;
        background-color: #30507329;
    }
    @media (min-width: 768px) {
        justify-content: flex-end;
        align-items: center;
    }
    .wrapper-card {
        width: 100vw;
        height: calc(100% - 30px);
        background-color: $card-background-color;
        position: relative;

        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        @media (max-width: 767px) {
            &.visible {
                animation: slide-up 0.5s;
            }
            &.hidden {
                animation: slide-down 0.5s;
            }
        }

        @media (min-width: 768px) {
            border-top-left-radius: 16px;
            border-top-right-radius: 0;
            border-bottom-left-radius: 16px;
            min-width: 310px;
            // width: auto;
            max-width: 425px;
            position: fixed;
            background: #fff;
            z-index: 999999;
            right: 0;
            height: 100vh;
            top: 0px;
            &.visible {
                animation: slide-left 0.5s;
            }
            &.hidden {
                animation: slide-right 0.5s;
            }
            .filterbar-heading {
                font-size: 1rem;
                font-weight: 600;
            }
            .filterbar-heading {
                font-size: 1rem;
                font-weight: 600;
                color: #305073;
            }
        }
        form {
            height: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr auto;
            grid-template-areas:
                "header"
                "main"
                "footer";
            .drawer-header {
                color: $secondary-color;
                font-size: 22px;
                font-weight: 600;
            }
            .btn-close {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #c0cbd8;
                color: #305073;
                width: 30px;
                height: 30px;
                outline: 0;
                background: transparent;
                overflow: hidden;
                padding: 0;
                border-radius: 10px;
                svg {
                    transform: rotate(45deg);
                }
            }
            .btn-filters {
                background-color: transparent !important;
                border: 0 !important;
                font-size: 12px !important;
                box-shadow: none !important;
                &:hover:not([disabled]) {
                    opacity: 0.8;
                }
                &:disabled {
                    pointer-events: none;
                }
                &.clearAll {
                    color: #8a9eb4;
                    font-weight: normal !important;
                    padding: 8px 12px !important;
                }
                &.saveFilter {
                    font-weight: 600 !important;
                    color: $primary-color !important;
                    .icon-button2 {
                        svg {
                            width: 12px !important;
                            height: 12px !important;
                        }
                        background-color: transparent !important;
                        border-color: transparent !important;
                    }
                    .icon-button-span {
                        padding-left: 0px !important;
                    }
                }
            }
            .existingFilter {
                background-color: transparent;
                color: $secondary-color;
                border: 2px solid $primary-color;
                border-radius: 12px;
                cursor: pointer;
                &.active {
                    background-color: $secondary-color;
                    border-color: $secondary-color;
                    color: $card-background-color;
                }
                padding: 6px 12px;
                font-size: 0.875rem;
                font-weight: 600;
                svg {
                    width: 12px;
                    height: 12px;
                    transform: rotate(45deg);
                }
            }
            .save-filter-input {
                border: none;
                border-radius: 12px;
                background-color: $secondary-color;
                color: $card-background-color;
                padding: 6px 12px;
                &:focus {
                    outline: none;
                }
            }
            .scroll-container {
                overflow-y: auto;
                overflow-x: hidden;
                .navi {
                    .navi-item {
                        .navi-link {
                            padding: 0;
                            &:hover {
                                background: transparent;
                            }
                            .selectInputNewFilter__control {
                                margin: 0 0 12px 0;
                                border: 2px solid $border-rest-color !important;
                                cursor: pointer;
                                .selectInputNewFilter__value-container {
                                    .selectInputNewFilter__placeholder,
                                    .selectInputNewFilter__single-value {
                                        color: $secondary-color !important;
                                        font-size: 14px !important;
                                        font-weight: 400 !important;
                                    }
                                }
                                .selectInputNewFilter__indicators {
                                    .selectInputNewFilter__dropdown-indicator,
                                    .selectInputNewFilter__clear-indicator {
                                        > svg > path {
                                            fill: $secondary-color !important;
                                        }
                                    }
                                }
                            }
                            .filterbar-heading {
                                font-size: 1rem;
                                font-weight: 600;
                                color: #305073;
                            }

                            .rdrDateRangePickerWrapper {
                                height: auto !important;
                                width: 100%;
                                position: relative;
                                .rdrDefinedRangesWrapper {
                                    width: 100%;
                                    position: absolute;
                                    left: 0;
                                    scroll-snap-type: x proximity;
                                    scroll-snap-align: center;
                                    display: grid;
                                    background: transparent;
                                    border: none;
                                    .rdrStaticRanges {
                                        // display: grid;
                                        // grid-auto-flow: column;
                                        // grid-auto-columns: auto;
                                        // padding: 0 30px 20px 30px;
                                        // width: 100%;
                                        // gap: 8px;
                                        display: flex;
                                        flex-direction: column;
                                        flex-wrap: wrap;
                                        width: max-content;
                                        margin-left: -5px;

                                        .rdrStaticRange {
                                            border: 2px solid $border-rest-color;
                                            border-radius: 8px;
                                            scroll-snap-align: center;
                                            background: $card-background-color;
                                            margin-bottom: 5px;
                                            &:hover {
                                                border-color: white;
                                                .rdrStaticRangeLabel {
                                                    background: $primary-color;
                                                    color: $card-background-color;
                                                    border-radius: 5px;
                                                }
                                            }
                                            &.rdrStaticRangeSelected {
                                                border-color: $primary-color;
                                                background: $primary-color;
                                                .rdrStaticRangeLabel {
                                                    color: $card-background-color;
                                                }
                                            }

                                            .rdrStaticRangeLabel {
                                                white-space: nowrap;
                                                padding: 4px 6px;
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                                .rdrCalendarWrapper {
                                    margin-top: 70px;
                                    font-size: 14px;
                                    background: transparent;
                                    position: relative;
                                    top: -69px;
                                    left: 68px;
                                    .rdrDateDisplayWrapper {
                                        background-color: transparent;
                                        .rdrDateDisplay {
                                            margin: 0;
                                            .rdrDateDisplayItem {
                                                input {
                                                    padding: 4px 6px;
                                                    font-size: 12px;
                                                }
                                                box-shadow: none;
                                                border: $common-border-style $border-rest-color;
                                                background-color: transparent;
                                                border-radius: 8px;
                                                &.rdrDateDisplayItemActive {
                                                    border-color: $primary-color;
                                                }
                                            }
                                        }
                                    }
                                    .rdrMonthAndYearWrapper {
                                        .rdrMonthAndYearPickers {
                                            order: 1;
                                            justify-content: flex-start;
                                            background: none;
                                            .rdrYearPicker,
                                            .rdrMonthPicker {
                                                line-height: 14px;
                                            }
                                            .rdrMonthPicker {
                                                margin-right: 30px;
                                            }
                                            select {
                                                font-weight: 600;
                                                background: none;
                                                text-align: left;
                                                padding: 0;
                                            }
                                        }
                                        .rdrNextPrevButton {
                                            background: transparent;
                                            position: relative;
                                            width: 21px;
                                            height: 21px;

                                            i {
                                                border: solid $secondary-color;
                                                border-width: 0 3px 3px 0;
                                                padding: 6px;
                                                border-radius: 0px 0px 2px 0px;
                                                position: absolute;
                                                top: calc(50% - 3px);
                                            }
                                        }
                                        .rdrPprevButton {
                                            order: 2;
                                            i {
                                                top: calc(50% - 12px);
                                                transform: rotate(135deg);
                                                -webkit-transform: rotate(135deg);
                                            }
                                        }
                                        .rdrNextButton {
                                            order: 3;

                                            i {
                                                top: calc(50% - 12px);

                                                transform: rotate(-49deg);
                                                -webkit-transform: rotate(-49deg);
                                            }
                                        }
                                    }
                                    .rdrMonths {
                                        .rdrMonth {
                                            width: 100%;
                                            padding: 0;
                                            .rdrWeekDays {
                                                span {
                                                    color: $secondary-color !important;
                                                }
                                            }
                                            .rdrDays {
                                                gap: 5px 0;
                                                .rdrDay {
                                                    aspect-ratio: 1;
                                                    height: auto;

                                                    &:not(.rdrDayPassive) {
                                                        .rdrStartEdge,
                                                        .rdrEndEdge,
                                                        .rdrInRange {
                                                            inset: 0;
                                                        }
                                                        .rdrDayNumber {
                                                            inset: 0;
                                                        }
                                                        .rdrStartEdge.rdrEndEdge {
                                                            color: transparent !important;
                                                            border: $common-border-style $primary-color;
                                                            & ~ .rdrDayNumber {
                                                                span {
                                                                    color: $primary-color;
                                                                }
                                                            }
                                                        }
                                                        .rdrDayStartPreview.rdrDayEndPreview {
                                                            border: none;
                                                            inset: 0;
                                                        }
                                                    }

                                                    &.rdrDayPassive {
                                                        span {
                                                            color: $disable-color !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media (max-width: 476px) {
                        .rdrStaticRange {
                            margin-right: 12px;
                        }
                        .filterbar-heading {
                            font-size: 1rem;
                            font-weight: 600;
                            color: #305073;
                        }
                        .form-control {
                            width: 100% !important;
                        }
                        .rdrStaticRanges {
                            display: flex;
                            flex-direction: row !important;
                            margin-left: 1px !important ;
                        }

                        .rdrDateInput {
                            display: none;
                        }
                        .rdrCalendarWrapper {
                            position: relative !important;
                            top: 0 !important;
                            left: 0 !important;
                            margin-top: 16px !important;
                        }
                        .rdrDateRangePickerWrapper {
                            margin-bottom: 2rem !important;
                        }
                    }
                    @media (min-width: 786px) {
                        > .navi-item {
                            .navi-link {
                                padding: 0;
                                &:hover {
                                    background: transparent;
                                }
                                .filterbar-heading {
                                    font-size: 1rem;
                                    font-weight: 600;
                                    color: #305073;
                                }
                                .rdrDateRangePickerWrapper {
                                    width: 100%;
                                    gap: 40px;
                                    .rdrDefinedRangesWrapper {
                                        font-size: 14px;
                                        background: transparent;
                                        border-right: 0px;
                                        @media (min-width: 768px) {
                                            width: 110px;
                                        }
                                    }
                                }

                                .rdrStaticRange {
                                    border-bottom: 0;
                                    background: transparent;
                                    margin-bottom: 4px;
                                    text-align: left;
                                    &:hover {
                                        .rdrStaticRangeLabel {
                                            background: rgba($primary-color, 0.1);
                                        }
                                    }
                                    &.rdrStaticRangeSelected {
                                        .rdrStaticRangeLabel {
                                            color: #f5f7fa;
                                            background: $primary-color;
                                        }
                                    }
                                    .rdrStaticRangeLabel {
                                        padding: 8px 12px;
                                        display: inline-block;
                                        border-radius: 6px;
                                    }
                                }
                                .rdrCalendarWrapper {
                                    font-size: 14px;
                                    background: transparent;
                                    flex: 1;
                                    padding: 0 20px;
                                    .rdrDateDisplayWrapper {
                                        background-color: transparent;
                                        .rdrDateDisplay {
                                            margin: 0;
                                            .rdrDateDisplayItem {
                                                box-shadow: none;
                                                border: 2px solid $border-rest-color;
                                                background-color: transparent;
                                                border-radius: 8px;
                                                &.rdrDateDisplayItemActive {
                                                    border-color: $primary-color;
                                                }
                                            }
                                        }
                                    }
                                    .rdrMonthAndYearWrapper {
                                        .rdrMonthAndYearPickers {
                                            order: 1;
                                            justify-content: flex-start;
                                            background: none;

                                            .rdrMonthPicker {
                                                margin-right: 30px;
                                            }
                                            select {
                                                font-weight: 600;
                                                background: none;
                                                text-align: left;
                                                padding: 0;
                                            }
                                        }
                                        .rdrNextPrevButton {
                                            background: transparent;
                                            position: relative;
                                            width: 21px;
                                            height: 21px;

                                            i {
                                                border: solid $secondary-color;
                                                border-width: 0 3px 3px 0;
                                                padding: 6px;
                                                border-radius: 0px 0px 2px 0px;
                                                position: absolute;
                                                top: calc(50% - 3px);
                                            }
                                        }
                                        .rdrPprevButton {
                                            order: 3;
                                            i {
                                                top: calc(50% - 12px);
                                                transform: rotate(45deg);
                                                -webkit-transform: rotate(45deg);
                                            }
                                        }
                                        .rdrNextButton {
                                            order: 2;

                                            i {
                                                transform: rotate(-135deg);
                                                -webkit-transform: rotate(-135deg);
                                            }
                                        }
                                    }
                                    .rdrMonths {
                                        .rdrMonth {
                                            width: 100%;
                                            padding: 0;
                                            .rdrWeekDays {
                                                span {
                                                    color: $secondary-color !important;
                                                }
                                            }
                                            .rdrDays {
                                                gap: 5px 0;
                                                .rdrDay {
                                                    aspect-ratio: 1;
                                                    height: auto;

                                                    &:not(.rdrDayPassive) {
                                                        .rdrStartEdge,
                                                        .rdrEndEdge,
                                                        .rdrInRange,
                                                        .rdrDayNumber {
                                                            inset: 0;
                                                        }
                                                        &.rdrDayStartOfMonth,
                                                        &.rdrDayStartOfWeek {
                                                            .rdrInRange,
                                                            .rdrEndEdge {
                                                                border-top-left-radius: 12px;
                                                                border-bottom-left-radius: 12px;
                                                            }
                                                        }

                                                        &.rdrDayEndOfMonth,
                                                        &.rdrDayEndOfWeek {
                                                            .rdrInRange,
                                                            .rdrStartEdge {
                                                                border-top-right-radius: 12px;
                                                                border-bottom-right-radius: 12px;
                                                            }
                                                        }

                                                        &.rdrDayStartOfMonth,
                                                        &.rdrDayStartOfWeek {
                                                            .rdrDayInPreview,
                                                            .rdrDayEndPreview {
                                                                border-top-left-radius: 12px;
                                                                border-bottom-left-radius: 12px;
                                                                border-left-width: 2px;
                                                            }
                                                        }
                                                        &.rdrDayEndOfMonth,
                                                        &.rdrDayStartPreview {
                                                            .rdrDayInPreview,
                                                            .rdrDayStartPreview {
                                                                border-top-right-radius: 12px;
                                                                border-bottom-right-radius: 12px;
                                                                border-right-width: 2px;
                                                            }
                                                        }

                                                        &.rdrDayEndOfWeek {
                                                            .rdrDayInPreview,
                                                            .rdrDayStartPreview {
                                                                border-top-right-radius: 12px;
                                                                border-bottom-right-radius: 12px;
                                                                border-right-width: 2px;
                                                            }
                                                        }

                                                        .rdrDayInPreview {
                                                            border-top-width: 2px;
                                                            border-bottom-width: 2px;
                                                            top: 0;
                                                            bottom: 0;
                                                        }
                                                        .rdrDayStartPreview {
                                                            border-top-left-radius: 12px;
                                                            border-bottom-left-radius: 12px;
                                                            border-top-width: 2px;
                                                            border-left-width: 2px;
                                                            border-bottom-width: 2px;
                                                            top: 0;
                                                            bottom: 0;
                                                        }
                                                        .rdrDayEndPreview {
                                                            border-top-right-radius: 12px;
                                                            border-bottom-right-radius: 12px;
                                                            border-top-width: 2px;
                                                            border-right-width: 2px;
                                                            border-bottom-width: 2px;
                                                            top: 0;
                                                            bottom: 0;
                                                        }
                                                        .rdrStartEdge.rdrEndEdge {
                                                            color: transparent !important;
                                                            border: 2px solid $primary-color;
                                                            & ~ .rdrDayNumber {
                                                                span {
                                                                    color: $primary-color;
                                                                }
                                                            }
                                                        }
                                                        .rdrEndEdge {
                                                            border-top-right-radius: 12px;
                                                            border-bottom-right-radius: 12px;
                                                        }
                                                        .rdrStartEdge {
                                                            border-top-left-radius: 12px;
                                                            border-bottom-left-radius: 12px;
                                                        }
                                                        .rdrDayStartPreview.rdrDayEndPreview {
                                                            border: none;
                                                            inset: 0;
                                                        }
                                                    }

                                                    &.rdrDayPassive {
                                                        span {
                                                            color: $disable-color !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .close {
                    @media (min-width: 767px) {
                        display: none !important;
                    }
                }
            }
        }
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes slide-down {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
}
@keyframes slide-left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slide-right {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}
