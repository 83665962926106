@import "../../final-report-common.module.scss";

.percentSumContainer {
    display: flex;
    align-items: flex-end;
}

.percentSumContainer:nth-child(3) {
    margin-left: auto;
}

.percentGraph {
    width: 56px;
    height: 160px;
    background-color: $secondary-gray;
    margin-right: 24px;
    display: flex;
    align-items: flex-end;
}

.itemPercent {
    background-color: $primary-pink;
    width: 100%;
}

.percentRatio {
    color: $primary-pink;
    font-size: 24px;
    font-weight: 600;
}

.percentCtg {
    color: $primary-text-color;
    font-size: 18px;
    font-weight: 500;
}

.portfolioTitle {
    font-size: 20px;
    color: $primary-text-color;
    margin-bottom: 24px;
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 60px;
    .card {
        aspect-ratio: 1;
        border-radius: 16px;
        position: relative;
        height: 226px;
        width: 226px;
        .logo {
            background-color: white;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            height: 52px;
            width: 105px;
            margin-left: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .courseName {
            font-size: 21px;
            font-weight: 600;
            color: white;
            margin: 12px 24px;
            line-height: 1.4;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
        .delegatesCount {
            width: max-content;
            background: white;
            border-radius: 8px;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            bottom: -15%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px 8px;
            .qty {
                font-size: 22px;
                font-weight: 600;
            }
            .qtyText {
                font-size: 14px;
            }
        }
    }
}

.portfolioContainer {
    .container {
        display: flex;
        .portfolioItem {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            width: 33.3%;
            .img {
                height: 64px;
                width: 64px;
                border-radius: 12px;
                margin-right: 24px;
            }
            .info {
                .title {
                    font-size: 18px;
                    font-weight: 600;
                }
                .session {
                    font-size: 18px;
                }
            }
        }
    }
}
