.square3x3{
    width:22px;
    margin-left:15px;
}
.up {
    margin-right:5px;
}
.flower {
    margin-right:5px;
}
.bag {
    @include fluid-Width($width_lg, $width_xxl, 54px, 98px);
    @include fluid-Height($width_lg, $width_xxl, 60px, 109px);
    margin-right:25px;
}

.overflow-hidden {
    overflow: hidden;
}