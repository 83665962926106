.salesBox {
    display: flex;
    text-align: left;
    flex-direction: column;

    .salesBoxHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    }
    .salesPrice {
        font-weight: 800;
        margin-bottom: 43px;
        color: $success-color;
    }
    .totalSalesBag {
        // position: absolute;
        // right: 1.875rem;
        margin-right: 50px;
        width: 80px;
        height: 75px;

        @media (max-width: 1441px) {
            margin-right: 0;
        }
    }

    .salesFooter {
        display: flex;
        flex-direction: row;

        .wonDeals {
            @include fluid-FontSize($width_lg, $width_xxl, 12px, 14px);
            color: $secondary-color;
            display: block;
            font-size: 14px !important;
            margin: 10px 0 auto 10px;
        }
        .salesTrend {
            @include fluid-FontSize($width_lg, $width_xxl, 12px, 14px);
            color: $secondary-color;
            display: block;
            margin: 10px 2.5rem;
        }
    }
    @media (max-width: $mobile-breakpoint) {
        .salesBoxHeader {
            flex-direction: column-reverse;
        }
        .salesFooter {
            flex-direction: column;
            position: relative;
            // right: -15vw;
            // width: 50vw;
            text-align: left;

            .salesTrend {
                margin: 10px;
            }
            .wonDeals{
                margin: 10px 0;
            }

            .footer-details {
                display: flex;
                gap: 16px;
            }
        }
        .totalSalesBag {
            margin-right: 0;
            width: 70px;
            height: 64px;
        }
    }
}

.cardTitle {
    font-weight: 600 !important;
    color: #305073 !important;
}

.salesCard {
    width: 100%;
    margin: 10px;
}
.salesCard .cardFluid {
    text-align: center;
}
.salesCardBlue {
    background-color: #e1e9ff !important;
}

@media only screen and (max-width: 1450px) and (min-width: 770px) {
    .totalSalesBag {
        margin-left: 1rem;
    }
}
@media only screen and (max-width: 769px) {
    .salesFooter {
        bottom: 20px !important;
    }
    
}
@media only screen and (max-width: $mobile-breakpoint) {
    .sales-search{
        div{
            width: 100%;
        }
    } 
}
.bold-text {
    font-weight: 600;
}
.color-green {
    color: $success-color !important;
}

.font-size-1rem {
    font-size: 1rem !important;
    line-height: 25px;
}

.color-56ccb4 {
    color: $success-color !important;
}

.forTabletSize {
    @media (max-width: 960px) {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        .heading,
        .page-heading {
        }
    }
}
.total-camp-labels {
    display: grid;
    grid-template-columns: 1fr;
    @media only screen and (min-width: 1400px) {
        grid-template-columns: auto auto auto;
    }
}
