body {
    background-color: $background-color !important;
    color: #213040 !important;
    font-family: "Poppins_PL" !important;
    &.modal-open {
        overflow: hidden !important;
    }
}

* a {
    color: inherit;
}

.text-disable-color {
    color: $disable-color !important;
}

.modal-input div:first-child {
    width: 90%;
}
.modal-sticky-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
}

.rem1-5-label {
    @media (max-width: 1920px) {
        font-size: 1.5rem !important;
    }
    @media (min-width: 1920px) {
        font-size: 1.75rem !important;
    }
}

.button-labels {
    font-weight: 600;
    font-size: 0.875rem;
}

.input-label {
    color: $disable-color;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: 0.25rem;
}

.small-text {
    font-size: 0.75rem;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: 0;
    text-align: start;
}
.just-small-text {
    font-size: 0.75rem;
    font-weight: 400;
}

.small-text-bold-secondary {
    font-size: 0.75rem;
    font-weight: 600;
    color: $secondary-color;
}

.card2 {
    padding: $card-padding-y $card-padding-x;
    background-color: $card-background-color;
    border-radius: $card-border-radius;

    &-header {
        color: $secondary-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .viewAllButton {
            color: $primary-color;
            text-decoration: underline;
        }
    }

    &-body {
        .miniTable {
            .avatar {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $disable-color;
                border-radius: 12px;
                margin-right: 25px;
                svg g [fill] {
                    transition: fill 0.3s ease;
                    fill: $secondary-color !important;
                }
            }
            .avatar-nameTag {
                font-size: 1rem;
                color: $secondary-color;
                font-weight: 600;
            }
        }
    }
}

.ordered-list {
    padding: 0;
    counter-reset: item;
    list-style-type: none;
    > li {
        position: relative;
        padding-left: 3.75rem;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: counter(item);
            counter-increment: item;
            font-weight: 600;
        }
    }
}
$mobile-breakpoint: 768px;
.custom-scroll {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background-color: inherit;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #e0e0e0;
        border: 3px solid transparent;
        border-radius: 6px;
        background-clip: content-box;
        &:hover {
            background-color: #c0c0c0;
            border-width: 2px;
        }
    }
}

.sidebar-wrapper {
    @extend .custom-scroll;
    position: fixed;
    top: 190px;
    height: calc(100vh - 190px);
    margin-left: -35px;
    padding: 32px;
    z-index: 3;
    border-radius: 0px 12px 12px 0px;
    background-color: $card-background-color;
    //transition: width 0.5s ease-in-out;
    overflow-x: hidden;
    z-index: 11;
    @media (max-width: 1920px) {
        padding: 28px;
    }
    .sidebar-content {
        //transition: all 0.5s ease-in-out;
        width: 375px;
        @media (max-width: 1920px) {
            width: 350px;
        }
        height: 100%;
        overflow-y: auto;
        overscroll-behavior-y: contain;
    }
    ///mobile breakpoint
    @media (max-width: $mobile-breakpoint) {
        display: none;
    }
}

.sidebar-open {
    padding-right: 16px;
    width: 450px; ////_!_!_!_!
    @media (max-width: 1920px) {
        width: 350px;
        padding-left: 12px;
    }
    .sidebar-content {
        padding-right: 16px;
    }
}
.sidebar-close {
    width: 36px;
}
.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
        cursor: pointer;
    }
}

.sidebar-chevron-left {
    position: absolute;
    top: 35px;
    left: 20px;
    transition: all 0.5s ease-in-out;
    transform: rotate(180deg);
    cursor: pointer;
}

.dropdown-menu {
    > li > a,
    > .dropdown-item {
        &.active,
        &:active {
            background-color: $primary-color;
        }
    }
}

@import "header";
@import "navbar";
@import "card";
@import "nav";
@import "tab";
@import "table";
@import "label";
@import "accounts";
@import "pagination";
@import "input";
@import "select";
@import "trainings";
@import "progressBar";
@import "sales";
@import "tooltip";
@import "utils";
@import "filterDrawer";
@import "deals";
@import "slick-slider";
