@import "../../final-report-common.module.scss";

.title {
    font-size: 20px;
    margin-bottom: 38px;
}
.container{
    margin: 64px 0 84px;

.portfolioTitle{
    font-size: 20px;
    color: $primary-text-color;
    text-align: center;
}


.cardContainer {
    display: flex;
    justify-content: center;
    .card {
        width: 240px;
        height: 240px;
        border-radius: 16px;
        position: relative;
        margin-right: 36px;
        margin-top: 32px;
        position: relative;
        .courseName {
            font-size: 20px;
            font-weight: 600;
            color: white;
            margin: 32px 18px;
        }
        .delegatesCount {
            width: max-content;
            background: white;
            border-radius: 10px;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            bottom: -15%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px 8px;
            .qty {
                font-size: 22px;
                font-weight: 600;
            }
            .qtyText {
                font-size: 14px;
            }
        }
    }
}
}

.revenueContainer {
    width: 45%;
 
    .statusBar {
        width: 100%;
        height: 56px;
        background-color: $tertiary-green;
        display: flex;
        align-items: center;
        
        .amount {
            padding-left: 24px;
            font-size: 24px;
            font-weight: 600;
        }
    }
    .subTitle {
        font-size: 18px;
        color: $primary-text-color;
        font-weight: 500;
        margin-top: 8px;
        margin-bottom: 48px;
    }
}
.frequentContainer {
    width: 45%;
    .fallbackNameTag {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        width: 98px;
        height: 98px;
        border-radius: 12px;
        font-size: 32px;
        font-weight: 600;
         color: #305073;
        transform: rotateX(0deg) rotate(-46deg);

    }
    .frequentInfo {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        margin-bottom: 56px;
        align-items: center;

        .brand {
            font-size: 18px;
            font-weight: 500;
        }
        .logo {
            width: 98px;
            height: 98px;
            border: 2px solid $primary-text-color;
            border-radius: 10px;
            transform: rotateY(0deg) rotate(45deg);
            display: flex;
            align-items: center;
            justify-content: center;

            margin: auto;
             .brandLogo {
                transform: rotateX(0deg) rotate(-46deg);
                .brandImg{
                    width: 65px;
                    height: 65px;
                }
            }
        }
        .session {
            text-align: center;
            .qty {
                font-size: 20px;
                font-weight: 600;
            }
            .qtyText {
                font-size: 16px;
            }
        }
    }
}
