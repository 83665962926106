.customSelectInput, .selectInputNewFilter {

    @media (min-width:992px) {
        &.nameTemplateInput{
            width:450px!important;
        }   
    }
    &__control {
        border-radius: $card-border-radius !important;
        border: $common-border-style $border-rest-color !important;
        font-weight: 400;
        background: $card-background-color !important;
        color: $secondary-color !important;
        box-shadow: $common-box-shadow $box-shadow-color !important;
        padding: 0 1.5rem;

        &--is-focused {
            border-radius: $card-border-radius !important;
            border: $common-border-style $border-rest-color !important;
            box-shadow: $common-box-shadow $box-shadow-color !important;
            &:hover {
                border: $common-border-style $border-rest-color !important;
                box-shadow: $common-box-shadow $box-shadow-color !important;
            }
        }
    }

    &__single-value {
        color: $secondary-color !important;
    }

    &__placeholder {
        color: transparentize($secondary-color, 0.5) !important;
    }

    &__value-container {
        // compansate for the border (2 * 1px)
        min-height: 42px !important;
    }

    &__option {
        background: none !important;
        border-radius: $card-border-radius;
        margin-bottom: 0.25rem;
        cursor: pointer !important;
        color: $secondary-color !important;
        transition: all 0.2s ease;
        position: relative;
        padding: 0.5rem 1.5rem;
        &--is-selected {
            background: $secondary-color !important;
            color: $card-background-color !important;
        }

        &:hover {
            background: transparentize($secondary-color, 0.5) !important;
            color: $card-background-color !important;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    &--no-wrap &__value-container {
        flex-wrap: nowrap !important;

        .customSelectInput {
            &__single-value,
            &__placeholder {
                position: static !important;
                transform: none !important;
            }
        }
    }

    &__menu {
        overflow: hidden;
        border-radius: $card-border-radius !important;
        border: $common-border-style $border-rest-color !important;
        box-shadow: none !important;
        background: $card-background-color !important;
        &-list {
            padding: 0.25rem;
        }
    }

    &__indicator {
        transition: all 0.2s ease;
        color: inherit !important;
        &:hover {
            color: inherit !important;
        }
    }

    &__dropdown-indicator svg {
        transition: all 0.2s ease;
        path {
            fill: inherit !important;
        }
    }

    &__control--menu-is-open &__dropdown-indicator svg {
        transform: rotate(-90deg);
    }

    &__multi-value {
        background: transparent !important;
        border: $common-border-style $border-rest-color !important;
        border-radius: 6px !important;
        overflow: hidden;
        &__remove {
            border-radius: 0 !important;
            cursor: pointer;
            &:hover {
                background-color: transparentize($secondary-color, 0.5) !important;
            }

            &:hover svg {
                fill: $card-background-color !important;
            }
        }
    }
}

