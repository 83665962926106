$danger-color: #b93d48;
$outline-background-color: #fcfcfc;
$primary-disabled-color: #a6b7ca;
$primary-disabled-text-color: #798b9f;
$secondary-disabled-text-color: #7c7878;
$borderless-text-color: #305073;

.btn {
    height: 2.75rem !important;
    min-width: 2.75rem !important;
    border-radius: 12px !important;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem !important;
    font-weight: 600;
    font-size: 0.875rem !important;
    overflow: hidden;
    @media (max-width: 1919px) {
        padding: 0.5rem 1rem 0.5rem 1rem !important;
    }
    @media (max-width: $mobile-breakpoint) {
        height: 2.5rem !important;
        min-width: 2.5rem !important;
    }
}

.icon-button-padding {
    padding: 0 !important;
}
.icon-padding {
    padding: 0.7rem !important;
}

//Primary Buttons
.btn-primary {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    box-shadow: $common-box-shadow transparentize($primary-color, 0.9);
}

.btn-primary:disabled {
    background-color: $primary-disabled-color !important;
    border-color: $primary-disabled-color !important;
    color: $primary-disabled-text-color !important;
}

.btn-outline-primary {
    background-color: transparent !important;
    border-color: $primary-color !important;
    color: $primary-color !important;
    box-shadow: $common-box-shadow transparentize($primary-color, 0.9);
    svg [fill] {
        stroke: $primary-color;
    }
    .icon-button2 {
        border-color: inherit;
        path {
            stroke: $primary-color;
        }
    }
    &:hover {
        background: $primary-color !important;
        color: $outline-background-color !important;
        border: $common-border-style $primary-color !important;
        height: inherit;
        svg [fill] {
            stroke: $outline-background-color;
        }
    }
}

.btn-outline-primary:disabled {
    background-color: $outline-background-color !important;
    border-color: $primary-disabled-text-color !important;
    color: $primary-disabled-text-color !important;
}

//Secondary Buttons
.btn-secondary {
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
    color: $outline-background-color !important;
    box-shadow: $common-box-shadow transparentize($secondary-color, 0.9);
}
.btn-secondary:disabled {
    background-color: transparent !important;
    border-color: $primary-disabled-color !important;
    color: $secondary-disabled-text-color !important;
    svg [fill] {
        stroke: $secondary-disabled-text-color !important;
    }
}
.btn-outline-secondary {
    background-color: transparent !important;
    border-color: $secondary-color !important;
    color: $secondary-color !important;
    box-shadow: $common-box-shadow transparentize($secondary-color, 0.9);
    svg [fill] {
        stroke: $secondary-color;
    }

    .icon-button2 {
        border-color: inherit;
    }

    &:hover {
        background: $secondary-color !important;
        color: $outline-background-color !important;
        border: $common-border-style $secondary-color !important;
        // width: inherit;
        height: inherit;
        svg [fill] {
            stroke: $outline-background-color;
        }
    }
}
.btn-outline-secondary:disabled {
    background-color: transparent !important;
    border-color: $primary-disabled-color !important;
    color: $primary-disabled-color !important;
    &:hover {
        background: $primary-disabled-color !important;
    }
}

//Success Buttons
.btn-success {
    background-color: $success-color !important;
    border-color: $success-color !important;
    color: $outline-background-color !important;
    box-shadow: $common-box-shadow transparentize($success-color, 0.9);
    .icon-button2 {
        background-color: #77d6c3;
        border-color: #77d6c3;
    }
}
.btn-outline-success {
    background-color: transparent !important;
    border-color: $success-color !important;
    color: $success-color !important;
    box-shadow: $common-box-shadow transparentize($success-color, 0.9);

    .icon-button2 {
        border-color: inherit;
        path,
        circle,
        line {
            stroke: $success-color;
        }
        color: $success-color;
    }

    .icon-button3 {
        color: $success-color;
        path :not(.excelIcon) {
            stroke: $success-color;
        }
    }
    &:hover {
        background: $success-color !important;
        color: $outline-background-color !important;
        border: $common-border-style $success-color !important;
        // width: inherit;
        height: inherit;

        .icon-button2 {
            border-color: inherit;
            color: $outline-background-color;
            path,
            circle,
            line {
                stroke: $outline-background-color;
            }
        }
        .icon-button3 {
            color: $outline-background-color;
            path :not(.excelIcon) {
                stroke: $outline-background-color;
            }
        }
    }
}

//Danger Buttons
.btn-danger {
    background-color: $danger-color !important;
    border-color: $danger-color !important;
    color: $outline-background-color !important;
    box-shadow: $common-box-shadow transparentize($danger-color, 0.9);
    .icon-button2 {
        background-color: #c7636c;
        border-color: #c7636c;
    }
}
.btn-outline-danger {
    background-color: transparent !important;
    border-color: $danger-color !important;
    color: $danger-color !important;
    box-shadow: $common-box-shadow transparentize($danger-color, 0.9);
    .icon-button2 {
        border-color: inherit;
        path {
            stroke: $danger-color;
        }
    }
    .icon-button3 {
        color: $danger-color;
    }
    &:hover {
        background: $danger-color !important;
        color: $outline-background-color !important;
        border: $common-border-style $danger-color !important;
        // width: inherit;
        height: inherit;
        .icon-button2 {
            border-color: inherit;
            color: $outline-background-color;
            path {
                stroke: $outline-background-color;
            }
        }
        .icon-button3 {
            color: $outline-background-color;
        }
    }
}

//Warning Buttons
.btn-warning {
    background-color: $warning-color !important;
    border-color: $warning-color !important;
    color: $outline-background-color !important;
    box-shadow: $common-box-shadow transparentize($warning-color, 0.9);
    .icon-button2 {
        background-color: #d4a17e;
        border-color: #d4a17e;
    }
}
.btn-outline-warning {
    background-color: transparent !important;
    border-color: $warning-color !important;
    color: $warning-color !important;
    box-shadow: $common-box-shadow transparentize($warning-color, 0.9);
    svg [fill] {
        stroke: $warning-color;
    }
    .icon-button2 {
        border-color: inherit;
        path {
            stroke: $warning-color;
        }
    }
    &:hover {
        background: $warning-color !important;
        color: $outline-background-color !important;
        border: $common-border-style $warning-color !important;
        width: inherit;
        height: inherit;
        svg [fill] {
            stroke: $outline-background-color;
        }
    }
}

.btn-disabledbtn {
    background-color: transparent !important;
    border: 0 !important;
    color: $primary-disabled-color !important;
    .icon-button2 {
        background-color: $primary-disabled-color;
        border-color: $primary-disabled-color;
    }
}

.btn-outline-disabledbtn {
    background-color: transparent !important;
    border-color: $primary-disabled-color !important;
    color: $primary-disabled-color !important;
    box-shadow: $common-box-shadow transparentize($primary-disabled-color, 0.9);
    svg [fill] {
        stroke: $primary-disabled-color;
    }
    .icon-button2 {
        border-color: inherit;
        path {
            stroke: $primary-disabled-color;
        }
    }
    &:hover {
        background: $primary-disabled-color !important;
        color: $outline-background-color !important;
        border: $common-border-style $primary-disabled-color !important;
        width: inherit;
        height: inherit;
        svg [fill] {
            stroke: $outline-background-color;
        }
    }
}
.btn-borderless {
    background-color: transparent !important;
    border: 0 !important;
    color: $borderless-text-color !important;
    font-size: 12px !important;
    font-weight: normal !important;
    padding-left: 0px !important;
    box-shadow: none !important;
    .icon-button2 {
        // width: 50px;
        // height: 30px;
        padding-right: 0px !important;
        svg {
            width: 20px !important;
            height: 20px !important;
        }
        background-color: transparent;
        border-color: transparent !important;
    }
}

//Icon Buttons
.icon-button {
    height: 2.7rem;
    @media (max-width: $mobile-breakpoint) {
        height: 2.5rem;
    }
}
.icon-button2 {
    border-right: 1.5px solid red;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.625rem;
    @media (max-width: $mobile-breakpoint) {
        padding: 0 0.5rem;
    }
}

.selectGridBtn {
    @extend .btn;
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: 1.5px solid #a6b7ca !important;
    transition: none !important;
    width: 44px;
    padding: 0 !important;

    svg {
        position: absolute;
        top: 26%;
        left: 30%;
        & > g > path {
            stroke: #a6b7ca;
        }
    }
    &.active {
        background-color: $secondary-color;
        border: 1.5px solid $secondary-color !important;
        svg > g > path {
            stroke: #fcfcfc;
        }
    }
}
.selectTableBtn {
    @extend .btn;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border: 1.5px solid #a6b7ca !important;
    transition: none !important;
    width: 44px;
    padding: 0 !important;
    position: absolute;
    top: 0;

    svg {
        position: absolute;
        top: 14px;
        left: 11px;
        & > g > path {
            stroke: #a6b7ca;
        }
    }
    &.active {
        background-color: $secondary-color;
        border: 1.5px solid $secondary-color !important;
        svg > g > path {
            stroke: #fcfcfc;
            fill: none;
        }
    }
}
.selectMobileSortBtn {
    @extend .btn;
    border: 1.5px solid $border-rest-color !important;
    box-shadow: 0px 3px 8px #3050731a;
    transition: none !important;
    width: 44px;
    padding: 0 !important;
    background-color: #fcfcfc;

    &.small {
        height: 2rem !important;
        padding: 0.25rem 1rem !important;
        font-weight: 400;
    }

    &.left {
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    &.middle {
        border-radius: 0px !important;
    }

    &.right {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 12px !important;
        border-top-right-radius: 12px !important;
    }

    &.single {
        max-width: fit-content;
    }

    &.active {
        border: 1.5px solid $secondary-color !important;
        font-weight: 600;
    }

    span {
        font-size: 0.75rem;
        font-weight: 400;
        color: $secondary-color;
    }
}

////switch toggle button
.switch-button {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 26px;
    margin-bottom: 0;
}

.switch-button input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .switch-slider {
    background-color: $success-color;
}

input:focus + .switch-slider {
    box-shadow: 0 0 1px $success-color;
}

input:checked + .switch-slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.switch-slider.switch-round {
    border-radius: 34px;
}

.switch-slider.switch-round:before {
    border-radius: 50%;
}

.customBootstrapBtn {
    padding: 0.25rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
