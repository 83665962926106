@import "../../final-report-common.module.scss";

.textGreen {
    color: $primary-green;
}

.semiBold {
    font-weight: 500;
}

.highestItem {
    font-weight: 600 !important;
    color: $primary-green !important;
}

.itemTitle {
    font-size: 20px;
    color: $primary-text-color;
    margin-bottom: 14px;
}

.graph {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 18px 0;
    justify-content: center;

    .ftof {
        position: relative;
        background-color: $secondary-gray;
        width: 47%;
        height: 48px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .fillBar {
            position: absolute;
            height: 100%;
            background-color: #e289a3;
            right: 0;
        }
        .barText {
            display: flex;
            height: 100%;
            align-items: center;
            z-index: 10;
            .percentageText {
                color: $primary-pink;
                font-size: 22px;
                font-weight: 600;
                margin-right: 12px;
            }
            .typeText {
                color: $primary-text-color;
                font-size: 18px;
                font-weight: 500;
                margin-right: 12px;
            }
        }
    }
    .ftofRight {
        @extend .ftof;
        justify-content: flex-start;
        .fillBar {
            background-color: $tertiary-green;
            left: 0;
        }
        .barText {
            padding-left: 12px;
            .percentageText {
                color: $primary-green;
            }
        }
    }

    .versus {
        width: 6%;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
    }
}

.infoGraph {
    text-align: center;
    .infoText {
        font-size: 15px;
    }
}

.salesThisMonthTitle {
    font-size: 18px;
    font-weight: 500;
    color: $primary-text-color;
    margin-bottom: 6px;
}

.salesThisMonthAmount {
    font-size: 18px;
    font-weight: 600;
    color: $primary-green;
}