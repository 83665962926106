$width_sm: 360px;
$width_md: 992px;
$width_lg: 993px;
$width_xl: 1440px;
$width_xxl: 1800px;

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
  }

@mixin fluid-FontSize($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);
  
    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
      & {
        font-size: $min-font-size !important;
        @media screen and (min-width: $min-vw) {
            font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})) !important;
        }
        @media screen and (min-width: $max-vw) {
            font-size: $max-font-size !important;
        }
      }
    }
}

@mixin fluid-Width($min-vw, $max-vw, $min-width, $max-width) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-width);
    $u4: unit($max-width);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
        width: $min-width !important;
        @media screen and (min-width: $min-vw) {
            width: calc(#{$min-width} + #{strip-unit($max-width - $min-width)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})) !important;
        }
        @media screen and (min-width: $max-vw) {
            width: $max-width !important;
        }
        }
    }
}

@mixin fluid-Height($min-vw, $max-vw, $min-height, $max-height) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-height);
    $u4: unit($max-height);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
        height: $min-height !important;
        @media screen and (min-width: $min-vw) {
            height: calc(#{$min-height} + #{strip-unit($max-height - $min-height)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})) !important;
        }
        @media screen and (min-width: $max-vw) {
            height: $max-height !important;
        }
        }
    }
}

@mixin fluid-Height($min-vw, $max-vw, $min-height, $max-height) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-height);
    $u4: unit($max-height);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
        height: $min-height !important;
        @media screen and (min-width: $min-vw) {
            height: calc(#{$min-height} + #{strip-unit($max-height - $min-height)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})) !important;
        }
        @media screen and (min-width: $max-vw) {
            height: $max-height !important;
        }
        }
    }
}

