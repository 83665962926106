.filterSubheader {
    top: 119px !important;
}
.filter-header {
    margin-bottom: 20px;
    margin-top: -8px;
}

.subHeaderForm {
    margin: 0 !important;
    margin-right: 10px !important;
    min-width: 200px;
}

.filterPadding {
    padding: 55px 5px 0;
    background-color: #eef0f8 !important;
}

.subHeaderFilter__control {
    box-shadow: none !important;
}

.subHeaderFilter__control--is-focused {
    border-color: hsl(0, 0%, 70%) !important;
}

.dateFilter {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.css-1okebmr-indicatorSeparator {
    width: 0 !important;
}

.css-yk16xz-control {
    border-color: #ecf0f3 !important;
    border-radius: 4px !important;
}

.dropdown-filter .subHeaderFilter__control {
    cursor: pointer;
}

// Reports Filter Modal
.rdrStaticRanges span,
.rdrDateDisplayWrapper input {
    color: #305073;
    font-weight: 600 !important;
}
.rdrDayNumber span {
    color: #305073;
    font-weight: 400 !important;
}
.rdrMonthAndYearPickers select {
    color: #305073 !important;
}
.rdrDateDisplayWrapper span,
.rdrWeekDays span,
.rdrDayPassive .rdrDayNumber span {
    color: #6490bf !important;
}
span .rdrEndEdge,
span .rdrInRange,
span .rdrStartEdge {
    color: #6490bf !important;
}

@media (min-width: 992px) {
    .filterSubheader {
        height: 70px !important;
    }

    /*.daterangepicker {
        position: fixed !important;
        top: 182px !important;
    }

    .daterangepicker.opensright:before, .daterangepicker.opensright:after {
        left: auto;
        right: 17px;
    }*/
}

@media (max-width: 1200px) {
    .subHeader-buttons {
        display: none;
    }
}

@media (max-width: 991px) {
    .filterPadding {
        padding-top: 0;
    }

    .filterPadding .filterSubheader {
        margin: 0 -20px 10px;
        padding: 15px 0 !important;
        //border-radius: 0.25rem;
        //box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    }

    .daterangepicker {
        right: 15px !important;
        top: 175px !important;
        z-index: 3;
    }

    .daterangepicker.opensright:after,
    .daterangepicker.opensright:before {
        left: auto !important;
        right: 17px !important;
    }
}

@media (max-width: 729px) {
    .ranges,
    .daterangepicker .ranges ul {
        width: 100% !important;
        max-height: 115px;
        overflow-y: scroll;
    }

    .daterangepicker.show-calendar .drp-calendar {
        display: block;
        max-width: 100%;
    }

    .daterangepicker .drp-calendar.left {
        padding: 8px !important;
    }
}

@media (min-width: 576px) {
    /*.dropdown-filter, .datepicker {
        display: flex;
        align-items: center;
    }*/
}

@media (max-width: 575px) {
    .dropdown-filter {
        display: block;
        width: 100%;

        .subHeaderForm {
            margin-bottom: 10px !important;
            margin-right: 0 !important;
        }
    }

    .datepicker {
        display: block !important;
        width: 100%;

        .dateFilter {
            float: right;
        }
    }
}

@media (max-width: 360px) {
    .daterangepicker {
        top: 281px !important;
    }
}

@media (max-width: 991.98px) {
    .subheader-solid {
        border-top: none !important;
        margin-bottom: 0 !important;
    }
}
@media (max-width: $mobile-breakpoint) {
    .subheader-solid {
        box-shadow: 0 4px 10px #3050731a;
    }
}
.dropdown-toggle::after {
    //content: none !important;
    color: hsl(0, 0%, 20%) !important;
}

.subHeaderForm {
    padding-right: 20px;
}

#filterDropdown .dropdown-toggle {
    background: rgba(0, 0, 0, 0) !important;
    color: hsl(0, 0%, 20%) !important;
    border-color: #ecf0f3 !important;
    border-radius: 4px !important;
    padding: 7.5px 12.5px !important;
}

#filterDropdown.show .dropdown-toggle {
    border-color: hsl(0, 0%, 70%) !important;
}

.filterSubheader {
    top: 65px !important;
}

.filterPadding {
    padding: 5px 5px;
}

.subHeaderFilter__option {
    color: hsl(0, 0%, 20%) !important;
}

.subHeaderFilter__option label {
    width: 88%;
}

.subHeaderFilter__option--is-selected {
    color: white !important;
}

.dateFilter {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    cursor: pointer;
}

.rdrInputRanges {
    display: none;
}
.dropdownPL {
    display: inline;
}

.searchPL {
    display: inline-block;
}
@media (min-width: 768px) {
    .rdrDefinedRangesWrapper {
        width: 120px;
    }

    .dropdown-menu-withDateRangePicker {
        width: 530px !important;
    }

    .rdrMonth {
        width: 330px;
    }

    .rdrDateRangePickerWrapper {
        flex-direction: row;
    }

    .rdrStaticRanges {
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .rdrDefinedRangesWrapper {
        width: 110px;
    }
    .dropdown-menu-withDateRangePicker {
        width: 430px !important;
    }

    .rdrMonth {
        width: 230px;
    }

    .rdrPprevButton {
        margin-right: 0;
    }

    .rdrNextButton {
        margin-left: 0;
    }

    .rdrDateRangePickerWrapper {
        flex-direction: row;
    }

    .rdrStaticRanges {
        flex-direction: column;
    }
}

@media (max-width: 576px) {
    .subHeader-dropdownIcon {
        display: none;
    }
    .dropdown-menu-withDateRangePicker {
        width: 300px !important;
        position: fixed !important;
        top: 130px !important;
        left: auto !important;
        right: 10px !important;
        transform: translate(0px, 0px) !important;
    }

    .rdrDefinedRangesWrapper {
        overflow-x: scroll;
    }

    .rdrDefinedRangesWrapper,
    .rdrMonth {
        width: 260px;
    }

    .rdrDateRangePickerWrapper {
        flex-direction: column;
    }

    .rdrStaticRanges {
        flex-direction: row;
    }
    .rdrStaticRangeLabel {
        padding: 10px;
    }
}

.btn-group-subheader {
    margin-left: 10px;
    @media (max-width: 450px) {
        margin-left: 0px;
    }
}

.subHeaderFilter__group-heading {
    text-transform: none !important;
    color: rgb(51, 51, 51) !important;
    font-size: 100% !important;
    padding: 0 3px !important;
}

.deptFilter .subHeaderFilter__option {
    padding-left: 20px;
}

.subheaderButtonPassive i {
    color: #525255;
}

.subheaderButtonActive i {
    color: #6a83ff;
}

.launchButton {
    background: #d7f9ef !important;
    color: #a1a1a1 !important;
    // border: 0;
    font-size: 18px;
    font-weight: 600;
}

.launchButton img {
    margin-right: 15px;
}

.downloadBrochure {
    svg {
        width: 24px;
    }
}

.cloneButton {
    // background: #eff2ff !important;
    // color: #a1a1a1 !important;
    // border: 0;
    font-size: 14px;
    font-weight: 600;
}

.deleteButton {
    background: #f64e60 !important;
    color: #fff !important;
    // border: 0;
    font-size: 14px;
    font-weight: 600;
}

.editButton {
    background: #eff2ff !important;
    color: #a1a1a1 !important;
    border: 0;
    font-size: 14px;
    font-weight: 600;
}

.dropdownDropdownPreCampaigns {
    padding: 20px !important;
}

.launchButton:disabled,
.cloneButton:disabled .deleteButton:disabled,
.editButton:disabled {
    pointer-events: none;
}

.titleFilterButton {
    border: 0;
    background: #eff2ff;
    font-size: 14px;
    color: #464e5f;
    border-radius: 5px;
    margin-right: 10px;
    padding: 5px;
}

@media (max-width: 425px) {
    .titleFilterButton {
        position: absolute;
        top: 55px;
        right: 10px;
        z-index: 5;
    }
}

.surveyInfoEditButton {
    box-shadow: $common-box-shadow $box-shadow-color;
    .icon-button2 {
        box-shadow: 3px 0px 8px $box-shadow-color;
    }
}

.surveyInfoInputType {
    text-align: right;
    width: 73%;
}
.questionTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
}
.inlineSurveyInfoInputType {
    visibility: hidden;
    height: 0;
}

@media (max-width: 769px) {
    .surveyInfoInputType {
        width: 97%;
    }
    .questionTextContainer {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .surveyInfoInputType {
        visibility: hidden;
    }
    .inlineSurveyInfoInputType {
        visibility: visible;
        height: auto;
    }
}

// Custom Dropdown Design
.dropdown-menu {
    border-radius: 12px;
    transform: translateY(30px);
    background-color: #fcfcfc;
    .head {
        display: none !important;
    }
    .navi {
        padding: 32px 24px 24px 24px;

        > .row {
            // > .col-md-6:nth-child(2n-1) {
            //     padding-right: 3px;
            // }
            // > .col-md-6:nth-child(2n) {
            //     padding-left: 3px;
            // }
            margin-bottom: 16px;

            .navi-item {
                .navi-link {
                    padding: 0;
                    &:hover {
                        background: transparent;
                    }
                    .selectInputNewFilter__control {
                        border: 2px solid $border-rest-color !important;
                        cursor: pointer;
                        .selectInputNewFilter__value-container {
                            .selectInputNewFilter__placeholder,
                            .selectInputNewFilter__single-value {
                                color: $secondary-color !important;
                                font-size: 14px !important;
                                font-weight: 400 !important;
                            }
                        }
                        .selectInputNewFilter__indicators {
                            .selectInputNewFilter__dropdown-indicator,
                            .selectInputNewFilter__clear-indicator {
                                > svg > path {
                                    fill: $secondary-color !important;
                                }
                            }
                        }
                    }
                    .selectInputNewFilter__menu {
                        .selectInputNewFilter__option {
                            display: flex;
                            align-items: center;
                            font-size: 14px !important;
                            color: $primary-color;
                            cursor: pointer;
                            font-weight: normal;
                            input[type="checkbox"] {
                                left: 8px;
                                top: 0;
                            }
                        }
                    }
                    .subHeaderForm {
                        padding-right: 0;

                        .subHeaderFilter__control {
                            border: 2px solid $border-rest-color !important;
                            background-color: transparent;
                            cursor: pointer;
                            margin: 5px;
                            min-height: 44px !important;
                            width: 100%;
                            .subHeaderFilter__value-container {
                                .subHeaderFilter__placeholder {
                                    color: $secondary-color !important;
                                    font-size: 14px !important;
                                    font-weight: 400 !important;
                                }
                            }
                            .subHeaderFilter__indicators {
                                .subHeaderFilter__dropdown-indicator,
                                .subHeaderFilter__clear-indicator {
                                    > svg > path {
                                        fill: $secondary-color !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        > .navi-item {
            .navi-link {
                padding: 0;
                &:hover {
                    background: transparent;
                }
                .rdrDateRangePickerWrapper {
                    width: 100%;
                    gap: 40px;
                    .rdrDefinedRangesWrapper {
                        font-size: 14px;
                        background: transparent;
                        border-right: 0px;
                        @media (min-width: 768px) {
                            width: 110px;
                        }
                    }
                }

                .rdrStaticRange {
                    border-bottom: 0;
                    background: transparent;
                    margin-bottom: 4px;
                    text-align: left;
                    &:hover {
                        .rdrStaticRangeLabel {
                            background: rgba($primary-color, 0.1);
                        }
                    }
                    &.rdrStaticRangeSelected {
                        .rdrStaticRangeLabel {
                            color: #f5f7fa;
                            background: $primary-color;
                        }
                    }
                    .rdrStaticRangeLabel {
                        padding: 8px 12px;
                        display: inline-block;
                        border-radius: 6px;
                    }
                }
                .rdrCalendarWrapper {
                    font-size: 14px;
                    background: transparent;
                    flex: 1;
                    padding: 0 20px;
                    .rdrDateDisplayWrapper {
                        background-color: transparent;
                        .rdrDateDisplay {
                            margin: 0;
                            .rdrDateDisplayItem {
                                box-shadow: none;
                                border: 2px solid $border-rest-color;
                                background-color: transparent;
                                border-radius: 8px;
                                &.rdrDateDisplayItemActive {
                                    border-color: $primary-color;
                                }
                            }
                        }
                    }
                    .rdrMonthAndYearWrapper {
                        .rdrMonthAndYearPickers {
                            order: 1;
                            justify-content: flex-start;
                            background: none;

                            .rdrMonthPicker {
                                margin-right: 30px;
                            }
                            select {
                                font-weight: 600;
                                background: none;
                                text-align: left;
                                padding: 0;
                            }
                        }
                        .rdrNextPrevButton {
                            background: transparent;
                            position: relative;
                            width: 21px;
                            height: 21px;

                            i {
                                border: solid $secondary-color;
                                border-width: 0 3px 3px 0;
                                padding: 6px;
                                border-radius: 0px 0px 2px 0px;
                                position: absolute;
                                top: calc(50% - 3px);
                            }
                        }
                        .rdrPprevButton {
                            order: 3;
                            i {
                                top: calc(50% - 12px);
                                transform: rotate(45deg);
                                -webkit-transform: rotate(45deg);
                            }
                        }
                        .rdrNextButton {
                            order: 2;

                            i {
                                transform: rotate(-135deg);
                                -webkit-transform: rotate(-135deg);
                            }
                        }
                    }
                    .rdrMonths {
                        .rdrMonth {
                            width: 100%;
                            padding: 0;
                            .rdrWeekDays {
                                span {
                                    color: $secondary-color !important;
                                }
                            }
                            .rdrDays {
                                gap: 5px 0;
                                .rdrDay {
                                    aspect-ratio: 1;
                                    height: auto;

                                    &:not(.rdrDayPassive) {
                                        .rdrStartEdge,
                                        .rdrEndEdge,
                                        .rdrInRange,
                                        .rdrDayNumber {
                                            inset: 0;
                                        }
                                        &.rdrDayStartOfMonth,
                                        &.rdrDayStartOfWeek {
                                            .rdrInRange,
                                            .rdrEndEdge {
                                                border-top-left-radius: 12px;
                                                border-bottom-left-radius: 12px;
                                            }
                                        }

                                        &.rdrDayEndOfMonth,
                                        &.rdrDayEndOfWeek {
                                            .rdrInRange,
                                            .rdrStartEdge {
                                                border-top-right-radius: 12px;
                                                border-bottom-right-radius: 12px;
                                            }
                                        }

                                        &.rdrDayStartOfMonth,
                                        &.rdrDayStartOfWeek {
                                            .rdrDayInPreview,
                                            .rdrDayEndPreview {
                                                border-top-left-radius: 12px;
                                                border-bottom-left-radius: 12px;
                                                border-left-width: 2px;
                                            }
                                        }
                                        &.rdrDayEndOfMonth,
                                        &.rdrDayStartPreview {
                                            .rdrDayInPreview,
                                            .rdrDayStartPreview {
                                                border-top-right-radius: 12px;
                                                border-bottom-right-radius: 12px;
                                                border-right-width: 2px;
                                            }
                                        }

                                        &.rdrDayEndOfWeek {
                                            .rdrDayInPreview,
                                            .rdrDayStartPreview {
                                                border-top-right-radius: 12px;
                                                border-bottom-right-radius: 12px;
                                                border-right-width: 2px;
                                            }
                                        }

                                        .rdrDayInPreview {
                                            border-top-width: 2px;
                                            border-bottom-width: 2px;
                                            top: 0;
                                            bottom: 0;
                                        }
                                        .rdrDayStartPreview {
                                            border-top-left-radius: 12px;
                                            border-bottom-left-radius: 12px;
                                            border-top-width: 2px;
                                            border-left-width: 2px;
                                            border-bottom-width: 2px;
                                            top: 0;
                                            bottom: 0;
                                        }
                                        .rdrDayEndPreview {
                                            border-top-right-radius: 12px;
                                            border-bottom-right-radius: 12px;
                                            border-top-width: 2px;
                                            border-right-width: 2px;
                                            border-bottom-width: 2px;
                                            top: 0;
                                            bottom: 0;
                                        }
                                        .rdrStartEdge.rdrEndEdge {
                                            color: transparent !important;
                                            border: 2px solid $primary-color;
                                            & ~ .rdrDayNumber {
                                                span {
                                                    color: $primary-color;
                                                }
                                            }
                                        }
                                        .rdrEndEdge {
                                            border-top-right-radius: 12px;
                                            border-bottom-right-radius: 12px;
                                        }
                                        .rdrStartEdge {
                                            border-top-left-radius: 12px;
                                            border-bottom-left-radius: 12px;
                                        }
                                        .rdrDayStartPreview.rdrDayEndPreview {
                                            border: none;
                                            inset: 0;
                                        }
                                    }

                                    &.rdrDayPassive {
                                        span {
                                            color: $disable-color !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
