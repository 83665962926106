@media print {
    @page {
        -webkit-print-color-adjust: exact;
        margin: 0;
    }

    .table-responsive {
        -webkit-print-color-adjust: exact;
    }

    body {
        background-color: white !important;
    }

    .card {
        page-break-after: auto !important;
        page-break-inside: avoid !important;
        position: relative;
        display: block !important;
        background-color: white;
    }

    .subheader {
        display: none !important;
    }

    .p-flex {
        display: flex !important;
    }

    .avoid-page-break {
        page-break-inside: avoid !important;
    }

    .p-block-row {
        display: block !important;

        > div {
            display: inline-block !important;
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    .nonbreakable-row {
        page-break-after: auto !important;
        page-break-inside: avoid !important;
        position: relative;
        display: block !important;
    }

    .salesContainer {
        display: block !important;
        flex-direction: column !important;
    }

    .row-flex-print .col-md-6 {
        width: calc(50% - 10px);
        float: left;
    }

    .row-flex-print .col-md-6:first-child {
        margin-right: 20px;
    }

    .salesContainer .salesCard {
        width: calc(50% - 10px);
    }

    .salesContainer .salesCard:first-child {
        margin-right: 20px;
    }

    span,
    a:link,
    a:visited {
        text-decoration: none !important;
    }

    .top2Accounts {
        padding: 20px;
    }

    .header-mobile {
        position: absolute !important;
    }

    .d-xxl-flex,
    .cardToolbarMuted,
    .topbar,
    .footer,
    .scrolltop {
        display: none !important;
    }

    .subHeader-buttons {
        display: block;
    }

    #kt_header_mobile div,
    .breadcrumb,
    .pdf-icon,
    #filterDropdown {
        display: none !important;
    }

    .indicatorBox,
    .errorBox {
        margin-top: 20px !important;
    }

    .coloredCard {
        width: 150px;
        margin-top: 50px !important;
    }

    .coloredCard div {
        text-align: left;
    }

    .asideFilter {
        display: none !important;
    }
}
