// ::root {
//     --fc-event-response: #eff3f8;
//     --fc-event-accepted: #56ccb4;
//     --fc-event-rejected: #db6671;
//     --fc-event-tentative: #d3e1f4;
// }
$fc-event-response: #eff3f8;
$fc-event-accepted: #56ccb4;
$fc-event-rejected: #db6671;
$fc-event-tentative: #d3e1f4;
$fc-event-lvt-house: #f8e1d1;
$fc-event-lvt-public: #d3e1f4;
$fc-event-mentoring: #f7dadd;
$fc-event-public: #d3f0ea;

.fc {
    .fc-day-mon {
        position: relative;
    }
    .fc-daygrid-day,
    .fc-timegrid-col {
        position: relative;
        &.fc-day-today {
            background-color: rgba(255, 220, 40, 0.06) !important;
            @media screen and (max-width: 991px) {
                background-color: $background-color !important;
            }
        }
    }

    .off-day.fc-daygrid-event {
        position: absolute;
        border-radius: 0 !important;
        height: 100%;
        width: 100%;
    }
    .off-day {
        z-index: 4;
        background-color: #fff;

        color: #a6b7ca !important;

        &.fc-h-event .fc-event-title {
            font-size: 1rem;
            color: #a6b7ca !important;
            top: 10%;
        }

        .fc-event-time {
            display: none;
        }
        &::before {
            content: "";
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-bottom: 10px;
            width: 58px;
            height: 58px;
            background-image: url("/media/svg/popleads/off-day-icon.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 58px;
        }
        &::after {
            content: "Off Day";
            font-size: 1rem;
            position: absolute;

            top: 90%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: 10px;
            width: 65px;
        }

        &.fc-timegrid-event {
            &::after {
                top: 57% !important;
            }
            &::before {
                top: 53% !important;
            }
        }
    }
    &.fc-timegrid-col {
        height: 100%;
    }

    .fc-daygrid-event,
    .fc-timegrid-event {
        white-space: normal !important;
        margin: 0;
        padding: 8px;
        border-radius: 12px !important;
        border: none !important;
        cursor: default;

        .fc-event-main {
            font-size: 12px;
            .name-tag {
                width: 32px;
                height: 32px;
                line-height: 32px;
                min-width: 32px;
                min-height: 32px;
                position: relative;
                font-size: 1rem;
                background-color: #a6b7ca;
                overflow: hidden;
                text-align: center;
                border-radius: 8px;
                color: #305073;
                font-weight: 600;
            }
            .trainer-img {
                border-radius: 8px;
            }

            .eventType1 {
                display: flex;
                flex-direction: column;
                margin-left: 12px;
                width: 100%;
                align-items: flex-start;
                @media screen and (max-width: 991px) {
                    color: $secondary-color;
                }
                .topPart {
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    row-gap: 5px;
                    column-gap: 30px;
                }
            }

            .eventType2 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                margin-left: 12px;
                width: 100%;
                @media screen and (max-width: 991px) {
                    color: $secondary-color;
                }
                .leftPart {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    row-gap: 5px;
                    margin-right: 15px;
                }
                .eventStatus {
                    text-transform: capitalize;
                }
            }

            .trainerName {
                font-size: 16px;
                font-weight: 600;
                text-overflow: ellipsis;
                overflow: hidden;

                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-word;
                line-height: 16px;
                a {
                    &:hover {
                        color: $secondary-color;
                    }
                }
            }

            .campaignType {
                width: auto;
                svg {
                    width: 100%;
                    height: 100%;
                    max-width: 20px;
                    max-height: 20px;
                    margin-right: 4px;
                }
                div {
                    // text-overflow: ellipsis;
                    // overflow: hidden;
                    display: block;
                    white-space: nowrap;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 1;
                    // -webkit-box-orient: vertical;
                    // word-break: break-word;
                }
            }

            .eventTime {
                // margin-top: 8px;
                .timeIcon {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        width: 100%;
                        height: 100%;
                        path {
                            stroke-width: 6px;
                        }
                    }
                }
            }

            .eventDesc {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                word-break: break-word;
                margin-top: 8px;
            }

            .offday {
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                // align-items: center;
                // justify-content: center;
                svg {
                    width: 58px;
                    height: 58px;
                    stroke: $disable-color;
                }
                span {
                    margin-top: 12px;
                    font-size: 1rem;
                    color: $disable-color;
                }
            }
        }

        &.single-day {
            @media screen and (min-width: 992px) and (max-width: 1280px) {
                .fc-event-main {
                    .event-wrapper {
                        flex-direction: column;
                        .eventType1 {
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }

        &.fc-event-accepted {
            background-color: $fc-event-accepted;
        }
        &.fc-event-rejected {
            background-color: $fc-event-rejected;
        }
        &.fc-event-response {
            background-color: $fc-event-response;
        }
        &.fc-event-tentative {
            background-color: $fc-event-tentative;
        }
        &.fc-event-public {
            background-color: $fc-event-public;
        }
        &.fc-event-lvt-house {
            background-color: $fc-event-lvt-house;
        }
        &.fc-event-lvt-public {
            background-color: $fc-event-lvt-public;
        }
        &.fc-event-mentoring {
            background-color: $fc-event-mentoring;
        }

        &.fc-event-public,
        &.fc-event-lvt-house,
        &.fc-event-lvt-public,
        &.fc-event-mentoring {
            .fc-event-main {
                color: $secondary-color;
                .campaignType {
                    svg {
                        path {
                            fill: $secondary-color;
                        }
                    }
                }
                .eventTime {
                    svg path:first-child {
                        stroke: $secondary-color;
                    }
                }
            }
        }

        &.fc-event-response,
        &.fc-event-tentative {
            .fc-event-main {
                color: $secondary-color;
                .subject {
                    color: $disable-color;
                }
                .eventTime {
                    svg path:first-child {
                        stroke: $secondary-color;
                    }
                }
            }
        }

        &.fc-event-accepted,
        &.fc-event-rejected {
            .fc-event-main {
                color: $card-background-color;
                .subject {
                    color: $background-color;
                    @media screen and (max-width: 991px) {
                        color: $secondary-color;
                    }
                }
                .eventTime {
                    svg path {
                        stroke: $background-color;
                        @media screen and (max-width: 991px) {
                            stroke: $secondary-color;
                        }
                    }
                }
            }
        }
    }
    .fc-timegrid {
        .fc-event-main {
            .offday {
                top: 50% !important;
            }
        }
    }
    .fc-scrollgrid-section-sticky > th {
        background-color: #fcfcfc;
    }
    .fc-header-toolbar {
        .fc-toolbar-chunk:first-child {
            min-width: 238px;
            height: 44px;
        }
        .fc-toolbar-chunk:nth-child(2) {
            > div {
                display: flex;
                align-items: center;
                gap: 25px;
                .fc-button {
                    width: 45px;
                    height: 44px;
                    background-color: $card-background-color;
                    border: 1px solid $disable-color;
                    box-shadow: 0 3px 8px #3050731a;
                    border-radius: 12px;
                    color: $secondary-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 16px 20px;
                    &::before {
                        content: "";
                        border: solid $secondary-color;
                        border-width: 0 3px 3px 0;
                        display: inline-block;
                        padding: 4px;
                        border-radius: 0px 0px 2px 0px;
                    }
                    &.fc-prev-button {
                        &::before {
                            margin-right: -5px;
                            transform: rotate(135deg);
                            -webkit-transform: rotate(135deg);
                        }
                    }
                    &.fc-next-button {
                        &::before {
                            margin-left: -5px;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                        }
                    }
                }
                .fc-toolbar-title {
                    font-size: 28px;
                    font-weight: 600;
                    color: $secondary-color;
                }
            }
        }
        .fc-toolbar-chunk:nth-child(3) {
            .fc-button-group {
                align-items: center;
                gap: 16px;
                .fc-button-primary {
                    background-color: $card-background-color;
                    border: 1px solid $disable-color;
                    box-shadow: 0 3px 8px #3050731a;
                    border-radius: 12px;
                    color: $secondary-color;
                    padding: 12px 24px;
                    font-size: 14px;
                    font-weight: 600;
                    &.fc-button-active {
                        background-color: $secondary-color;
                        color: $card-background-color;
                        border-color: transparent;
                    }
                }
                .fc-toolbar-title {
                    font-size: 28px;
                    font-weight: 600;
                    color: $secondary-color;
                }
            }
        }
    }
    .fc-view-harness {
        background-color: $card-background-color;
        box-shadow: 0 3px 8px #3050731a;
        border-radius: 12px;
        overflow: hidden;
        padding: 18px 21px;
        // z-index: -1;

        @media screen and (max-width: 991px) {
            background-color: $background-color;
            box-shadow: none;
            padding: 0;
        }
        .fc-daygrid {
            .fc-scrollgrid {
                border: none;
                > thead {
                    @media screen and (max-width: 991px) {
                        display: none !important;
                    }
                    .fc-scrollgrid-section-sticky {
                        background: $card-background-color !important;

                        > th {
                            border: none !important;
                        }

                        .fc-col-header-cell {
                            border: none !important;
                            text-align: left !important;
                            padding-left: 12px;

                            a {
                                color: $disable-color;
                                font-size: 16px;
                                font-weight: 600;
                            }
                        }
                    }
                }
                > tbody {
                    &::before {
                        height: 28px;
                        display: table-row;
                        content: "";
                        @media screen and (max-width: 991px) {
                            display: none !important;
                        }
                    }
                    .fc-scrollgrid-section {
                        > td {
                            border: none !important;
                            .fc-daygrid-day {
                                padding: 0 10px;
                                border: 1px solid #d6dee8 !important;
                                &:last-child {
                                    border-right: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .fc-timegrid {
            .fc-scrollgrid {
                border: none;
                > thead {
                    .fc-scrollgrid-section-sticky {
                        background: $card-background-color !important;
                        > th {
                            border-right-color: transparent !important;
                        }
                        .fc-timegrid-axis {
                            border-bottom-color: $disable-color;
                        }
                        .fc-col-header-cell {
                            text-align: left !important;
                            padding-left: 12px;
                            padding-bottom: 20px;
                            border-color: #d6dee8;
                            border-bottom-color: $disable-color;
                            a {
                                display: flex;
                                align-items: flex-start;
                                flex-direction: column;
                                color: $disable-color;
                                font-size: 16px;
                                font-weight: 600;
                                span {
                                    font-size: 22px;
                                    font-weight: 600;
                                    color: $secondary-color;
                                }
                            }
                        }
                    }
                }
                > tbody {
                    td {
                        border-color: #d6dee8;
                    }
                    .fc-timegrid-slots {
                        .fc-timegrid-slot-label-cushion {
                            font-size: 12px;
                            font-weight: 600;
                            color: $disable-color;
                        }
                    }
                }
            }
        }
    }

    .fc-daygrid-day-top {
        flex-direction: row !important;
    }

    .fc-day-disabled {
        background-color: $card-background-color !important;
    }
    .fc-daygrid-day-number {
        font-size: 22px;
        font-weight: 600;
        color: $secondary-color !important;
    }
    .fc-daygrid-day-frame {
        display: flex;
        flex-direction: column;
        .fc-daygrid-day-events {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 100%;
            margin-bottom: 0 !important;
            // position: unset !important;
            .fc-daygrid-event-harness {
                // flex: 1;
                z-index: 10;
                // position: unset;
                .fc-daygrid-event {
                    position: unset;
                    // min-height: 114px;
                    margin: 4px 2px !important;
                    padding: 8px;
                    @media screen and (max-width: 991px) {
                        background-color: #fff !important;
                        box-shadow: 0 3px 8px #3050731a;
                        margin: 0 0 24px 0 !important;
                        padding: 24px;
                    }
                    .fc-event-main {
                        position: unset;
                    }
                }
            }
        }
    }
}
.calendar .customSelectInput__menu {
    z-index: 10 !important;
}

.edit-row {
    height: 6.3em !important;
}
.edit-event {
    min-height: 6.3em;
}
.trainer-filter {
    position: absolute !important;
    width: 100%;
    // transform: translateY(77px);
    z-index: 10;
    .customSelectInput__control {
        border: 2px solid $border-rest-color !important;
        .customSelectInput__placeholder {
            color: $secondary-color !important;
            font-size: 14px;
            font-weight: 600;
        }
        .customSelectInput__indicator {
            svg {
                stroke-width: 3px;
            }
        }
    }
}
.all-day {
    font-size: 14px;
    font-weight: 600;
    color: #a6b7ca;
}

.change-date {
    display: flex;
    align-items: center;
    gap: 45px;
    .date-text {
        font-size: 20px;
        color: #305073;
        font-weight: 600;
    }
    button {
        width: 45px;
        aspect-ratio: 1;
        background: transparent;
        border: none;
        outline: none;
        &::before {
            content: "";
            border: solid $secondary-color;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 4px;
            border-radius: 0px 0px 2px 0px;
        }
        &:first-child {
            &::before {
                margin-right: -5px;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }
        }
        &:last-child {
            &::before {
                margin-left: -5px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
        }
    }
}
.dates {
    overflow-x: scroll;
    ul {
        display: flex;
        align-items: center;
        list-style: none;
        gap: 10px;
        padding: 0 10px;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0 20px;
            white-space: nowrap;
            color: #305073;
            background: transparent;
            width: 48px;
            aspect-ratio: 1;
            border-radius: 6px;
            font-size: 14px;

            .day-number {
                font-weight: 600;
                margin-top: 2px;
            }
            &.activeDate {
                background: #305073;
                color: #fff;
            }
        }
    }
}
