.top2Accounts {
    display: flex;
    justify-content: space-between;
    padding: 35px 0px 45px;
}

.account {
    display: flex;
    min-width: 50%;
}

.accountRow {
    color: #3699ff;
    // font-size: 120px;
    // font-weight: bold;
    line-height: 0.75;
}
@media (max-width: 425px) {
    .account {
        margin-left: -25px;
    }
}

.accountInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.accountBoxName {
    font-size: 1.275rem;
    line-height: 22px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    width: 100px;
}

.accountBoxes {
    display: flex;
}

.accountBox {
    background-color: #e1e9ff;
    text-align: center;
    @include fluid-FontSize($width_xl, $width_xxl, 15px, 18px);
    font-weight: bold;
    color: #464e5f;
    border-radius: 5px;
    margin-right: 5px;
    padding: 5px 10px;
}

.accountBoxCount {
    font-weight: 600;
}

.accountBoxMuted {
    font-size: 8px;
    @include fluid-FontSize($width_xl, $width_xxl, 7px, 8px);
    font-weight: 300;
    color: #838383;
    margin-top: -3px;
}

#topSalesByAccounts {
    margin-top: 60px;
}

#topSalesByAccounts thead tr {
    background-color: #e1e9ff;
    color: #838383;
}

#topSalesByAccounts th {
    font-weight: bold;
}

#topSalesByAccounts td {
    color: #464e5f;
    border: 0;
}

#topSalesByAccounts td:first-child {
    color: #3699ff;
    font-weight: bold;
}

#topSalesByAccounts td:nth-child(3),
#topSalesByAccounts td:nth-child(4),
#topSalesByAccounts th:nth-child(3),
#topSalesByAccounts th:nth-child(4) {
    font-weight: bold;
    text-align: center;
}

#topSalesByAccounts tbody tr:nth-of-type(odd) {
    background-color: #dedede;
}

.accountBoxes .accountBox {
    margin-bottom: 3px;
}

.cardIcon {
    display: flex;
    width: 50px;
    height: 50px;
}

.cardIconAndTitle {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 575px) {
    .account {
        width: 50% !important;
        .accountBoxes {
            display: block;
        }
    }
}
