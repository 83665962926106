.tooltip {
    // .arrow::before{
    //   border-bottom-left-radius: 100%;
    //   border-bottom-right-radius: 10%;
    // border-radius: 0% 10% 66% 69% / 0% 0% 100% 100%;
    // }

    // &.left{
    //   .arrow::before{
    //     left:  !important;
    //   }
    // }
    // &.right{
    //   .arrow::before{
    //     position: absolute;
    //     right: 10% !important;
    //     border-radius: 3px;
    //     transform: translate(-10%, -50%);
    //   }
    // }

    > div.tooltip-inner {
        box-shadow: 0px 3px 8px transparentize($secondary-color, 0.9) !important;
        background-color: $secondary-color;
        color: $card-background-color;
        border-radius: 12px;
        text-align: left;
        max-width: 100%;
        padding: 0.375rem 0.75rem !important;

        > strong {
            font-size: 0.75rem !important;
            font-weight: normal !important;
            letter-spacing: 0px;
        }
    }
    &.bs-tooltip-top {
        .arrow {
            &::before {
                top: -1px !important;
                border-top-color: $secondary-color;
                border-bottom-left-radius: 100%;
                border-bottom-right-radius: 10%;
            }
        }
    }

    &.bs-tooltip-right {
        left: 2px;
        .arrow::before {
            border-right-color: $secondary-color;
        }
    }

    &.bs-tooltip-bottom {
        top: 2px !important;
        .arrow::before {
            border-bottom-color: $secondary-color;
            border-top-left-radius: 100%;
            border-top-right-radius: 10%;
        }
    }

    &.bs-tooltip-left {
        left: -1px;
        .arrow::before {
            border-left-color: $secondary-color;
        }
    }

    .delegateInfo_tooltip {
        white-space: nowrap;
    }
    .show {
        opacity: 1 !important;
        border-radius: 12px;
        @include tooltip-theme($card-background-color, $secondary-color);
    }
}
