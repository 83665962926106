@import "../../../../../src/app/popleads/assets/css/design_2.0/variables";

.content {
    @extend .customScroll;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem 0.75rem 1.5rem;
    border-bottom: 1px solid $secondary-color;
}

.body {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0.5rem;
    padding: 0.75rem 0.75rem 0.75rem 1.5rem;
}

.footer {
    padding: 0.75rem 1.5rem 1.5rem 1.5rem;
}

.closeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $border-rest-color;
    color: $secondary-color;
    width: 32px;
    height: 32px;
    outline: 0;
    background: transparent;
    overflow: hidden;
    padding: 0;
    border-radius: 8px;
    svg {
        transform: rotate(45deg);
    }
}

.customScroll {
    ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }
    ::-webkit-scrollbar-track {
        background-color: inherit;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #e0e0e0;
        border: 3px solid transparent;
        border-radius: 6px;
        background-clip: content-box;
        min-height: 32px;
        &:hover {
            background-color: #c0c0c0;
            border-width: 2px;
        }
    }
}
