import .precampaignsGrid {
    display: grid;
    grid-template-columns: 24.5% 24.5% 24.5% 24.5%;
    grid-gap: 10px;
}

.precampaignsGridItem {
    //height:390px;
    border-radius: 10px;
    background: white;
    padding: 5px;
    cursor: pointer;
}

.precampaignsGridItem_Header {
    height: 100px;
    width: 100%;
    // background: #dadada;
    border-radius: 5px 5px 0 0;
    padding: 20px;
}

.precampaignsGridItem.selected,
.precampaignsGridItem.selected {
    background: #56ccb41a;
    box-shadow: 0px 3px 8px #3050731a;
}

.precampaignsGridItem_Title {
    font-size: 1rem;
    color: #305073;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
}
@media (max-width: 1919px) {
    .precampaignsGridItem_Title {
        font-size: 0.875rem;
    }
}
.isHighlightUser {
    background-color: #ffdd99;
}
.precampaignsGridItem_Info {
    display: flex;
    flex-direction: column;
    padding: 1px 0 10px 20px;
}

.precampaignsGridItem_InfoRow {
    display: flex;
    margin-bottom: 8px;
}

.precampaignsGridItem_InfoRow .icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    //justify-content: center;
}

.precampaignsGridItem_Status {
    width: 16px;
    height: 16px;
    background-color: #ff4c4c;
    border-radius: 360px;
}

.precampaignsGridItem_StatusActive {
    background-color: #0bb783;
}

.precampaignsGridItem_StatusClosed {
    background-color: #aaaaaa;
}

.precampaignsGridItem_StatusPostponned {
    background-color: #e6ff06;
}

.precampaignsGridItem_StatusPlanning {
    background-color: #2600ff;
}

.precampaignsGridItem_Label {
    color: #a6b7ca;
    font-size: 0.75rem;
    line-height: 18px;
    // margin-top: 4px;
}

.precampaignsGridItem_Value {
    color: #464e5f;
    font-size: 12px;
    line-height: 15px;
}

.precampaignsGridItem_Value.valueCampaignDate {
    font-size: 14px;
    font-weight: 600;
}

// .cpreampaignsGridItem_Value.valueType {
//     font-size: 12px;
//     font-weight: 600;
// }

.precampaignsGridItem_Buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 20px 10px 20px;
}

.precampaignsGridItem_Buttons button {
    // background: #eff2ff;
    font-size: 14px;
    color: #464e5f;
    border-radius: 5px;
}

.precampaignsTableItem_Buttons {
    display: flex;
    flex-direction: row;
    button {
        // border: 0;
        background: #eff2ff;
        font-size: 14px;
        color: #464e5f;
        border-radius: 5px;
    }
}
.precampaignTableButton {
    color: #464e5f;
    background: #eff2ff;
    border: 0;
    padding: 7.5px 20px;
    border-radius: 5px;
    z-index: 9;
}

.preCampaignsTable {
    font-size: 12px !important;
}

.preCampaignsTable th.status,
.preCampaignsTable th.type,
.preCampaignsTable th.basTarih,
.preCampaignsTable th.bitTarih {
    text-align: center;
}

.preCampaignsTable td.type div,
.preCampaignsTable td.basTarih div,
.preCampaignsTable td.bitTarih div {
    justify-content: center;
}

.preCampaignsTable th.fullPrice {
    text-align: right;
}

.preCampaignsTable .campaignName {
    max-width: 300px;
}

.preCampaignsTable .status {
    min-width: 40px !important;
    width: 40px;
}

.preCampaignsTable .status div {
    justify-content: center;
}

.preCampaignsTable .fullPrice {
    min-width: 60px !important;
    width: 60px;
}

.preCampaignsTable td.fullPrice {
    text-align: right;
}

.preCampaignsTable td.fullPrice div {
    justify-content: end;
}

.preCampaignsTable_campaignName {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.preCampaignsTable .button {
    min-width: 120px !important;
    width: 120px;
}

.addPreCampaignWizard {
    padding: 50px 100px;
}

.addPreCampaignWizard .wizardButtons {
    display: flex;
    justify-content: space-between;
}

.addPreCampaignWizard .wizardButtons .wizardButton {
    width: 210px;
    border-bottom: 3px solid #ecf0f3;
    cursor: pointer;
}

.addPreCampaignWizard .wizardButtons .wizardButton.selected {
    border-bottom: 3px solid #3699ff;
}

.addPreCampaignWizard .wizardButtons .wizardButton .wizardNo {
    font-size: 25px;
    font-weight: 600;
    color: #b5b5c3;
    margin-right: 10px;
}

.addPreCampaignWizard .wizardButtons .wizardButton.selected .wizardNo {
    color: #3699ff;
}

.addPreCampaignWizard .wizardButtons .wizardButton .wizardLabel {
    font-size: 18px;
    font-weight: 600;
    color: #b5b5c3;
}

.addPreCampaignWizard .wizardButtons .wizardButton.selected .wizardLabel {
    color: #3699ff;
}

.addPreCampaignWizard .wizardContent {
    padding: 100px 0px;
}

.addPreCampaignWizard .wizardContent .wizardContentPart {
    display: flex;
    justify-content: center;
}

.addPreCampaignWizard .wizardContent .wizardContentPart_Icon {
    margin-right: 70px;
    margin-top: 16px;
}

.addPreCampaignWizard .wizardContent .wizardContentPart .wizardInputRow {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    width: 460px;
}

.addPreCampaignWizard .wizardContent .wizardContentPart .wizardInputRow .wizardInput {
    display: flex;
    flex-direction: column;
}

.addPreCampaignWizard .wizardContent .wizardContentPart .wizardInputRow .wizardInput label {
    color: #a1a1a1;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
}

.textInputNew {
    color: #464e5f;
    height: 46px;
    font-size: 16px;
    font-weight: 600;
    background-color: #f3f6f9 !important;
    color: #464e5f;
    border: 0;
    border-radius: 5px;
    width: 160px;
    padding-left: 10px;
    padding-right: 10px;
}

.textInputNew.inputCalendar {
    width: 240px;
}

.textInputNew.inputEdit {
    width: 225px;
}

.addPrecampaignInput__control {
    height: 46px;
    font-size: 16px;
    font-weight: 600;
    background-color: #f3f6f9 !important;
    color: #464e5f;
    border: 0;
    border-radius: 5px;
}
.contactText {
    font-size: 1.125rem !important;
    font-weight: 600;
    line-height: 1.5;
    color: $secondary-color;
}
.pm-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
}
.addPrecampaignInput_template .addPrecampaignInput__control {
    width: 460px;
}

.addPrecampaignInput_type .addPrecampaignInput__control,
.addPrecampaignInput_status .addPrecampaignInput__control {
    width: 170px;
}

.addPrecampaignInput_pm .addPrecampaignInput__control {
    width: 200px;
}

.addPrecampaignInput_city .addPrecampaignInput__control {
    width: 290px;
}

.addPrecampaignInput_preliminaryCalendar .addPrecampaignInput__control {
    width: 225px;
}

.wizardInput i.flaticon2-calendar-9,
.preCampaignsMoreInfoInput i.flaticon2-calendar-9 {
    color: #464e5f;
    margin-left: -30px;
    z-index: 2;
    font-size: 24px;
    line-height: 48px;
}

.wizardNavButtons {
    // width: 900px;
    margin: auto;
    text-align: right;
}

.wizardNavButtons.errorMessage {
    margin-bottom: 10px;
    margin-top: 10px;
    color: darkred;
}

.wizardNavButtons button {
    // border: 0;
    padding: 12px 22px;
    border-radius: 4px;
    color: #464e5f;
    font-size: 13px;
    font-weight: 600;
    // background: #eff2ff;
    margin-left: 50px;
}

.wizardNavButtons button.finish {
    // background: #d3e5e5;
}

.preCampaignsMoreInfoPart {
    display: flex;
    border-bottom: 2px solid #ecf0f3;
    margin-bottom: 40px;
    padding-bottom: 20px;
    width: 100%;
    justify-content: center;
}

.preCampaignsMoreInfoInput {
    display: flex;
}

.preCampaignsMoreInfoInput label {
    width: 160px;
    font-weight: 600;
    color: #a1a1a1;
    font-size: 16px;
    line-height: 46px;
    margin-right: 20px;
}

.preCampaignsMoreInfoInput label.twoLine {
    line-height: 23px !important;
}

.preCampaignsMoreInfoInput .editValue {
    font-size: 16px;
    font-weight: 600;
    color: #464e5f;
    line-height: 46px;
}

.preCampaignsMoreInfoPart .preCampaignsMoreInfoPart_Icon {
    margin-right: 70px;
}

.preCampaignsMoreInfo {
    margin: 100px 0;
}

.preCampaignsMoreInfoPart .wizardInputRow {
    margin-bottom: 20px;
    width: 640px;
}

.preCampaignsTable .MuiTableCell-root {
    padding: 5px;
    border: 0;
    color: #b5b5c3;
    font-size: 18px;
    //margin-bottom:-30px;
    //margin-top:10px;
}

.precampaignsGridContainer .MuiTableCell-root {
    padding: 5px;
    border: 0;
    color: #b5b5c3;
    font-size: 18px;
}

.preCampaignsTable .MuiTableCell-root .MuiToolbar-root,
.precampaignsGridContainer .MuiTableCell-root .MuiToolbar-root {
    min-height: 0;
}

.precampaignsGridContainer .infoLabel,
.preCampaignsTable .infoLabel {
    color: darkred;
    font-style: italic;
}

.trainerFilterButtons.buttonMobile {
    display: none;
}

.trainerFilterButtons.buttonDesktop {
    display: block;
}

@media (max-width: 700px) {
    .precampaignsGridItem_Title {
        font-size: 14px;
    }

    .wizardLabel {
        display: none;
    }

    .wizardNo {
        margin: 0;
        text-align: center;
        display: block;
        width: 100%;
    }

    .precampaignsGridItem_Header {
        // background: white;
        height: auto;
        // border-bottom: 1px solid #d9dbdc;
        padding: 20px 0;
    }

    .precampaignsGridItem {
        padding: 40px;
    }

    .precampaignsGridItem_Info {
        margin-left: 0;
    }

    .precampaignsGridItem_Buttons {
        margin: 10px 0;
    }

    .infoRowCloseMobile {
        display: none;
    }

    .addPreCampaignWizard {
        margin: 10px;
        padding: 10px;
    }

    .addPrecampaignInput__control,
    .wizardInputRow {
        max-width: 250px;
    }

    .wizardInputRow {
        flex-direction: column;
        margin-bottom: 0 !important;
    }

    .wizardNavButtons {
        width: auto;
        text-align: center;
        display: flex;
        justify-content: space-between;
    }

    .wizardNavButtons button {
        margin: 0;
    }

    .wizardContentPart {
        display: block !important;
    }

    .wizardInput {
        margin-bottom: 40px;
    }

    .wizardContent {
        padding: 15px !important;
    }

    .addPreCampaignWizard .wizardContent .wizardContentPart .wizardContentPart_Icon {
        margin-bottom: 20px;
    }

    .wizardButton {
        margin: 10px;
    }

    .preCampaignsMoreInfoPart .preCampaignsMoreInfoPart_Icon {
        display: none;
    }

    .preCampaignsMoreInfoInput {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .preCampaignsMoreInfoInput label {
        width: auto;
        line-height: 16px;
    }

    .preCampaignsMoreInfoInput .editValue {
        line-height: 20px;
    }

    .preCampaignsMoreInfo {
        margin: 30px auto;
    }

    .precampaignsGrid {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 10px;
    }

    .trainerFilterButtons.buttonMobile {
        display: block;
    }

    .trainerFilterButtons.buttonDesktop {
        display: none;
    }

    .gridOrTableFilter_precampaign {
        display: none;
    }
}

@media (min-width: 701px) and (max-width: 1350px) {
    .wizardNavButtons {
        width: auto;
        text-align: right;
    }
    .precampaignsGrid {
        display: grid;
        grid-template-columns: 49% 49%;
        grid-gap: 10px;
    }
}

@media (min-width: 1351px) and (max-width: 1600px) {
    .preCampaignsMoreInfoPart {
        display: flex;
        border-bottom: 2px solid #ecf0f3;
        margin-bottom: 20px;
        padding-bottom: 20px;
        width: 100%;
        justify-content: flex-start;
        margin-left: 100px;
    }

    .preCampaignsMoreInfo {
        width: calc(100% - 100px);
    }

    .precampaignsGrid {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-gap: 10px;
    }
}

@media (min-width: 701px) and (max-width: 1100px) {
    .addPrecampaignInput__control {
        max-width: 275px !important;
    }

    .preCampaignsMoreInfoPart .preCampaignsMoreInfoPart_Icon {
        display: none !important;
    }

    .gridOrTableFilter_precampaign {
        display: none;
    }

    .trainerFilterButtons.buttonMobile {
        display: block;
    }

    .trainerFilterButtons.buttonDesktop {
        display: none;
    }
}

#preCampaignsTable .basTarih,
#preCampaignsTable .bitTarih {
    min-width: 110px !important;
    width: 110px !important;
}

#preCampaignsTable .trainer,
#preCampaignsTable .projectManager,
#preCampaignsTable .idpPerson {
    min-width: 100px !important;
    width: 100px !important;
}

#preCampaignsTable .country,
#preCampaignsTable .city,
#preCampaignsTable .type {
    min-width: 85px !important;
    width: 85px !important;
}

#preCampaignsTable .status {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

// #preCampaignsTable tr{
//     border-bottom: 2px solid #ECF0F3;
// }

.cancelButton {
    background-color: transparent;
    border: none;
}

.published-success {
    position: relative;
    &:after {
        content: "";
        position: absolute;

        transform: translate(-50%, -50%);
        top: 50%;
        right: 0%;

        width: 20px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 24 24' fill='none' stroke='%2356ccb4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    }
}

.deleteImage {
    position: relative;

    .surveyHomeDeleteButton {
        position: absolute;
        right: 0;
        bottom: -10px;
    }
}

.make-default-text {
    :hover {
        color: #305073;
    }
}

.default-image-text {
    :hover {
        color: #6490bf;
    }
}

.inputFile {
    width: 100%;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #49698c1a;
    border-radius: 12px;
    border: 2px solid #c0cbd8;
    cursor: pointer;

    form {
        width: 603px;
        height: 172px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: 893px) {
            width: 65vw;
        }
    }
    form input {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
    }

    &-browse-file {
        text-decoration: underline;
        color: #6490bf;
        line-height: 18px;
        letter-spacing: 0px;
        margin-top: 4px;
    }
}

.addCourseInput {
    width: 100% !important;
}

.topicText {
    width: 25vw !important;
}

.topicTitle {
    width: 20vw !important;
}

.whoShouldAttend {
    width: 50vw;
    height: 336px;
}

.customEditButton {
    width: 45px;
    height: 45px;
    display: flex;
    padding-top: 6px;
    justify-content: flex-end;

    &_related {
        @extend .customEditButton;
        aspect-ratio: 1;
        justify-content: center;
        align-items: center;
        padding: 0;
        background: #fcfcfc 0% 0% no-repeat padding-box;
        border: 2px solid #c0cbd8;
        border-radius: 12px;
        margin-right: 13px;
    }

    svg {
        width: 18px;
        height: 18px;
    }
}

.ck-editor {
    width: 100% !important;
    .ck.ck-dropdown .ck-dropdown__arrow {
        z-index: 0;
    }
    &__editable {
        min-height: 180px !important;
        background: transparent !important;
    }
}

.academyLineClamp {
    @media (min-width: 1200px) {
        height: 100px;
        font-size: 0.875rem;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }
}

.required-field {
    .input-label:after {
        color: $error-color;
        content: "*";
        position: relative;
        margin-left: 7px;
        margin-top: 3px;
    }
}

.addPreCampaignWizard,
.courseDetailPage {
    ::-webkit-input-placeholder {
        color: $secondary-color;
    }

    ::-moz-placeholder {
        color: $secondary-color;
    }

    :-ms-input-placeholder {
        color: $secondary-color;
    }

    ::placeholder {
        color: $secondary-color;
    }
}
