// div[data-column-id="settingsTemplate"] {
//     max-width: 175px !important;
// }
div[data-column-id="sendingDate"] {
    max-width: 120px !important;
}
.sending-date svg {
    height: 20px;
    cursor: pointer;
}
.sending-date span {
    text-decoration: underline;
}

#row-1 {
    position: relative;
}
.sending-date-dropdown {
    position: absolute;
    z-index: 2;
    width: 350px;
}
// div[data-column-id="courseName"] {
//     max-width: 175px !important;
// }
// div[data-column-id="startEndDate"] {
//     max-width: 175px !important;
// }

.setting-disabled {
    color: $list-group-disabled-color;
}

.email-template-modal {
    width: 800px;
    max-width: none !important;
    iframe {
        width: 100% !important;
        min-width: 100px !important;
        height: 600px !important;
        margin-bottom: 24px;
    }
}
.tox-toolbar-overlord {
    display: none !important;
    margin-left: 200px !important;
}
.tox-editor-container > div:first-child {
    display: none !important;
}

.without-bg {
    background: none;
}

@media only screen and (max-width: 450px) {
    .email-template-modal {
        width: 96vw;
    }
}
