.courseHomeSearch {
    width: 100%;
    max-width: 650px;

    .courseHomeSearchInput {
        width: 100%;
    }
    .customSelectInput__placeholder,
    .customSelectInput__single-value,
    .selectInputNewFilter__placeholder,
    .selectInputNewFilter__single-value {
        font-size: 1rem !important;
        font-weight: 600 !important;
        color: #305073 !important;
    }

    .selectInputNewFilter__control {
        margin: 0 !important;
        padding: 0 1.5rem !important;
    }

    .css-yk16xz-control {
        border: 1px solid #c0cbd8 !important;
        box-shadow: 0px 3px 8px #49698c1a !important;
        background-color: transparent !important;
        min-width: 160px;
    }
}

.coursesRadioButtons {
    @extend .btn;
    border: 1.5px solid $border-rest-color !important;
    box-shadow: 0px 3px 8px #3050731a;
    transition: none !important;
    // width: 44px;
    padding: 0 !important;
    background-color: #fcfcfc;
    display: flex;
    align-items: center;
    justify-content: center;

    &.left {
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    &.middle {
        border-radius: 0 !important;
        margin-left: 0 !important;
    }

    &.right {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 12px !important;
        border-top-right-radius: 12px !important;
        margin-left: 0 !important;
    }

    &.active {
        border: 1.5px solid $secondary-color !important;
        z-index: 0 !important;
    }

    &.left-border {
        border-left: 1.5px solid $secondary-color !important;
    }

    span {
        font-size: 0.75rem;
        font-weight: 400;
        color: $secondary-color;
    }
}

.course {
    &-title {
        @extend .subheading;
        font-weight: 600 !important;
        color: $secondary-color;
        height: fit-content;
        line-height: 1.3;
        @media (max-width: 575px) {
            -webkit-line-clamp: unset !important;
        }
    }
    &-image {
        flex-shrink: 0;
        text-align: center;
        font-weight: 600;
        background-color: $disable-color;
        border-radius: $card-border-radius;
        color: $secondary-color;
        height: 2em;
        width: 2em;
        line-height: 2em;
        &--large {
            font-size: 3rem;
        }
        &--small {
            font-size: 2.25rem;
        }
    }
    &-trainer {
        font-weight: 600;
        @media (max-width: 767px) {
            font-weight: 400;
        }
    }
    &-videoBox {
        border-radius: 6px;
        overflow: hidden;
        width: 100%;
        aspect-ratio: 16 / 9;
    }
}

.courseDetailPage {
    .nav {
        &.nav-pills {
            .nav-link {
                border-radius: 12px;
                padding: 8px 16px;
                color: $secondary-color !important;
                text-decoration: none;
                font-weight: 600 !important;
                &.active {
                    color: $secondary-color !important;
                    background-color: transparentize($success-color, 0.85);
                }
                &:hover {
                    color: $secondary-color !important;
                }
            }
        }
    }

    .card.card2.grid {
        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
        }
    }

    .course-badge {
        display: flex;
        align-items: center;
        color: #fcfcfc;
        padding: 6px 12px;
        border-radius: 12px;
        font-size: 0.875rem;
        font-weight: 600;
        &.drafted {
            background-color: $error-color;
        }
        &.published {
            background-color: $success-color;
        }
        &.preparation {
            background-color: $tertiary-color;
        }
    }

    .outline-topics {
        border-top: 1.5pt solid rgba(65, 72, 79, 0.35);
        @media (min-width: 768px) {
            border-top: none;
            border-left: 1.5px solid rgba(65, 72, 79, 0.35);
        }
    }

    .mobile-small-text {
        @media (max-width: $mobile-breakpoint) {
            font-size: 0.875rem !important;
        }
    }

    .mobile-xsm-text {
        @media (max-width: $mobile-breakpoint) {
            font-size: 0.75rem !important;
        }
    }

    .pmFallbackSvg {
        .cls-1 {
            stroke: $secondary-color;
        }
    }
}

.courseQuickActionButtons {
    &-desktop {
        white-space:nowrap;
        display: block;
        @media (max-width: 450px) {
            display: none;
        }
    }
    &-mobile {
        display: none;
        @media (max-width: 450px) {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
}

.course-credit {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 15px;
}
.refund-border {
    border: 2px solid;
    padding: 4px 12px;
    border-radius: 12px;
    color: #8a9eb4;
}

.top-course-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border-rest-color;
    border-radius: 12px;
    padding: 6px 12px;
    span {
        font-size: 14px;
        color: #8a9eb4;
    }
}

.pm-image {
    img {
        border-radius: 8px;
    }
}
.pm-name,
.pm-email {
    margin-bottom: 0;
}

.pm-right {
    padding-left: 10px;
}
.pm-name {
    font-size: 16px;
}
.pm-email {
    font-size: 14px;
    color: var(--color-disable);
}

.status .row {
    width: 5%;
    margin-left: auto;
    margin-right: auto;
}
.businesssector,
.academy {
    p {
        margin-bottom: 0 !important;
    }
}

.statusColumn {
    border: 2px solid;
    padding: 4px 12px;
    border-radius: 12px;
}
// .status {
//    > div >div {
//         border: 2px solid;
//         padding: 4px 12px;
// border-radius: 12px;
//     }

// }

.tableScroll:has(> #coursesTable){
    overflow-x: auto !important;
}

#coursesTable th:nth-child(1),
#coursesTable td:nth-child(1) {
    min-width: auto;
}

#coursesTable th:nth-child(2),
#coursesTable td:nth-child(2) {
    min-width: auto;
}
#coursesTable th:nth-child(3),
#coursesTable td:nth-child(3) {
    min-width: 70px;
    padding-right: 0 !important;
}
#coursesTable th:nth-child(4),
#coursesTable td:nth-child(4) {
    padding-left: 0 !important;
    min-width: 300px;
}
#coursesTable {
    td {
        padding: 0.75rem 0.75rem;
        vertical-align: top;
    }

    margin-left: 0 !important;
    .topCourse {
        padding: 1rem 0 !important;
        vertical-align: top;
    }
}

.courseNameCol {
    display: flex;
    flex-direction: column;
}
.totalRevenue {
    text-align: end !important;
}
.courseImg img {
    border-radius: 12px;
}
.courseImg div {
    width: 45px;
    height: 45px;
    font-size: 22px;
}
.courseNameCol > .row {
    margin-left: auto;
    margin-right: auto;
}
.card > .courseHomeSearch {
    margin: 0 16px 0 auto !important;
}
.courseName{
    min-width: 175px !important;
}
.delete {
    padding-top: 2px !important;
    min-width: 50px !important;
}

.customTextarea {
    background: transparent;
    box-shadow: 0px 3px 8px #49698c1a;
    border: 2px solid #c0cbd8;
    border-radius: 12px;
    width: 28vw;
    height: 193px;
    margin-right: 16px;
    min-height: 193px;
    padding-inline: 32px;
    padding-top: 10px;
    color: #305073;

    &:focus {
        color: #305073;
        border: 2px solid #c0cbd8;
        box-shadow: 0px 3px 8px #49698c1a;
        outline: none;
    }

    &:active {
        color: #305073;
        border: 2px solid #c0cbd8;
        box-shadow: 0px 3px 8px #49698c1a;
        outline: none;
    }
}
