// $checkBoxCardBorder: none;

// .checkBoxCard {
//     @extend .card;
//     @extend .card2;
//     border: $checkBoxCardBorder;

//     & > input[type="radio"]:checked {
//         $checkBoxCardBorder: 2px solid $primary-color;
//     }
// }
.sc-gKPRtg {
    input {
        height: 22px;
    }
}

.deal-search-filter {
    margin-top: -14px;
}
.check {
    border-color: $primary-color;
}
.checkBoxCard {
    border: 2px solid transparent;
    // cursor: pointer;
    &.check {
        border-color: $primary-color;
    }
}

.deal-table {
    .mobileExpandedBox {
        &.open {
            background-color: #f5f7fa;
        }
        &_More {
            padding: 0 24px;
        }
    }
    .column-box,
    .customize-hr {
        padding: 0 24px;
    }
    .quick-action-buttons {
        background-color: transparent;
        border: none;
        outline: 0;
        padding: 0;
        &.edit-icon {
            color: $success-color;
        }
    }
    .custom-img {
        width: 45px;
        aspect-ratio: 1/1;
        border-radius: 8px;
        max-width: 100%;
    }
    .item-desc {
        color: #8a9eb4;
        letter-spacing: 0;
    }
    .total-amount {
        font-weight: 600;
        color: $success-color !important;
        letter-spacing: 0;
        text-align: right;
        color: #56ccb4;
    }
    .paid-amount {
        letter-spacing: 0;
        text-align: right;
        color: #899fb7;
    }
    .active-time {
        border: 2px solid $warning-color;
        color: $warning-color;
        border-radius: 12px;
        font-weight: 600;
        padding: 6px 12px;
    }
    .paid-btn {
        border: 2px solid $success-color;
        color: $success-color;
        border-radius: 12px;
        font-weight: 600;
        padding: 6px 12px;
        cursor: pointer;
    }
    .table-dlt-btn {
        color: #b93d48;
        cursor: pointer;
    }

    .link {
        color: $primary-color;
        text-decoration: underline;
    }
    .rdt_Table {
        background-color: transparent;
        .rdt_TableHead {
            background-color: transparent;
            font-weight: 600;
            color: $secondary-color;
            .rdt_TableHeadRow {
                background-color: transparent;
                border: none;
            }
        }
        .rdt_TableBody {
            .rdt_TableRow {
                background-color: transparent;
                min-height: auto;
                @extend .table-heading;
                color: $secondary-color;
                letter-spacing: 0;
                padding-top: 12px;
                padding-bottom: 12px;

                &:not(:last-of-type) {
                    border-bottom: 2px solid rgba($disable-color, 0.3);
                }
                .rdt_TableCell {
                    align-items: flex-start;
                    input {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #ffffff;
                        border: 1px solid #6490bf !important;
                        border-radius: 6px;
                        &:checked {
                            background-color: #6490bf !important;
                        }
                    }
                }
                // .cpdasa {
                //     min-width: 46px;
                //     flex: 0 0 46px;
                //     align-items: flex-start;
                //     button {
                //         color: inherit;
                //         fill: inherit;
                //         border-radius: unset;
                //         height: auto;
                //         width: auto;
                //         &:hover:not(:disabled),
                //         &:focus {
                //             background-color: transparent;
                //         }
                //     }
                // }
            }
            .rdt_ExpanderRow {
                background-color: #f5f7fa;
                padding: 24px 48px;
                border-bottom: 2px solid rgba($disable-color, 0.3);
                color: $secondary-color;
                letter-spacing: 0px;

                .expand-box {
                    > div {
                        &:first-child {
                            margin-left: -12.5px;
                        }
                        &:last-child {
                            margin-right: -12.5px;
                        }
                    }
                    &:not(:last-of-type) {
                        border-bottom: 2px solid rgba($disable-color, 0.35);
                        padding-bottom: 16px;
                    }
                    margin-bottom: 16px;

                    .box-wrapper {
                        display: flex;
                        gap: 32px;
                    }
                }

                tbody {
                    tr {
                        td:nth-of-type(1) {
                            width: 30%;
                            padding-right: 12px;
                        }
                        td:nth-of-type(2) {
                            width: 20%;
                            padding-right: 12px;
                        }
                        td:nth-of-type(3) {
                            width: 30%;
                            padding-right: 12px;
                        }
                        td:nth-of-type(4) {
                            width: 20%;
                        }
                    }
                }
            }
        }
    }
}

.tg-wrap {
    .tg {
        width: 100%;
    }
    tbody {
        tr {
            &:not(:last-of-type) {
                border-bottom: 2px solid rgba($disable-color, 0.35);
            }
            &:first-child {
                td {
                    padding: 0 0 16px 0;
                }
            }
            &:last-child {
                td {
                    padding: 16px 0 0 0;
                }
            }
            td {
                padding: 16px 0;
                vertical-align: top;
                &:not(:last-of-type) {
                    padding-right: 5px;
                }
                .expand-boxes-header {
                    color: #8a9eb4;
                    font-size: 12px;
                    margin-bottom: 4px;
                }
                .col-group {
                    display: inline-block;

                    &:not(:last-of-type) {
                        margin-right: 32px;
                    }
                }
            }
        }
    }
}

.total-amount-mobile {
    color: #56ccb4;
}

.paid-amount-mobile {
    color: #8a9eb4;
}
.closed {
    color: $warning-color;
}
