.progress-container {
    height: 8px;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 0 4px 4px 0;
    margin-top: 2px;
    .progress-fill {
        height: 100%;
        border-radius: inherit;
    }
}
