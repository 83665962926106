.pdf-icon {
    cursor: pointer;
    margin-right: 1rem;
    width: 2.5rem;
}

.pdfLogo{
    padding-left:20px;
}

.pdfLogo img{
    width: 142px;
    height: 100px;
}

.pdf-header-wrapper {
    //justify-content: center; 
    align-items: center; 
    padding: 0.5rem; 
    background-color: white; 
    margin-bottom: 0.5rem
}

.pdfFilters{
    padding-right:20px;
}

.pdfFilters h4{
    text-align: right;
}

.pdfFilters h4:last-child{
    margin:0;
}

@media (max-width: 576px){
    .pdfFilters{
        padding-right:10px;
    }

    .pdfFilters h4{
        font-size:12px;
    }

    .pdfLogo{
        padding-left:10px;
    }

    .pdfLogo img{
        width: 100px;
        height: auto
    }
}