.slick-slider.cardSlider {
    .slick-arrow {
        display: none !important;
    }
    .slick-dots {
        bottom: -8px;
        li {
            width: 8pt;
            height: 8pt;
            margin: 0 3px;
            &.slick-active {
                button {
                    &::before {
                        color: $primary-color;
                    }
                }
            }
            button {
                width: 8pt;
                height: 8pt;
                &::before {
                    width: 8pt;
                    height: 8pt;
                    color: $border-rest-color;
                    opacity: 1;
                    line-height: 8pt;
                    font-size: 8pt;
                }
            }
        }
    }
}
