.areaGraphLegend {
    display: flex;
}

.areaGraphLegend_item {
    display: flex;
    align-items: center;
    margin-right: 80px;
}

.areaGraphLegend_itemColor {
    width: 87px;
    height: 14px;
    margin-right: 15px;
}

.areaGraphLegend_itemTag {
    line-height: 28px;
}

.legendYellow {
    background: var(--color-warning);
}

.legendBlue {
    background: var(--color-primary);
}

.legendGreen {
    background: var(--color-success);
}

#closedDeals text#SvgjsText1039 {
    font-size: 22px !important;
    font-weight: 600 !important;
}

@media (max-width: 768px) {
    .areaGraphLegend_item {
        margin-right: 12px;
    }
}

@media (max-width: $mobile-breakpoint) {
    .areaGraphLegend_itemTag {
        font-size: 0.625rem !important;
    }
}

.card-toolbar_viewAll {
    align-items: center;
}

table.tableIdInquiries {
    text-align: right;
}

table.tableIdInquiries th:first-child,
table.tableIdInquiries td:first-child {
    text-align: left;
}

.coloredCard {
    margin: auto;
}

.heading-button {
    font-weight: 600;
    font-size: 22px;
}
.button-number {
    font-size: 48px;
    font-weight: bolder;
    color: $secondary-color;
}

.coloredCard {
    margin: auto;
}

.number-text {
    font-size: 48px;
    font-weight: 600;
    line-height: 52px;
}

@media (max-width: 1919px) {
    .heading-button {
        font-size: 18px;
    }
    .button-number {
        font-size: 32px;
    }
}

@media (max-width: 576px) {
    .button-number {
        font-size: 24px;
        font-weight: 900;
    }
}

.inquriesCardFooter {
    width: 100%;
    height: 12px;
    z-index: 10;
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
