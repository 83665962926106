.addDealContainer {
    @include make-container();
    .bold-selector {
        .customSelectInput__single-value {
            font-weight: 600;
        }
    }

    .widerNumberInput .NumberInput_input__1xsLY {
        width: 7rem;
    }

    .NumberInput_button__3-Kly {
        width: 60px;
    }
}
.fixedHeight {
    height: auto !important;
}
.addDealInputs {
    @extend .textInputNew;
    color: $secondary-color;
    background-color: transparent !important;
    height: 44px !important;
    padding: 0 !important;

    input {
        height: 44px !important;
        background-color: #fcfcfc !important;
        border-radius: 12px !important;
        border: 1px solid #c0cbd8 !important;
        box-shadow: 0px 3px 8px #49698c1a !important;
        // min-width: 30vw;
        display: flex;
        justify-content: center;
        align-items: center;
        &::placeholder {
            color: $secondary-color;
            font-weight: normal;
            background-color: #fcfcfc !important;
        }
    }
}

.deals-success-btn {
    margin-right: 1rem;
    color: #56ccb4;
}
.deals-danger-btn {
    margin-right: 3rem;
    &:hover {
        svg {
            color: white;
            font-size: 15px;
        }
    }
}
.deals-times-btn {
    margin-right: 1rem;
    svg {
        transform: rotate(45deg);
    }
}
.employeeAvatar {
    svg {
        z-index: 2;
        position: relative;
        top: 20px;
        left: 15px;
        @media (max-width: 500px) {
            top: 10px;
        }
    }
}

.mobile-drawer-full {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    background-color: white;
    transition: bottom 0.3s ease-in-out;
    z-index: 99;
    height: calc(100% - 30px);

    background-color: $card-background-color;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow-y: auto;
}

.fullsize-backdrop-100 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000002e;
    z-index: 100;
}

#dealViewAll {
    .counter {
        min-width: 50px !important;
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
}
.above-svg {
    margin-right: 10px;
    color: #56ccb4;
}

.name-rectangle {
    border-radius: 10px 0 0 10px;
}
.rectangle {
    border: 1px solid #c0cbd8;
}
.amount-rectangle {
    border-radius: 0 10px 10px 0;
}
.selected-rectangle {
    border: 2px solid #305073;
}

.mini-table-counter {
    @extend .table-heading;
    line-height: 1.5;
    color: $secondary-color;
    width: 2rem;
    min-width: 2rem;
}
.counter-width {
    width: 41px !important;
}

@media (min-width: 760px) and (max-width: 1400px) {
    .top-card-tablet-height {
        height: -webkit-fill-available;
    }
}

.dealsRadioButtons {
    @extend .btn;
    border: 1.5px solid $border-rest-color !important;
    box-shadow: 0px 3px 8px #3050731a;
    transition: none !important;
    // width: 44px;
    height: 44px;
    padding: 0 !important;
    background-color: #fcfcfc;

    &.left {
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    &.middle {
        border-radius: 0 !important;
        margin-left: 0 !important;
    }

    &.right {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 12px !important;
        border-top-right-radius: 12px !important;
        margin-left: 0 !important;
    }

    &.active {
        border: 1.5px solid $secondary-color !important;
        z-index: 0 !important;

        &__inverseColor {
            background-color: $primary-color !important;
            span {
                color: $card-background-color !important;
            }
        }
    }

    span {
        font-size: 0.75rem;
        font-weight: 400;
        color: $secondary-color;
    }
}
