//INPUT CHECK BOX COMPONENT

/* Customize the label (the checkbox-container) */
.checkbox-container {
    height: 2rem;
    width: 2rem;
    border-radius: 12px !important;
    margin: 0;
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: $common-border-style $primary-color !important;
    border-radius: 6px;
    path {
        stroke: #ffffff;
    }
    &.success {
        border: $common-border-style $success-color !important;
    }
    &.danger {
        border: $common-border-style $danger-color !important;
    }
}

/* On mouse-over, add a grey background color */
// .checkbox-container:hover input ~ .checkmark {
//     background-color: #ccc;
//     path {
//         stroke: #ccc;
//     }
// }

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: $primary-color;
    &.success {
        background-color: $success-color;
    }
    &.danger {
        background-color:$danger-color;
    }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkbox-container:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.checkmark svg {
    max-width: 1rem;
    max-height: 1.2rem;
}

//RADİO BUTTON

/* Customize the label (the container) */
.radio-container {
    height: 2rem !important;
    width: 2rem !important;
    margin: 0;
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.checkmark2 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: $common-border-style $disable-color;
    border-radius: 50%;
    &.success {
        border: $common-border-style $success-color;
    }
    &.danger {
        border: $common-border-style $danger-color;
    }
}

/* On mouse-over, add a grey background color */
// .radio-container:hover input ~ .checkmark2 {
//     background-color: white;
// }

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark2 {
    background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark2:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark2:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark2:after {
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    background: $primary-color;
    &.success {
        background: $success-color;
    }
    &.danger {
        background: $danger-color;
    }
}
