.tableOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
}

.tablePL {
    width: calc(100% - 40px);
    margin: 20px;
}

.tablePL td,
.tablePL th {
    padding: 12.5px;
    min-width: 120px;
}
.tablePL th {
    cursor: default;
}

.tablePL th.sortable {
    cursor: pointer;
}

.tablePL th i {
    color: #3f4254;
}

.tablePL th.totalRevenue,
.tablePL th.lastClosedDealDate {
    text-align: right;
}

.tablePL th.totalRevenue,
.tablePL th.lastClosedDealDate,
.tablePL th.companySize,
.tablePL th.companyType,
.tablePL th.sector {
    width: 150px;
}

.tablePL td.totalRevenue .d-flex {
    display: flex;
}

.tablePL td.lastClosedDealDate .d-flex {
    flex-direction: row-reverse;
}

.tableScroll {
    position: relative;
    // max-width: calc(100% - 20px);
    overflow: hidden;
    margin: 0 10px;
}

.selectableRow {
    cursor: pointer;
}

.selectedRow {
    background: #c9f7f5;
}

.highlightedRow {
    background: #f7ebeb;
}

.errorRow {
    background: #aa0000;
}

.mobileItem_Label {
    color: $secondary-color;
    font-size: 0.75rem;
    font-weight: $font-weight-bolder;
    line-height: 18px;
}
.mobileExpandedBox {
    &.open {
        margin: 8px 0 16px 0;
        padding: 16px 0;
        border-top: 2px solid rgba($disable-color, 0.3);
        border-bottom: 2px solid rgba($disable-color, 0.3);
        .mobileExpandedBox_More {
            margin-bottom: 28px;
        }
    }
    &_More {
        &_Text {
            color: #8a9eb4;
            text-decoration: underline;
        }
        &_Icon {
            font-size: 18px;
            line-height: 18px;
            i {
                font-size: inherit;
            }
        }
    }
}

.mobileItem_Value {
    color: $secondary-color;
    font-size: 0.875rem;
    line-height: 21px;

    &_Name {
        @extend .mobileItem_Value;
        font-weight: $font-weight-bolder;
    }

    &_Status {
        @extend .mobileItem_Value;
        font-weight: $font-weight-bolder;

        &_Warning {
            @extend .mobileItem_Value_Status;
            color: $warning-color;
        }
    }
}
.actionsGroup {
    display: flex;
    align-items: center;
    gap: 8px;
}
@media (max-width: 1380px) {
    .tableScroll {
        overflow-x: scroll;
    }
}

.table-overflow-auto {
    overflow: auto;
}

@media (max-width: 1610px) {
    .preCampaignsTable .tableScroll {
        overflow-x: scroll;
    }
}

.totalCount_table {
    color: #666;
    margin: 10px 5px;
}

.selectInputTable {
    min-width: 120px;
    width: 100%;
}

.editableTable.tableScroll {
    overflow-x: scroll !important;
}

@media (min-width: 700px) {
    .preCampaignsTable .tableScroll {
        // height:calc(100vh - 300px);
        // overflow-y:scroll;
    }
}

.editableTable th {
    text-align: center !important;
}

#newFilterMenu .selectInputTable__menu {
    margin-left: -80px;
    width: 200px;
}

.rowStripedTable {
    table {
        tbody {
            tr {
                border-bottom: 2px solid #ecf0f3;
            }
        }
    }
}

.view-all-table {
    @media (max-width: $mobile-breakpoint) {
        padding-top: 0rem;
        margin-top: 2rem;
    }
    padding-top: 5rem;
    margin-top: 5rem;
    .tablePL td,
    .tablePL th {
        padding: 12.5px;
        min-width: 70px;
    }

    table {
        border-collapse: collapse;
    }

    tbody tr {
        border-bottom: $common-border-style hsl(212, 25%, 72%, 35%);
    }
    tr:nth-last-child(1) {
        border-bottom: none !important;
    }
    .table-value.sortable {
        cursor: pointer;
        span {
            i {
                color: #3f4254;
            }
        }
    }
    .tranierTableCounter {
        width: 1em;
        min-width: 0px !important;
        padding: 0px !important;
    }

    .tranierTableCounter > div {
        width: 1.5em;
        color: $secondary-color;
        font-weight: 600;
    }

    .trainerImg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .trainerImg {
        width: 1em;
        min-width: 48px;
    }

    .trainerImg > div {
        justify-content: center;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        background-color: $disable-color;
        line-height: 3rem;
        height: 3rem;
        width: 3rem;
        border-radius: 12px;
        color: $secondary-color;
        margin-right: 1.5rem;
    }

    .lvt,
    .inHouse,
    .name,
    .expertise,
    .averageScore {
        color: #213040;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0;
    }

    .name > div,
    .expertise > div,
    .averageScore > div {
        font-weight: normal;
        font-size: 1rem;
    }
    .lvt > div,
    .inHouse > div {
        color: $success-color;
        font-weight: 600;
        font-size: 1rem;
    }
}

.searchTableAlign {
    position: relative;
}

.btn-load-more {
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 121px;
    height: 44px;
    border: $common-border-style $primary-color;
    border-radius: 12px;
    background: $card-background-color 0% 0% no-repeat padding-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    color: $primary-color;
}

.tableTopElmployees {
}

$selected-row-color: #c9f7f5;
.accountMergeInput {
    // input[type="checkbox"] {
    //     border: none;
    //     background: transparent !important;
    //     color: $card-background-color !important;
    // }
    input[type="checkbox"] {
        // border: none;
        // background: $card-background-color !important;
        color: $card-background-color !important;
        height: 15px;
        width: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: $common-border-style darken($secondary-color, 25);
        border-radius: 999px;
        outline: none;
        // transition-duration: 0.3s;
        cursor: pointer;
    }
    input[type="checkbox"]:checked {
        border: none;
        background: $selected-row-color !important;
        color: $card-background-color !important;
        content: url("/media/svg/popleads/secondary_color_check_mark.svg");
        // display: block;
        text-align: center;
        // position: absolute;
        // left: 0.7rem;
        // top: 0.2rem;
    }
}

.table-responsive .rt-resizable-header {
    padding: 6px 24px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.table-responsive .rt-resizer:before {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 3px;
    height: 18px;
    width: 18px;
    color: transparent;
    content: ".";
    background-size: 18px 18px;
    background-repeat: no-repeat;
    opacity: 0.87;
}

.table-responsive .rt-resizable-header-content:after {
    position: absolute;
    right: 8px;
    top: 3px;
    height: 18px;
    width: 18px;
    z-index: 120;
    color: transparent;
    content: ".";
}

.table-responsive .rt-th.-sort-asc .rt-resizer:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
}

.table-responsive .rt-th.-sort-desc .rt-resizer:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCAzdjkuMTNMNCA4IDMgOWw2IDYgNi02LTEtMS00IDQuMTNWM3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
}

.id-column {
    width: 50px;
    text-align: center;
    padding: 8px;
}

@media (max-width: 768px) {
    .id-column {
        width: 30px;
    }
}
