.popPagination {
    list-style: none;
    display: flex;

    .popPagination_pageButton,
    .popPagination_stepButton {
        min-width: 44px;
        height: 42px;
        background-color: $card-background-color;
        box-shadow: $common-box-shadow $box-shadow-color;
        border: $common-border-style $primary-color;
        border-radius: 12px;
        margin: 0 4px;
        color: $primary-color;
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 600;
    }

    .popPagination_pageButton.selected {
        background-color: $primary-color;
        color: $card-background-color;
    }

    .popPagination_ellipsis {
        width: 44px;
        line-height: 42px;
        display: block;
        text-align: center;
        font-size: 1.5rem;
    }

    .popPagination_stepButton:disabled {
        opacity: 0.5;
    }

    &.popPagination_secondary {
        .popPagination_pageButton,
        .popPagination_stepButton {
            border: $common-border-style $secondary-color;
            color: $secondary-color;
        }

        .popPagination_pageButton.selected {
            background-color: $secondary-color;
            color: $card-background-color;
        }
    }
}

.MuiTablePagination-root {
    border: none;
    .MuiTablePagination-caption {
        line-height: 42px;
        display: block;
        text-align: center;
        font-size: 1rem;
        font-family: "Poppins_PL";
        color: $secondary-color;
    }

    .MuiIconButton-root.MuiButtonBase-root {
        width: 44px;
        height: 42px;
        box-shadow: $common-box-shadow $box-shadow-color;
        border: $common-border-style $primary-color;
        border-radius: 12px;
        color: $secondary-color;
        margin: 0 4px;
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 600;

        &:hover {
            color: $primary-color !important;
            background-color: $card-background-color !important;
        }

        &:disabled {
            opacity: 0.5;
        }
    }
}
