.trip-status {
    .status {
        color: #fcfcfc;
        border-radius: 15px;
        padding: 10px;
        font-size: 12px;
        text-align: center;
        width: max-content;
    }
}
.height-max-content {
    height: max-content !important;
}

.success-border {
    border: 2px solid $success-color;
    color: $success-color;
    border-radius: 12px;
    font-weight: 600;
    padding: 3px 6px;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    text-transform: capitalize;
}

.waiting-border {
    border: 2px solid #8a9eb4;
    color: #8a9eb4;
    border-radius: 12px;
    font-weight: 600;
    padding: 3px 6px;
    font-size: 0.75rem;
    display: flex;
    text-transform: capitalize;
    justify-content: center;
}
.error-border {
    @extend .waiting-border;
    border: 2px solid $error-color;
    color: $error-color;
}
.request-summary-row {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
}

.trip-summary {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
}
.secondary-border {
    border: 2px solid;
    border-radius: 10px;
    font-weight: 600;
    border-color: $secondary-color;
    color: $secondary-color;
    padding: 3px 6px;
    font-size: 0.75rem;
    display: flex;
    width: max-content;
    justify-content: center;
}
.travel-textarea {
    height: 120px !important;
    min-height: 120px !important;
}
.upcoming-trips-country-border {
    @extend .secondary-border;
    padding: 2px 4px;
}

.toast-container {
    background-color: $fc-event-rejected;
    color: #fcfcfc;
    padding: 20px 20px;
    border-radius: 12px;
    font-size: 0.75rem;
}
.country-selection {
    // align-items: center;
    .country-item {
        border: 2px solid #a6b7ca;
        padding: 3px 6px;
        border-radius: 12px;
    }
    cursor: pointer;
}
.travel-contry-item:hover {
    color: #3699ff !important;
}
.travel-contry-item {
    transition: background-color 0.6s ease !important;
    color: $secondary-color;
}
.add-country-item:hover {
    color: #a6b7ca;
}
.trips-svg svg {
    width: 275px;
}
.active-selected-country {
    .country-item {
        border: 2px solid $secondary-color;
        padding: 4px 8px;
        border-radius: 12px;
        background-color: $secondary-color;
    }
    cursor: pointer;
    .country {
        color: #fcfcfc;
    }
    .time-icon {
        svg {
            transform: rotate(45deg);
            height: 10px;
            color: #fcfcfc;
            padding-left: 3px;
            margin-bottom: 3px;
        }
    }
}
.upcoming-trips-icon {
    background-color: $success-color;
    border-radius: 12px;
    padding: 6px;
}

.parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0px;
}
.add-page2-dlt {
    color: $danger-color;
}
.trips-icon-container {
    height: max-content;
}
.horizontal-seperator {
    content: "";
    min-width: 1px;
    margin: 0 calc(0px - calc(1.5rem + 1px));
    border-bottom: $common-border-style transparentize($secondary-color, 0.65);
    opacity: 0.7;
}
.horizontal-seperator:nth-child(3n-1) {
    display: none;
}
.date-detail-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}
.side-bar-grid:last-child {
    padding-bottom: 40px;
}
.addFormBtn {
    border: 2px solid $success-color;
    color: $success-color;
    padding: 4px;
    border-radius: 8px;
    width: max-content;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    svg {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.date-number {
    font-size: 28px;
    font-weight: 600;
    color: $secondary-color;
}
.single-date-card {
    height: 352px;
}
.past-day-card {
    div,
    span {
        color: #c0cbd8;
    }
}
.visas-view-all-table {
    .tranierTableCounter {
        width: 50px;
    }
    .table-heading {
        font-weight: 600;
        font-size: 0.875rem;
        color: #305073;
    }
    .table-value > div {
        font-weight: normal !important;
        font-size: 0.875rem !important;
        color: #305073;
    }
    .type {
        width: 200px;
    }
    .attachmentList {
        width: 400px;
        .visa-documents-wrap {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            min-width: 200px;
        }
    }
    .name {
        width: 200px;
    }
}
.visa-and-passport-table {
    .tranierTableCounter {
        width: 50px;
    }
    .country {
        width: 200px;
    }
    .type {
        width: 200px;
    }
    .document {
        width: 400px;
        .visa-documents-wrap {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            min-width: 200px;
        }
    }
}

.visa-documents svg {
    height: 30px;
    width: 30px;
}
.bottom-line {
    position: relative;
    top: -13px;
    background: #ca8a5e;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 12px;
}
.bottom-line-add-request {
    @extend .bottom-line;
    top: -17px;
    margin-left: 9px;
}
.bottom-line-itinerary {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 12px;
    background-color: #8a9eb4 !important;
    width: 109%;
    position: relative;
    top: 20px;
    color: transparent;
    right: 25px;
}
.viewMoreButton {
    text-decoration: underline;
    margin-top: 20px;
    font-size: 0.75rem;
    cursor: pointer;
    margin-top: auto;
}
.travelViewAllTable {
    thead tr th {
        color: $secondary-color;
    }
    .view-all-table {
        margin-top: 0 !important;
        padding-top: 0 !important;
        td {
            vertical-align: middle;
        }
    }
}
.visa-and-passport-table {
    td {
        vertical-align: middle;
    }
}
.trip-max-height {
    height: 280px;
    width: 915px !important;
    padding-right: 10px;
    overflow: auto;
    min-width: 420px;
}
.trip-max-height::-webkit-scrollbar {
    width: 10px; /* Scroll çubuğunun genişliği */
}

.trip-max-height::-webkit-scrollbar-thumb {
    background-color: #888; /* Scroll çubuğu başparmağı rengi */
    border-radius: 5px;
}

.trip-max-height::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Scroll çubuğu arka plan rengi */
}

.pagination-trips {
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    color: $secondary-color;
    align-items: center;
    text-decoration: underline;
    .popPagination {
        padding: 0 !important;
        margin: 0 !important;

        .popPagination_pageButton {
            max-width: 25px !important;
            border: 0px !important ;
            box-shadow: none;
            font-size: 12px;
        }
        .popPagination_stepButton {
            border: 0;
            font-size: 12px;
            box-shadow: none;
        }

        .popPagination_ellipsis {
            min-width: 30px !important;
            height: 30px !important;
            line-height: 30px !important;
        }
        button,
        span {
            min-width: 30px !important;
            height: 30px !important;
        }
    }

    .selected-pagination-item {
        padding: 5px;
        color: #fcfcfc;
        background-color: #305073;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border-radius: 11px;
    }
    .pagination-item {
        margin: 0 10px;
        cursor: pointer;
        padding: 5px;
    }
}
#travelInfoTooltip {
    max-width: 200px;
}
.custom-date-header {
    display: flex;
    align-items: center;
    border: 1px solid #c0cbd8;
    border-radius: 12px;
    padding: 0px 10px;
    height: 100%;
    cursor: pointer;

    .date-range-svg {
        margin-right: 10px;
        border-right: 1px solid #c0cbd8;
        padding-right: 10px;
    }
    .date-range-svg::after {
        content: "";
        height: 100%;
        width: 1px;
    }

    svg {
        width: 20px;
    }
}
.for-unset {
    p {
        all: unset;
    }
}
.g-5 {
    gap: 20px;
}
.g-2 {
    gap: 10px;
}
@media only screen and (max-width: 600px) {
    .input-range {
        .react-datepicker-wrapper {
            width: 100%;
        }
        width: 100% !important;
        input {
            min-width: none !important;
            width: 100% !important;
        }
    }
    .addTravelRequestPage {
        width: 280px !important;
    }
    .single-date-card {
        height: 480px;
    }
    .from-to-input input {
        width: 100% !important;
    }
    .edit-trip-modal input {
        width: 100% !important;
    }
    .formattedCenter {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .formattedPadding {
        padding-left: 0;
        padding-right: 0;
    }
    .date-detail-container {
        display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-gap: 1rem;
    }

    .rdrCalendarWrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rdrInRange {
        width: 54px !important;
        color: #c0cbd8 !important;
    }
    .bottom-line-itinerary {
        top: -10px;
        width: 116%;
    }
    .rdrCalendarWrapper {
        left: 14px;
        // width: 70% !important;
        // margin-left: 50px !important;
    }
    .rdrStaticRanges {
        margin-left: 5px !important;
    }
}
.flightOrOther {
    padding: 14px 0;
    @media (max-width: 600px) {
        padding: 12px 48px !important;
    }
}
.mobile-drawer-full {
    overflow-y: auto !important;
}

.double-selection {
    .option-padding div:first-child {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
.mobile-border {
    border: 1.5pt solid #a6b7ca;
    border-radius: 8px;
    text-align: center;
    align-self: end;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.upcoming-trips-mobile-drawer-btn {
    position: fixed;
    bottom: 0;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 100%;
    padding: 20px;
    border-radius: 12px;
}
.customized-sidebar {
    .side-bar-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
    }
    .sidebar-wrapper {
        cursor: default !important;
        @extend .custom-scroll;
        height: calc(100vh - 190px);
        margin-left: -35px;
        padding: 32px;
        border-radius: 0px 12px 12px 0px;
        background-color: $card-background-color;
        transition: width 0.5s ease-in-out;
        overflow-x: hidden;
        @media (max-width: 1920px) {
            padding: 28px;
        }
        .sidebar-content {
            transition: all 0.5s ease-in-out;
            width: 650px !important;
            @media (max-width: 1920px) {
                width: 350px;
            }
            height: 100%;
            overflow-y: auto;
            overscroll-behavior-y: contain;
        }
        ///mobile breakpoint
        @media (max-width: $mobile-breakpoint) {
            display: none;
        }
    }

    .sidebar-open {
        padding-right: 16px;
        width: 650px !important; ////_!_!_!_!
        @media (max-width: 1920px) {
            width: 350px;
            padding-left: 12px;
        }
        .sidebar-content {
            padding-right: 16px;
        }
    }
    .sidebar-close {
        width: 36px;
    }
    .sidebar-header {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        position: sticky;
        padding-bottom: 10px;
        top: 0;
        background-color: #fcfcfc;
        align-items: center;
        svg {
            cursor: pointer;
            margin-right: 20px;
        }
    }

    .sidebar-chevron-left {
        position: absolute;
        top: 35px;
        left: 20px;
        transition: all 0.5s ease-in-out;
        transform: rotate(180deg);
        cursor: pointer;
    }
}
.from-to-summary {
    display: flex;
    justify-content: space-between;
}
.trip-from-to-row {
    display: flex;
    justify-content: space-between;
}
@media only screen and (min-width: 600px) and (max-width: 1199px) {
    .trip-from-to-row {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr 3fr;
    }
    .trip-summary-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .min-width-200 {
        min-width: 250px;
        max-width: 250px;
    }
    .addRequestHeader {
        height: 200px;
    }
    .date-detail-container {
        display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // grid-gap: 1rem;
    }
}
@media only screen and (min-width: 600px) {
    .trip-summary-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    .trip-from-to-row {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr 3fr;
    }
}
@media only screen and (min-width: 1199px) and (max-width: 1400px) {
    .date-detail-container {
        display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // grid-gap: 1rem;
    }
    .travelViewAllTable .view-all-table {
        overflow: hidden;
    }
}
.selected-header {
    background-color: #60cccb1a;
}
.showModal {
    .modal-counter {
        font-size: 1rem;
        line-height: 1.5;
        color: $secondary-color;
        font-weight: 600;
        width: 2rem;
        min-width: 2rem;
        @media (max-width: 576px) {
            width: 1.5rem;
            min-width: 1.5rem;
            font-size: 0.875rem;
        }
    }
    .modal-img {
        position: relative;
        font-size: 1rem;
        background-color: $disable-color;
        line-height: 3em;
        height: 3em;
        width: 3em;
        min-width: 3em;
        min-height: 3em;
        overflow: hidden;
        text-align: center;
        border-radius: 12px;
        color: $secondary-color;
        margin-right: 1.5rem;
        font-weight: 600;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .modal-mini-title {
        color: $disable-color !important;
        // font-size: 0.75rem;
        @extend .small-text;
        line-height: 1.5;
        font-weight: normal;
        display: block;
        text-align: start;
    }
}
.date-list-dropdown-item:hover {
    background-color: #d3e1f4;
    border-radius: 12px;
}

.date-card-dropdown {
    .btn.btn-success.dropdown-toggle:after {
        color: #8a9eb4 !important;
    }
    button {
        box-shadow: none;
        height: max-content !important;
    }
}
.itinerary-dropdown {
    .doc-count {
        color: black;
        position: relative;
        bottom: 10px;
        left: 25px;
        background-color: red;
        color: white;
        padding-left: 7px;
        padding-right: 8px;
        font-size: 12px;
        border-radius: 50%;
        font-weight: 400;
    }
    button {
        width: max-content !important;
        padding: 0 !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        border: none !important;
    }
    .btn-success {
        border-color: white !important;
        background-color: transparent !important;
    }
    // .dropdown-svg svg{
    //      color: $success-color;
    // }
    // .dropdown-svg path{
    //     stroke: $success-color;
    // }
}
.month-select {
    display: flex;
    align-items: center;
    .month-button {
        background-color: #fcfcfc;
        color: #fcfcfc;
        border-radius: 12px;
        border: 1px solid #a6b7ca;
        height: 45px;
        width: 45px;
        svg g {
            stroke: #fcfcfc;
        }
    }
    .month-and-year {
        margin: 10px 20px;
    }
}
.travel-date {
    .deal-datepicker-custumzie {
        height: 320px !important;
    }
}

.travel-tab-menu {
    .card2 {
        margin: 0 !important;
    }
    svg {
        height: 25px;
        stroke: currentColor;
    }
    .accountMenuItemLessThanTwoItem {
        align-items: center;
    }
}
.edit-itinerary {
    background: #56ccb426;
    border-radius: 12px;
}
.itinerary-icon svg:nth-child(2) {
    color: #b93d48 !important;
    margin-left: 10px;
}

.travelNotes div {
    width: 100% !important;
}

.border-radius-travel-header {
    border-radius: 12px;
}
.addTravelRequestPage {
    position: absolute;
    z-index: 1;
    box-shadow: 0px 3px 8px #3050731a;
    border-radius: 13px;
    background: white;
    padding-right: 20px;
    width: 360px;
    top: 50px !important;
    height: max-content;
    .itinerary-date .rdrNextPrevButton {
        display: none !important;
    }
    .rdrDateRangePickerWrapper {
        // height: 380px !important;
        width: 100%;
        position: relative;
        .rdrDefinedRangesWrapper {
            width: 100%;
            position: absolute;
            left: 0;
            scroll-snap-type: x proximity;
            scroll-snap-align: center;
            display: grid;
            background: transparent;
            border: none;
            .rdrStaticRanges {
                display: none;
                @media (max-width: 750px) {
                    margin-left: 5px !important;
                }
            }
        }
        .rdrCalendarWrapper {
            width: 100%;
            margin-top: 70px;
            font-size: 14px;
            background: transparent;
            padding: 0 2rem;
            position: relative;
            top: -69px;
            left: -7px;
            @media (max-width: 750px) {
                left: 14px;
                width: 100% !important;
                // margin-left: 50px !important;
            }
            .rdrDateDisplayWrapper {
                display: none;
            }
            .rdrMonthAndYearWrapper {
                .rdrMonthAndYearPickers {
                    order: 1;
                    justify-content: flex-start;
                    background: none;
                    .rdrYearPicker,
                    .rdrMonthPicker {
                        line-height: 14px;
                    }

                    select {
                        font-weight: 600;
                        background: none;
                        text-align: left;
                        padding: 0;
                    }
                }

                .rdrNextPrevButton {
                    background: transparent;
                    position: relative;
                    width: 21px;
                    height: 21px;

                    i {
                        border: solid $secondary-color;
                        border-width: 0 3px 3px 0;
                        padding: 6px;
                        border-radius: 0px 0px 2px 0px;
                        position: absolute;
                        top: calc(50% - 3px);
                    }
                }
                .rdrPprevButton {
                    order: 2;
                    i {
                        top: calc(50% - 12px);
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    }
                }
                .rdrNextButton {
                    order: 2;

                    i {
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(-135deg);
                    }
                }
            }
            .rdrDayEndOfMonth .rdrInRange {
                width: 60px;
            }
            .rdrMonths {
                .rdrMonth {
                    width: 100%;
                    padding: 0;
                    .rdrWeekDays {
                        display: none;
                    }
                    .rdrDayDisabled > .rdrDayNumber > span {
                        color: #a6b7ca !important;
                    }

                    .rdrDays {
                        margin-top: 15px;
                        gap: 5px 0;
                        .rdrDay {
                            // color: #c0cbd8 !important;
                            aspect-ratio: 1;
                            height: auto;
                            // width: 60px;
                            // height: 30px;
                            .rdrInRange {
                                width: 77px;
                                color: #c0cbd8 !important;
                            }
                            .rdrStartEdge,
                            .rdrEndEdge {
                                color: #fcfcfc !important;
                                border: 2px solid #6490bf;
                                border-radius: 8px;
                                span {
                                    color: #305073 !important;
                                }
                            }
                            &:not(.rdrDayPassive, .rdrDayDisabled) {
                                .rdrStartEdge,
                                .rdrEndEdge,
                                .rdrInRange {
                                    inset: 0;
                                }
                                .rdrDayNumber {
                                    inset: 0;
                                    span {
                                        color: #305073 !important;
                                        font-weight: 600 !important;
                                    }
                                }
                                .rdrStartEdge.rdrEndEdge {
                                    color: transparent !important;
                                    border: $common-border-style $primary-color;
                                    & ~ .rdrDayNumber {
                                        span {
                                            color: #6490bf !important;
                                        }
                                    }
                                }
                                .rdrDayStartPreview.rdrDayEndPreview {
                                    border: none;
                                    inset: 0;
                                }
                            }

                            &.rdrDayPassive {
                                span {
                                    color: $disable-color !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
