:root {
    --font-family: $font-family;
    --contrast: #fff;
    --contrast-inverse: #212526;
    --gutter-x: 64px;
    --gutter-y: 36px;
    --primary-green: $primary-green;
    --secondary-green: $secondary-green;
    --tertiary-green: $tertiary-green;
    --primary-pink: $primary-pink;
    --secondary-pink: $secondary-pink;
    --primary-orange: $primary-orange;
    --primary-purple: $primary-purple;
    --primary-darkblue: $primary-darkblue;
    --primary-gray: $primary-gray;
    --secondary-gray: $secondary-gray;
    --primary-text-color: $primary-text-color;
    --secondary-text-color: $secondary-text-color;
    --tertiary-text-color: $tertiary-text-color;
    --secondary-background: $secondary-background;
}

$primary-green: #328456;
$secondary-green: #98c1aa;
$tertiary-green: #daf2e5;
$primary-pink: #db406c;
$secondary-pink: #e289a3;
$primary-orange: #df8524;
$primary-purple: #a965e8;
$primary-darkblue: #3e4054;
$primary-gray: #777987;
$secondary-gray: #eff3f5;
$primary-text-color: #2c2c2c;
$secondary-text-color: #82929b;
$tertiary-text-color: #191919;
$secondary-background: #a6b7ca;

@font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 300;
    src: url("../../../assets/fonts/Gilroy-Light.ttf");
}

@font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 400;
    src: url("../../../assets/fonts/Gilroy-Medium.ttf");
}

@font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    src: url("../../../assets/fonts/Gilroy-SemiBold.ttf");
}

@font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    src: url("../../../assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 800;
    src: url("../../../assets/fonts/Gilroy-ExtraBold.otf");
}

.page {
    font-family: "Gilroy";
    // height: 1200px;
    page-break-after: always;
    page-break-inside: avoid;
    background-color: var(--contrast) !important;
    color: var(--contrast-inverse) !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
}

.header {
    padding-left: var(--gutter-x);
    padding-right: var(--gutter-x);
    padding-top: var(--gutter-y);
    padding-bottom: var(--gutter-y);
    display: flex;
    align-items: center;
    .leoronLogo {
        width: 112px;
        height: 32px;
        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .headerDivider {
        height: 2px;
        border-radius: 6px;
        flex: 1;
        margin: 0 24px;
        background-color: $primary-green;
    }
    .headerText {
        font-size: 16px;
        color: $secondary-text-color;
    }
}

.content {
    padding-left: var(--gutter-x);
    padding-right: var(--gutter-x);
    padding-top: 16px;
    padding-bottom: 16px;
}

.primaryGreenText {
    color: $primary-green;
}

.primaryOrangeText {
    color: $primary-orange;
}

.primaryPinkText {
    color: $primary-pink;
}

.primaryDarkBlueText {
    color: $primary-darkblue;
}

.primaryGreenBg {
    background-color: $primary-green;
}

.primaryOrangeBg {
    background-color: $primary-orange;
}

.primaryPinkBg {
    background-color: $primary-pink;
}

.primaryDarkBlueBg {
    background-color: $primary-darkblue;
}

.pageTitle {
    font-size: 24px;
    font-weight: 600;
    color: $primary-text-color;
    line-height: 36px;
}

.commonTable {
    width: 100%;
    tr {
        font-size: 18px;
        border-bottom: 2px solid $secondary-gray;
        th,
        td {
            padding: 10px;
            border-right: 2px solid $secondary-gray;
            &:last-child {
                border-right: none;
            }
        }
        th {
            font-weight: 500;
        }
    }
    tbody {
        tr {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.dealsData {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.comparisonText {
    font-size: 14px;
    color: $primary-text-color;
}

.dealPrimaryTitle {
    font-size: 18px;
    font-weight: 500;
    color: $primary-text-color;
    text-align: center;
}

.dealPrimaryValue {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}

.dealsecondaryTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height: 48px;
}

.dealsecondaryTitle {
    @extend .dealPrimaryTitle;
    font-size: 14px;
    margin-left: 12px;
}
.dealProjectsecondaryTitle {
    @extend .dealPrimaryTitle;
    font-size: 20px;
    margin-left: 14px;
}
.dealSecondaryValue {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.horizontalLine {
    width: 100%;
    min-width: 120px;
    height: 4px;
    border-radius: 8px;
}
.horizontalProjectLine {
    width: 100%;
    min-width: 120px;
    max-width: 130px;
    height: 4px;
    border-radius: 8px;
    margin: auto;
}
.dealsTotalAmount {
    color: $primary-green;
    font-weight: 600;
    font-size: 22px;
    margin-left: 10px;
}
.dealItemPublic,
.dealItemLVTPublic {
    flex: 0 0 17%;
    max-width: 180px;
    margin-left: 18px;
    margin-right: 18px;
}

.dealItemInHouse,
.dealItemLVTInHouse {
    @extend .dealItemPublic;
    margin-left: 42px;
    margin-right: 42px;
}

.dealItemConsulting,
.dealItemElearning,
.dealItemCoaching {
    flex: 0 0 24%;
    margin-left: 24px;
    margin-right: 24px;
}

.revenueContainerForProjects {
    .statusBar {
        width: 100%;
        height: 56px;
        background-color: $tertiary-green;
        display: flex;
        align-items: center;

        .amount {
            padding-left: 24px;
            font-size: 24px;
            font-weight: 600;
        }
    }
    .subTitle {
        font-size: 18px;
        color: $primary-text-color;
        font-weight: 500;
        margin-top: 8px;
        margin-bottom: 48px;
    }
}

.marginTop {
    margin-top: 24px;
    width: 100%;
}
.dealSellersandClientsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 190px;
}

.sellersAndClientTitle {
    color: $primary-text-color;
    margin: 20px 0;
    font-size: 20px;
    font-weight: 600;
}
