@import "../../final-report-common.module.scss";

.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 48px;
    grid-row-gap: 32px;
    .trainersContainer {
        display: flex;
        align-items: center;
        .sessionsInfo {
            .title {
                color: $primary-text-color;
                font-size: 18px;
            }
            .subTitle{
                color: $primary-text-color;
                font-size: 18px;
                opacity: .8;
            }
        }
    }
}

.platformContainer{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 48px;
        grid-row-gap: 32px;
        .platformItems{
            display: flex;
            align-items: center;
        }
        .title {
            color: $primary-text-color;
            font-size: 18px;
        }
        .subTitle{
            color: $primary-text-color;
            font-size: 18px;
            opacity: .8;
        }
}


.cardTableImg {
    position: relative;
    font-size: 24px;
     background-color: $secondary-background;
    line-height: 3rem;
    height: 72px;
    width: 72px;
    min-width: 72px;
    min-height: 72px;
    overflow: hidden;
    text-align: center;
    border-radius: 12px;
    // color: $secondary-color;
    margin-right: 0.5rem;
    padding: 10px;
    font-weight: 600;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &.card-heading {
        font-size: 1rem;
        font-weight: 600;
        @media (max-width < 1920px) {
            font-size: 0.875rem;
        }
    }
}

.fallbackNameTag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    height: 72px;
    width: 72px;
    border-radius: 12px;
    font-size: 32px;
    font-weight: 600;
    background-color: #a6b7ca;
    color: #305073;
}
