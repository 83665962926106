//RADIO BUTTON WİTH CHECKMARK AND TEXT

/* Customize the label (the checkbox-container) */
.checkradio-container {
    width: fit-content;
    min-width: 2.75rem !important;
    border-radius: 12px !important;
    padding: 0.5rem 0.8rem !important;
    border: $common-border-style $border-rest-color;

    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    background-color: $card-background-color;
    color: $secondary-color;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &.is-checked {
        background-color: $primary-color;
        color: $card-background-color;
    }

    /* Style the checkmark */
    .custommark {
        display: none;

        &.is-checked {
            display: inline;
            margin-right: 8px;
        }

        svg {
            display: inline-block;
            width: 14px;
            height: 12px;
        }
    }
}


