.heading-1{
    font-size:1.8125rem;
    font-weight:500;
    line-height:2.2625rem;
    letter-spacing: -0.58px;
}

.heading-2{
    font-size:1.5rem;
    font-weight:500;
    line-height:1.875rem;
    letter-spacing: -0.384px;
}

.heading-3{
    font-size:1.21875rem;
    font-weight:600;
    line-height:1.7688rem;
    letter-spacing: -0.234px;
}

.heading-4{
    font-size:1.0625rem;
    font-weight:600;
    line-height:1.5063rem;
    letter-spacing: -0.136px;
}

.heading-5{
    font-size:0.91563rem;
    font-weight:500;
    line-height:1.2938rem;
}

.body-1{
    font-size:0.91563rem;
    font-weight:400;
    line-height:1.2938rem;
}

.heading-6{
    font-size:0.75rem;
    font-weight:500;
    line-height:1.0638rem;
}

.body-2{
    font-size:0.75rem;
    font-weight:400;
    line-height:1.0638rem;
}