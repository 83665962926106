@import "../../final-report-common.module.scss";

.sellerImg {
    width: 45pt;
    height: 45pt;
    border-radius: 50%;
    margin-bottom: 6px;
}

.partnerImg {
    width: 64pt;
    height: 64pt;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
.sellerContainer{
    display: flex;
    align-items: center;
    margin-bottom:70px;
}

.customerImg {
    width: 48pt;
    height: 48pt;
    margin-right: 12px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.fallbackNameTag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45pt;
    height: 45pt;
    border-radius: 50%;
    margin-bottom: 6px;
    font-size: 24px;
    font-weight: 600;
    background-color: #a6b7ca;
    color: #305073;
    line-height: 2em;
}