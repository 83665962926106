@import "../../../../../src/app/popleads/assets/css/design_2.0/variables";

.chatItem {
    margin-top: 1rem;
    display: flex;
    gap: 4px;
}

.avatar {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: $card-border-radius;
    overflow: hidden;
}

.loadingIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $card-border-radius;
    background-color: $success-color;
    svg {
        color: white;
    }
}

// .messageBubble {
//     display: inline-block;
//     padding: 10px;
//     border-radius: $card-border-radius;
//     background-color: $success-color;
// }

// .skeletonText {
//     height: 10px;
//     background-color: #b0dbd3;
//     border-radius: 4px;
// }

// .skeletonAvatar {
//     animation: skeleton-loading 1s infinite ease-in-out alternate;
// }

// .skeletonMessageBubble {
//     animation: skeleton-loading 1s infinite ease-in-out alternate-reverse;
// }

// @keyframes skeleton-loading {
//     0% {
//         opacity: 0.5;
//     }
//     100% {
//         opacity: 1;
//     }
// }
