@import "../../final-report-common.module.scss";

.pageContainer {
    height: 100vh;
    background-size: 100% 100%;
    position: relative;
}

.titleContainer {
    position: absolute;
    left: 10%;
    bottom: 45%;
    color: #ffffff;
    .textBig {
        width: 250px;
        font-size: 64px;
        font-weight: 800;
        line-height: 1.3;
    }
    .border {
        border-top: 3pt solid #ffff;
        width: 75pt;
        margin: 12px 0;
    }
    .subText {
        font-size: 18pt;
    }
}

.logo {
   position: absolute;
   left: 10%;
   bottom: 2%;
    svg {
        height: 205pt;
        width: 170pt;
        color: white;
        path:nth-child(1) {
            fill: white;
        }
        path:nth-child(2) {
            fill: white;
        }
        path:nth-child(3) {
            fill: white;
        }
        path:nth-child(4) {
            fill: white;
        }
        path:nth-child(5) {
            fill: white;
        }
        path:nth-child(6) {
            fill: white;
        }
    }
}
