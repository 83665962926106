.color-success {
    color: $success-color !important;
}

.color-warning {
    color: $warning-color !important;
}

.color-error {
    color: $error-color !important;
}

.color-secondary {
    color: $secondary-color !important;
}

.color-disable {
    color: $disable-color !important;
}

.color-rest {
    color: $card-background-color !important;
}

.p-34px {
    padding: 34px !important;
}

.p-md-64px {
    @media (min-width: 768px) {
        padding: 64px !important;
    }
}

.transparent-border {
    border-color: transparent !important;
}

.pb-24px {
    padding-bottom: 24px !important;
}

.pt-1rem {
    padding-top: 1rem !important;
}

.pt-2rem {
    padding-top: 2rem !important;
}

.pt-3rem {
    padding-top: 3rem !important;
}

.pb-1rem {
    padding-bottom: 1rem !important;
}

.pb-2rem {
    padding-bottom: 2rem !important;
}

.pb-1-5rem {
    padding-bottom: 1.5rem !important;
}

.mr-1rem {
    margin-right: 1rem !important;
}

.mt-1-5rem {
    margin-top: 1.5rem !important;
}

.mt-1rem {
    margin-top: 1rem !important;
}

.ml-1rem {
    margin-left: 1rem !important;
}

.mt-3rem {
    margin-top: 3rem !important;
}

.mb-2rem {
    margin-bottom: 2rem !important;
}

.ml-1-5rem {
    margin-left: 1.5rem !important;
}

.ml-2rem {
    margin-left: 2rem !important;
}

.ml-3rem {
    margin-left: 3rem !important;
}

.w-h-64px {
    width: 64px !important;
    height: 64px !important;
}

.min-vh-50 {
    min-height: 50vh !important;
}

.vertical-seperator {
    content: "";
    min-width: 1px;
    margin: 0 calc(0px - calc(1.5rem + 1px));
    border-left: $common-border-style transparentize($secondary-color, 0.65);
}

.table-counter {
    min-width: 1rem;
    margin-right: 2rem;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.font-weight-extra-bold {
    font-weight: 800 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.line-height-1 {
    line-height: 1 !important;
}

.line-clamp-1,
.line-clamp-2,
.line-clamp-3 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line-clamp-1 {
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    -webkit-line-clamp: 3;
}

.break-word {
    word-break: break-word;
}

.margin-bottom-2rem {
    margin-bottom: 2rem;
}

.margin-bottom-3rem {
    margin-bottom: 3rem;
}

@function pxToRem($pxSize) {
    @return $pxSize / 16 + rem;
}

$font-size-list: 12, 14, 16, 18, 20, 22, 28, 32, 48;

@each $value in $font-size-list {
    .font-size-#{$value} {
        font-size: pxToRem($value);
    }
}
@each $value in $font-size-list {
    .font-size-#{$value}-important {
        font-size: pxToRem($value) !important;
    }
}

@media (max-width: 1919px) {
    .font-size-28 {
        font-size: 1.5rem;
    }
    .font-size-22-important {
        font-size: 1.25rem !important;
    }
    .font-size-16-important {
        font-size: 0.875rem !important;
    }
}
.border-bottom-1px-disabled {
    border: 1px solid #a6b7ca !important;
}

$colors: (
    "primary": #6490bf,
    "secondary": #305073,
    "card-background": #fcfcfc,
    "background": #eff3f8,
    "success": #56ccb4,
    "disable": #a6b7ca,
    "error": #b93d48,
    "warning": #ca8a5e,
    "darkBlue": #213040
);

@each $name, $color in $colors {
    .#{$name}-color {
        color: $color;
    }
}
@each $name, $color in $colors {
    .#{$name}-color-important {
        color: $color !important;
    }
}

.border-radius-12 {
    border-radius: 12px;
}
.border-bottom-1-5px {
    border-bottom: $common-border-style $disable-color;
}

.border-bottom-opacity-35 {
    border-bottom: $common-border-style transparentize($disable-color, 0.65);
}

.border-bottom-opacity-35-parent {
    & > *:not(:last-child) {
        border-bottom: $common-border-style transparentize($disable-color, 0.65);
    }
}

.capitalizeFirstLetter {
    text-transform: capitalize;
}

.clearMinWidth > * {
    min-width: none !important;
}
.clearShadow {
    box-shadow: none !important;
}
.standart-box-shadow {
    box-shadow: $card-box-shadow;
}

.font-size-0-875rem {
    font-size: 0.875rem !important;
}

.font-size-1-25rem {
    font-size: 1.25rem !important;
}

.display-grid {
    --grid-template-columns: auto auto auto;
    --grid-gap: 0;
    --grid-align-items: center;
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    grid-gap: var(--grid-gap);
    align-items: var(--grid-align-items);
}

.flex-basis-160px {
    flex-basis: 160px !important;
}

.w-md-min-content {
    @media (min-width: 768px) {
        width: min-content !important;
    }
}

.noWrap {
    display: flex !important;
    flex-wrap: nowrap !important;
}

.p-0 {
    padding: 0 !important;
}

.stroke-3 {
    stroke-width: 3px;
}

.top-50 {
    top: 50%;
}

.start-50 {
    left: 50%;
}

.translate-middle {
    transform: translate(-50%, -50%);
}
.border-color-success {
    border-color: $success-color !important;
}

.hide-chevron::after {
    display: none !important;
}

.min-w-none {
    min-width: auto !important;
}

.grid {
    display: grid;
    grid-template-rows: var(--grid-rows, 1);
    grid-template-columns: var(--grid-columns, 12);
    gap: var(--grid-gap, 0);
}
.color-success-mobile {
    @media (max-width: $mobile-breakpoint) {
        color: $success-color !important;
    }
}
.font-weight-semi-bold {
    font-weight: 500 !important;
}

.fullsize-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000002e;
    z-index: 99;
    &.enter-right {
        transform: translateX(0);
    }
    &.exit-right {
        transform: translateX(100%);
    }
}
.text-underline {
    text-decoration: underline;
}
