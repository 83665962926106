.accountsGridItem_Image {
    font-size: 2rem;
    background-color: $disable-color;
    line-height: 4.375rem;
    flex: 0 0 4.375rem;
    height: 4.375rem;
    width: 4.375rem;
    border-radius: $card-border-radius;
    color: $secondary-color;
}

.accountsGridItem_AccountName {
    display: flex;
    text-align: start;
    align-items: flex-end;
    font-size: 1rem;
    color: $secondary-color;
    line-height: 1.5;
    height: 3rem;
    span {
        max-height: 3rem;
        overflow: hidden;
    }
}

.accountsGridItem_AccountType {
    color: $disable-color;
}

.accountsGridItem_Info_Desc {
    font-size: 0.875rem;
    line-height: 1.5;
    color: $secondary-color;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.accountsGridItem_Info_title {
    padding-top: 8px;
    font-size: 0.75rem;
    line-height: 1.5;
    color: $disable-color;
    font-weight: normal;
}

.accountsGridItem_Info_TotalRevenueCount {
    font-size: 1.375rem;
    line-height: 1.5;
    color: $success-color;
    font-weight: 600;
}

.accountsGridItem_Info {
    font-size: 0.75rem;
    line-height: 30px;
    margin-top: 16px;
    height: 100px;
}

.accountsGridItem_AccountIndustry,
.accountsGridItem_AccountType {
    font-size: 0.75rem;
    color: $disable-color;
    line-height: 1.5;
}

.stickyRow__accountDetails {
    top: 155px;
}

.accountDetailBox {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;

    @media (min-width: 1200px) {
        grid-template-columns: min-content 1fr;
    }
}

.accountDetail {
    margin-top: 0;
    @media (min-width: 1200px) {
        margin-top: 1.5rem;
    }
}

.accountInfoBilling,
.accountOwner {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content;
}

.accountOwner,
.accountInfoBilling {
    align-items: center;
}

.accountsGridItem_Hero {
    text-align: center;
    font-weight: 600;
    font-size: 6rem;
    line-height: 2em;
    flex: 0 0 2em;
    height: 2em;
    width: 2em;
    background-color: $disable-color;
    border-radius: $card-border-radius;
    color: $secondary-color;
    margin-right: 2rem;
}

.accountsGridItem_Icon {
    background-color: $secondary-color;
    width: 48px;
    height: 48px;
    border-radius: $card-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    color: $card-background-color;
    &-disabled {
        background-color: $disable-color;
        color: $secondary-color;
    }
}

.accountMenuItem {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    color: $secondary-color;
    font-size: 1rem;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    margin-top: 0.5rem;

    @media (min-width: 895px) {
        margin-top: 0;
        justify-content: center;
        width: auto;
    }
    @media (max-width: 1920px){
        font-size: 0.875rem !important;
    }
    > div {
        display: grid;
        grid-template-columns: min-content auto;
        svg {
            margin-right: 1rem;
        }
    }
    &:hover {
        cursor: pointer;
    }
    &.menuActive {
        &::after {
            content: "";
            width: 0.5rem;
            position: absolute;
            border-radius: 999px;
            background-color: $success-color;

            height: 100%;
            left: -1rem;
            top: 0;

            @media (min-width: 1200px) {
                height: calc(100% + 2rem);
                top: -1rem;
            }
        }
    }
}

.accountMenuItemLessThanTwoItem{
    @extend .accountMenuItem;
    @media (min-width: 425px) {
        margin-top: 0 !important;
        justify-content: center !important;
        width: auto !important;
    }
    
}
// @media (max-width: 769px) {
//     .accountMenuItem{
//         display: flex;
//         flex-direction: column;
//     }
// }
.accountDetailsTable {
    overflow: auto;

    table > tbody > tr > td.amount > div {
        color: $success-color !important;
        font-weight: 600 !important;
    }
}

#accountsTable {
    th:first-child,
    td:first-child {
        min-width: auto;
    }
}


@media (max-width: 895px) {
    .subMenuParent{
        display: flex !important;
        flex-direction: column !important;
    }
}