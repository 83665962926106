.symbolBackground {
    background-color: #e1e9ff !important;
}

.symbolBackgroundCircle {
    border-radius: 360px !important;
}

.tableSalesByCampaign .tdWithPadding {
    padding: 0.2rem !important;
}

.highlightBackground {
    // background-color: #F3F6F9 !important;
    padding: 5px 10px;
}

.symbolBackground {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.tableRank {
    color: #464e5f;
    font-size: 18px;
    font-weight: 300;
    width: 20px;
}

.salesByCampaignViewAll {
    .tableSalesByCampaign {
        margin-top: 5rem;
        thead tr th:nth-child(2) {
            color: #213040;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 0;
        }
        .viewAllTitleReports {
        }
    }
    .salesByCampaignInput {
        display: flex;
        justify-content: end;
        position: absolute;
        right: 10%;
        top: 50px;
        input {
            min-width: 200px;
            width: 15vw;
            background: $card-background-color 0% 0% no-repeat padding-box;
            box-shadow: $common-box-shadow $box-shadow-color !important;
            border: $common-border-style $border-rest-color;
        }
        input::placeholder {
            text-align: left;
            font: normal normal 600 14px/21px;
            letter-spacing: 0px;
            color: $disable-color;
            padding-left: 5px;
        }

        .search-icon {
            box-shadow: 3px 0px 8px #5a81ab1a;
        }
    }
}
@media (max-width: 768px) {
    .salesByCampaignInput {
        left: 0;
    }
}

.tableAmount {
    width: 150px;
    text-align: center;
    color: #213040;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0;
}

.tableWonDeals {
    min-width: 100px;
}

.tableCount {
    width: 75px;
    text-align: center;
}

@media (min-width: 993px) {
    .symbolBackground {
        @include fluid-Width($width_lg, $width_xxl, 30px, 50px);
        @include fluid-Height($width_lg, $width_xxl, 30px, 50px);
    }
}


#viewAllTable{
    .table-value, & > tr > td, & > tr >th{
        // width: 50px !important;
        min-width: 50px !important;
    }

    .table-counter {
        transform: translateY(-13%);
    }

    .table-counter, .link {
        width: 50px !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        margin: 0 !important;
        min-width: 50px !important;
        max-width: 50px !important;
    }
    .paidAmount, .amount, .deliveredAmount, .totalPaidAmount {
        text-align: right !important;
        vertical-align: inherit;
        transform: translateX(1%);

        div{
            justify-content: flex-end;

        }
    }
    .name, .ad{
        width: 50% !important;
    }
}

