.white-space {
    white-space: pre-wrap;
}

.accountsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    gap: 16px;
    width: 100%;
}

// @media (min-width: 650px) and (max-width: 1329px) {
//     .accountsGrid {
//         grid-template-columns: 1fr 1fr;
//     }
// }

// @media (min-width: 1330px) and (max-width: 1649px) {
//     .accountsGrid {
//         grid-template-columns: 1fr 1fr 1fr;
//     }
// }

// @media (min-width: 1650px) {
//     .accountsGrid {
//         grid-template-columns: 1fr 1fr 1fr 1fr;
//     }
// }

.accountsGridItem_Image {
    flex: 0 0 120px;
    height: 120px;
    width: 120px;
    background-color: #eff2ff;
    margin-right: 25px;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    line-height: 120px;
    color: #7e8299;
    font-size: 40px;
    font-weight: 600;
}

.accountsGridItem_Image img {
    width: 100%;
    object-fit: contain;
}

.accountsGridItem_Title {
    //margin-left:20px;
}

.accountsGridItem_Title a {
    color: #3f4254;
}

.accountsGridItem_AccountName {
    font-size: 24px;
    line-height: 30px;
    height: 60px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    text-align: center;
}

.accountsGridItem_AccountIndustry {
    font-size: 20px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.accountsGridItem_AccountType {
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.row.totalRevenue {
    font-size: 14px;
}

.accountsGridItem_Info_TotalRevenueCount {
    font-size: 20px;
    color: #009d6e;
}

.accountsGridItem_Info {
    font-size: 12px;
    line-height: 30px;
    margin-top: 45px;
    height: 130px;
}

.accountsGridItem_Info .col-sm-5 {
    padding: 0;
}

.accountsGridItem_Info_title {
    font-weight: 600;
    min-width: 162px;

    /*padding-right:40px;*/
}

.accountsGridItem_Info .svg-icon {
    margin-right: 15px;
}
.max-content {
    width: max-content;
}

.row.totalRevenue .svg-icon {
    margin-left: -10px;
    margin-right: 5px;
}

.accountsGridItem_Info .svg-icon svg {
    width: 20px;
    height: 20px;
}

.accountsGridItem_Info .svg-icon svg g [fill] {
    fill: #4b5fc2;
}

.accountsGridItem_Button {
    margin-top: 40px;
    text-align: right;
}

.accountsGridItem_Button button {
    background: #eff2ff;
    padding: 10px 30px;
    color: black;
}

.accountsGridItem_OfficialBadge {
    display: flex;
    align-items: center;
    &_WithBorder {
        padding: 0 12px;
        border: $common-border-style $border-rest-color;
        border-radius: 12px;
    }
    &_Text {
        font-size: 0.875rem;
        color: #8a9eb4;
        font-weight: 500;
    }
}

#accountsTable th:first-child {
    //width:25%;
    min-width: 250px;
}

#partnerAccountsTable th:first-child {
    //width:25%;
    min-width: 120px;
}

.accountsTable_Muted {
    color: #ccc;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.accountsTable_AccountName {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.accountsTable_AccountName a {
    color: #3f4254;
}

.accountsTable_Image {
    flex: 0 0 40px;
    height: 40px;
    background-color: $disable-color;
    margin-right: 10px;
    color: $secondary-color;
    border-radius: 12px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accountsTable_Image img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
}

.iconColor svg g [fill] {
    transition: fill 0.3s ease;
    fill: #4b5fc2 !important;
}

.orange-svg svg g [fill] {
    transition: fill 0.3s ease;
    fill: #c9a524 !important;
}

.cardFullPage {
    padding: 35vh 0;
}

.cardFullPage .indicatorBox {
    margin: 0;
}

.cardMini .cardHeader,
.cardMini .cardFluid {
    padding: 0 0 0 0 !important;
}

.cardMini {
    padding: 25px;
}

.accountCardBlue {
    background-color: #e3f1ff;
}

.accountInfoBilling {
    font-size: 1.125rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.accountInfoBilling div {
    margin-bottom: 10px;
}

.accountDetailTableHeader {
    padding: 20px;
    padding-bottom: 0;
    margin: 0;
}

.accountDetailTableHeader .MuiPagination-root,
.accountDetailTableHeader .MuiTablePagination-root {
    display: inline-block;
    border: 0;
}

.accountDetailTableHeader .MuiTablePagination-toolbar {
    min-height: 40px;
}

.accountDetailTableSearch {
    width: 250px;
}

.menuItem {
    padding: 15px;
    cursor: pointer;
}

.menuItem:hover,
.menuItem.menuActive {
    background-color: #f3f6f9;
}

.accountMenuButton {
    margin-top: 5px;
    font-size: 18px;
    margin-left: 10px;
    align-items: space-between;
}

.accountMenuButton h2,
.accountMenuButton span {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.accountMenuButton h2 {
    margin-bottom: 5px;
}

.accountMenuButton span {
    margin-left: -5px;
    padding: 5px 10px;
}

.accountMenuButton_info {
    margin-top: "11px";
    color: #4b5fc2;
}

.menuActive .accountMenuButton span {
    color: #0bb783;
    background-color: #d7f9ef;
}

.lastDeal h2 {
    font-size: 1.125rem;
    color: #434349;
}

.lastDeal label {
    font-size: 1.125rem;
}

.lastDeal .row {
    margin-top: 15px;
}

.lastDeal .row:first-child {
    margin-top: 0;
}

.accountInfoBox {
    width: calc(100% - 150px);
}

.accountInfoBox h1 {
    font-size: 24px;
}

.accountInfoBox p {
    font-size: 18px;
}

.accountDetail {
    margin-top: 35px;
}

.accountDetail .row {
    margin-top: 10px;
}

.accountDetail .row:first-child {
    margin-top: 0px;
}

.accountKeys {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
}

.accountKeys .row {
    margin-top: 30px;
}

.accountKeys .row:first-child {
    margin-top: 0px;
}

.accountKeys h2 {
    font-size: 16px;
    color: #c9a524;
}

.accountKeys a {
    font-size: 16px;
    color: #3f4254;
}

.accountKeys label {
    font-size: 16px;
}

.accountMenu {
    padding: 5px 15px;
}

.accountMenu .row {
    display: flex;
    justify-content: space-between;
}

.accountOwner .row {
    margin-top: 10px;
}

.accountOwner .row:first-child {
    margin-bottom: 20px;
}

.accountOwnerNameTag {
    width: 85px;
    height: 85px;
    line-height: 85px;
    text-align: center;
    font-size: 40px;
    color: #0bb783;
    border-radius: 360px;
    background-color: #f3f6f9;
    margin-right: 10px;
}

.accountOwnerNameDiv {
    margin-top: 0px;
    width: calc(100% - 95px);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.accountOwnerName {
    color: #212529;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 85px;
}

.accountOwnerEmail {
    font-size: 1.125rem;
    color: #434349;
    margin-top: 2px;
}

.accountDetailBox {
    padding: 40px 30px 20px 30px;
}

.indicatorAccount {
    margin-top: 100px !important;
}

#accountsTable th.amount,
#accountsTable th.count {
    text-align: right;
}

#accountsTable th.baslik {
    width: 150px;
}

#partnerAccountsTable th.amount,
#partnerAccountsTable th.count {
    text-align: right;
}

#partnerAccountsTable th.baslik {
    width: 150px;
}

.stickyRow__accountDetails {
    position: sticky;
    top: 120px;
}

.accountModalLabel {
    width: 100px;
    line-height: 40px;
}

.officialAccountModalLabel {
    min-width: 22%;
    line-height: 40px;
    &.required-field:after {
        color: $error-color;
        content: "*";
        position: absolute;
        margin-left: 7px;
        margin-top: 3px;
    }
}

@media (min-width: 1650px) {
    .accountModal .form-control {
        width: 300px !important;
    }
}

@media (min-width: 600px) {
    .selectInput_account {
        width: auto;
        min-width: 220px;
        font-weight: 400 !important;
    }
}

@media (max-width: 599px) {
    .selectInput_account {
        width: 100%;
    }
}

.editAccountSVG {
    text-align: right;
    margin-top: -25px;
}

.locationCountModal {
    font-weight: 600;
    font-size: 14px;
    color: #434349;
    width: 50px;
    line-height: 38px;
}

// .mergeModalTitle {
//     font-weight: 600;
//     font-size: 20px;
// }

.mergeModalSubtitle {
    font-weight: normal;
    font-size: 1rem;
    color: #305073;
}

.mergeModalSubtitle2 {
    font-weight: 400;
    font-size: 16px;
}

.mergeModalSubtitle span,
.mergeModalSubtitle2 span {
    font-size: 18px;
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 4px;
    font-weight: 600;
}
.mergeModalSubtitle span {
    color: #213040 !important;
    font-size: 1rem !important;
}
.mergeModalSearchInput {
    width: 300px;
}

.mergeModal .MuiTablePagination-root {
    border: none;
    align-self: flex-end;
}

.mergeModal .MuiTablePagination-toolbar {
    min-height: 0px;
}

.mergeModalCheckbox {
    min-width: 0px !important;
    width: 40px;
}

.moreInfoButton {
    color: #000;
    background-color: #eff2ff;
    padding: 7px 20px;
    border-radius: 5px;
}

.moreInfoButton:hover {
    color: #000;
    text-decoration: underline;
}

.accountModal .ck-editor {
    width: 300px !important;
}

.phone-wrapper {
    border: 1.5px solid #c0cbd8;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    width: fit-content;
    padding: 6px 12px;
}

.phone-icon {
    border-top: 1.5px solid #c0cbd8;
    border-bottom: 1.5px solid #c0cbd8;
    border-left: 1.5px solid #c0cbd8;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    padding: 6px 12px;
}

.officialAccountsTable .tableScroll {
    overflow-x: auto;
}

#accountsTable td.table-value,
#partnerAccountsTable td.table-value,
#officialAccountsTable td.table-value {
    vertical-align: middle !important;
}

#officialAccountsTable .counter {
    min-width: 50px !important;
    width: 50px !important;
}

#officialAccountsTable .accountName {
    min-width: 250px !important;
    width: 250px !important;
}

#officialAccountsTable .billingAddress {
    min-width: 250px !important;
    width: 250px !important;
}

#officialAccountsTable .branchList {
    min-width: 250px !important;
    width: 250px !important;
}

#officialAccountsTable .vatNumber {
    max-width: 200px !important;
}

#officialAccountsTable .edit {
    min-width: 40px !important;
    width: 40px !important;
}
