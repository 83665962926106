.MuiPagination-ul .Mui-selected, .MuiButtonBase-root:hover{
    background-color: #3699FF !important;
    color:white !important; 
}

.MuiPagination-ul li:first-child button, .MuiPagination-ul li:last-child button{
    //color: #3699FF;
    //background-color: #E1F0FF;
    background-color: #3699FF !important;
    color:white !important; 
}

th#amount{
    width:150px;
    text-align:center;
}