$primary-color: #6490bf;
$secondary-color: #305073;
$tertiary-color: #8a9eb4;
$card-background-color: #fcfcfc;
$background-color: #eff3f8;
$success-color: #56ccb4;
$success-color-light: #56ccb426;
$disable-color: #a6b7ca;
$error-color: #b93d48;
$warning-color: #ca8a5e;
$info-color: #6490bf;

$box-shadow-color: #49698c1a;
$border-rest-color: #c0cbd8;

$common-border-style: 1px solid;
$common-border-width: 1px;
$common-box-shadow: 0px 3px 8px;
$common-border-radius: 12px;

$card-box-shadow: $common-box-shadow $box-shadow-color;
$card-padding-y: 32px;
$card-padding-x: 24px;
$card-border-radius: $common-border-radius;

:root {
    --color-primary: #{$primary-color};
    --color-secondary: #{$secondary-color};
    --color-tertiary: #{$tertiary-color};
    --color-card-background: #{$card-background-color};
    --color-background: #{$background-color};
    --color-success: #{$success-color};
    --color-disable: #{$disable-color};
    --color-error: #{$error-color};
    --color-warning: #{$warning-color};
    --color-box-shadow: #{$box-shadow-color};
    --color-rest-border: #{$border-rest-color};
    --color-info: #{$info-color};
    --common-border-style: #{$common-border-style};
    --common-border-width: #{$common-border-width};
    --common-box-shadow: #{$common-box-shadow};
    --common-border-radius: #{$common-border-radius};
    --toastify-color-info: #{$primary-color};
    --toastify-color-success: #{$success-color};
    --toastify-color-warning: #{$warning-color};
    --toastify-color-error: #{$error-color};
}
