.newFilter .react-datepicker__input-container,
.newFilter .react-datepicker-wrapper,
.newFilter .newFilterDatePicker,
.tablePL .newFilterDatePicker {
    width: 100%;
}

.tablePL .newFilterDatePicker,
.tablePL .numberInput {
    min-width: 120px;
}

.newFilter .newFilterDatePicker,
.tablePL .newFilterDatePicker {
    line-height: 34px;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid #e4e6ef;
}

.newFilter input::placeholder {
    color: #808080 !important;
    font-weight: 500;
    margin: 10px auto;
}
#newFilterMenu > [class^="css-"] {
    z-index: 999 !important;
}

.dropdownNewFilter .react-datepicker-popper {
    transform: translate3d(-17px, 38px, 0px) !important;
}

.dropdownNewFilter .react-datepicker-popper {
    transform: translate3d(-67px, 38px, 0px) !important;
}

.dropdownNewFilter .react-datepicker__triangle {
    left: 120px;
}

.selectInputNewFilter__placeholder {
    color: $primary-color;
    font-size: 0.875rem !important;
    opacity: 0.8 !important;
}

.selectInputNewFilter__input {
    color: $primary-color !important;
}

.css-1wa3eu0-placeholder {
    color: $primary-color !important;
}

.newFilter .actionSelect {
    width: 100%;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #e4e6ef;
}

.css-1uccc91-singleValue {
    margin-left: 5px !important;
}

.dropdownNewFilter {
    max-height: 455px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 12px;
}

.selectInputNewFilter__option--is-focused {
    cursor: pointer;
    background-color: $card-background-color !important;
    padding: 0 !important;
    // padding: 4px 25px  !important;
}
.selectInputNewFilter__option {
    cursor: pointer;
    padding: 0 !important;
    color: $primary-color !important;
    border-radius: 12px;
    // margin: 10px auto;
    // padding: 4px 25px  !important;
    // transition-duration: 0.3s;
    //background: $primary-color !important;
    label {
        margin-bottom: 0.4rem !important;
        margin-top: 0.4rem !important;
        padding-left: 16px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    input[type="checkbox"] {
        border: none;
        background: $card-background-color !important;
        color: $card-background-color !important;
        height: 15px;
        width: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        // border: 1px solid white;
        border-radius: 4px;
        outline: none;
        // transition-duration: 0.3s;
        cursor: pointer;
        text-align: center;
        position: relative;
        left: 13px;
        top: 0.2rem;
    }
    input[type="checkbox"]:checked {
        border: none;
        background: $primary-color !important;
        color: $card-background-color !important;
        border: $common-border-style $primary-color;
        content: "\2713";
        // display: block;
    }
    input:active {
        border: $common-border-style $primary-color;
    }
    &:hover {
        // transition-duration: 0.3s;
        background: $primary-color !important;
        color: $card-background-color !important;
        input[type="checkbox"] {
            border: none;
            background: $primary-color !important;
            color: $card-background-color !important;
            height: 15px;
            width: 15px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: $common-border-style $primary-color;
            border-radius: 4px;
            outline: none;
            // transition-duration: 0.3s;
            cursor: pointer;
        }
    }
}

.selectInputNewFilter__option label {
    width: 91%;
    font-size: 0.875rem;
    font-weight: normal;
    //padding: 4px 4px  !important;
    padding-left: 16px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    cursor: pointer;
}

.selectInputNewFilter__option--is-selected {
    color: $card-background-color !important;
    background-color: $primary-color !important;
    font-size: 0.875rem;
    //font-weight: normal;
    // padding: 4px 25px  !important;
    padding: 0 !important;

    input[type="checkbox"] {
        border: none;
        //background: $primary-color !important;
        color: $card-background-color !important;
    }
    input[type="checkbox"] {
        border: none;
        background: $primary-color !important;
        color: $card-background-color !important;
        height: 15px;
        width: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: $common-border-style $primary-color;
        border-radius: 4px;
        outline: none;
        // transition-duration: 0.3s;
        cursor: pointer;
    }
    input[type="checkbox"]:checked {
        border: none;
        background: $primary-color !important;
        color: $card-background-color !important;
        content: url("/media/svg/popleads/check_mark.svg");
        // display: block;
        text-align: center;
        // position: absolute;
        // left: 0.7rem;
        // top: 0.2rem;
    }
    &:hover {
        background: $primary-color !important;
        color: $card-background-color !important;
    }
}

.selectInputNewFilter__control {
    min-height: 44px !important;
    box-shadow: none !important;
    cursor: pointer;
    border-radius: 12px !important;
    border: $common-border-style $primary-color !important;
    transition: all 0.2s;
    // margin: 15px 0px;
}
.css-6q0nyr-Svg {
    transition: all 0.2s;
    transform: rotate(0deg);
}
.css-yk16xz-control {
    border-radius: 12px !important;
    border: $common-border-style $primary-color !important;
    transition: all 0.2s;
}

.selectInputNewFilter__control--is-focused {
    min-height: 44px !important;
    border-color: hsl(0, 0%, 70%) !important;
    border-radius: 12px !important;
    border: $common-border-style $primary-color !important;
    .css-6q0nyr-Svg {
        transform: rotate(-90deg);
    }
}

.selectInputNewFilter__menu {
    padding: 5px;
    padding-left: 0;
    border-radius: 12px !important;
    background-color: $card-background-color;
    border: $common-border-style $primary-color;
}

.selectInputNewFilter__menu-list {
    // padding: 10px;
    padding: 0 !important;

    border-radius: 20px;
    overflow-y: auto !important;
    border-radius: 12px !important;
    background-color: $card-background-color;
    > div {
        margin-top: 4px !important;
        margin-right: 7px !important;
        margin-left: 7px !important;
        min-height: 34px !important;
    }
    &:focus {
        background: $primary-color !important;
        color: $card-background-color !important;
    }
}

.selectInputNewFilter__menu-list-multi {
    background-color: $primary-color;
    border-radius: 12px !important;

    &:hover {
        background-color: $primary-color;
    }
}

#newFilter {
    padding: 18px;

    > .row {
        .navi-item {
            .navi-link {
                padding: 0;
                &:hover {
                    background: transparent;
                }
                .selectInputNewFilter__control {
                    border: 2px solid $border-rest-color !important;
                    cursor: pointer;
                    .selectInputNewFilter__value-container {
                        .selectInputNewFilter__placeholder,
                        .selectInputNewFilter__single-value {
                            color: $secondary-color !important;
                            font-size: 14px !important;
                            font-weight: 400 !important;
                        }
                    }
                    .selectInputNewFilter__indicators {
                        .selectInputNewFilter__dropdown-indicator,
                        .selectInputNewFilter__clear-indicator {
                            > svg > path {
                                fill: $secondary-color !important;
                            }
                        }
                    }
                }
                .selectInputNewFilter__menu {
                    .selectInputNewFilter__option {
                        display: flex;
                        align-items: center;
                        font-size: 14px !important;
                        color: $primary-color;
                        cursor: pointer;
                        font-weight: normal;
                        input[type="checkbox"] {
                            left: 8px;
                            top: 0;
                        }
                    }
                }
                .subHeaderForm {
                    padding-right: 0;

                    .subHeaderFilter__control {
                        border: 2px solid $border-rest-color !important;
                        background-color: transparent;
                        cursor: pointer;
                        margin: 5px;
                        min-height: 44px !important;
                        width: 100%;
                        .subHeaderFilter__value-container {
                            .subHeaderFilter__placeholder {
                                color: $secondary-color !important;
                                font-size: 14px !important;
                                font-weight: 400 !important;
                            }
                        }
                        .subHeaderFilter__indicators {
                            .subHeaderFilter__dropdown-indicator,
                            .subHeaderFilter__clear-indicator {
                                > svg > path {
                                    fill: $secondary-color !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.selectInputNewFilter__value-container {
    border-radius: 12px;
    padding: 12px;
}
.selectInputNewFilter__dropdown-indicator > svg > path,
.selectInputNewFilter__clear-indicator > svg > path {
    fill: $primary-color !important;
}

/*
    @@ SECONDARY SEARCH INPUT
    
*/
.selectInputNewFilterSecondary__control {
    min-height: 44px !important;
    background-color: $primary-color !important;
}
.selectInputNewFilterSecondary__placeholder {
    color: $card-background-color !important;
    font-size: 0.875rem !important;
}

.selectInputNewFilterSecondary__input {
    color: $card-background-color !important;
}

.selectInputNewFilterSecondary__option--is-focused {
    background-color: $primary-color !important;
    // padding: 4px 25px  !important;
    padding: 0 !important;

    input[type="checkbox"] {
        border: none;
        background: $primary-color !important;
        color: $card-background-color !important;
        height: 15px;
        width: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        // border: 1px solid white;
        border-radius: 4px;
        outline: none;
        // transition-duration: 0.3s;
        cursor: pointer;
    }
}
.selectInputNewFilterSecondary__option {
    color: $card-background-color !important;
    border-radius: 12px;
    padding: 0 !important;

    // margin: 10px auto;
    // padding: 4px 25px  !important;
    // transition-duration: 0.3s;
    //background: $primary-color !important;
    label {
        margin-bottom: 0.4rem !important;
        margin-top: 0.4rem !important;
        padding-left: 16px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    input[type="checkbox"] {
        border: none;
        background: $primary-color !important;
        color: $card-background-color !important;
        height: 15px;
        width: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        // border: 1px solid white;
        border-radius: 4px;
        outline: none;
        // transition-duration: 0.3s;
        cursor: pointer;
    }
    input[type="checkbox"]:checked {
        border: none;
        background: $secondary-color !important;
        color: $card-background-color !important;
        border: $common-border-style $secondary-color;
        content: "\2713";
        // display: block;
        text-align: center;
        position: relative;
        left: 13px;
        top: 0.2rem;
    }
    input:active {
        border: $common-border-style $secondary-color;
    }
    &:hover {
        // transition-duration: 0.3s;
        background: $secondary-color !important;
        color: $card-background-color !important;
        input[type="checkbox"] {
            border: none;
            background: $secondary-color !important;
            color: $card-background-color !important;
            height: 15px;
            width: 15px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: $common-border-style $secondary-color;
            border-radius: 4px;
            outline: none;
            // transition-duration: 0.3s;
            cursor: pointer;
        }
    }
}

.selectInputNewFilterSecondary__option label {
    width: 91%;
    font-size: 0.875rem;
    font-weight: normal;
    // margin: 0 10px;
    color: $card-background-color !important;

    padding-left: 16px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 34px;

    // padding: 4px 4px  !important;
}

.selectInputNewFilterSecondary__option--is-selected {
    // padding: 4px 25px  !important;
    padding: 0 !important;
    color: $card-background-color !important;
    background-color: $secondary-color !important;
    font-size: 0.875rem;
    //font-weight: normal;

    input[type="checkbox"] {
        border: none;
        //background: $primary-color !important;
        color: $card-background-color !important;
    }
    input[type="checkbox"] {
        border: none;
        background: $secondary-color !important;
        color: $card-background-color !important;
        height: 15px;
        width: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: $common-border-style $secondary-color;
        border-radius: 4px;
        outline: none;
        // transition-duration: 0.3s;
        cursor: pointer;
    }
    input[type="checkbox"]:checked {
        border: none;
        background: $secondary-color !important;
        color: $card-background-color !important;
        content: url("/media/svg/popleads/check_mark.svg");
        // display: block;
        text-align: center;
        // position: absolute;
        // left: 0.7rem;
        // top: 0.2rem;
    }
    &:hover {
        background: $secondary-color !important;
        color: $card-background-color !important;
    }
}

.selectInputNewFilterSecondary__control {
    min-height: 44px !important;
    box-shadow: none !important;
    cursor: pointer;
    border-radius: 12px !important;
    border: $common-border-style $primary-color !important;
    transition: all 0.2s;
}
.css-6q0nyr-Svg {
    transition: all 0.2s;
    transform: rotate(0deg);
}
.css-yk16xz-control {
    border-radius: 12px !important;
    border: $common-border-style $primary-color !important;
    transition: all 0.2s;
}

.selectInputNewFilterSecondary__control--is-focused {
    min-height: 44px !important;
    border-color: hsl(0, 0%, 70%) !important;
    border-radius: 12px !important;
    border: $common-border-style $primary-color !important;
    .css-6q0nyr-Svg {
        transform: rotate(-90deg);
    }
}

.selectInputNewFilterSecondary__menu {
    // padding: 10px;
    border-radius: 12px !important;
}
.selectInputNewFilterSecondary__menu-list {
    // padding: 10px;
    padding: 0 !important;
    border-radius: 20px;
    overflow-y: auto !important;
    border-radius: 12px !important;
    border: $common-border-style $primary-color;
    background-color: $primary-color;
    border-radius: 12px !important;
    color: $card-background-color;
    > div {
        margin-top: 4px !important;
        margin-left: 7px !important;
        margin-right: 7px !important;
        min-height: 34px !important;
    }
}

.selectInputNewFilterSecondary__menu-list-multi {
    background-color: $primary-color;
    border-radius: 12px !important;
    color: $card-background-color;

    &:hover {
        background-color: $secondary-color;
    }
}

#newFilter {
    .navi-text {
        border-radius: 12px;
    }
}
.selectInputNewFilterSecondary__value-container {
    border-radius: 12px;
    padding: 12px;
}

.selectInputNewFilterSecondary__dropdown-indicator > svg > path,
.selectInputNewFilterSecondary__clear-indicator > svg > path {
    fill: white !important;
}

/*
    @@ SECONDARY SEARCH INPUT END
    
*/

.selectInputNewFilter_single {
    width: 100%;
    .selectInputNewFilter__menu-list > div {
        margin: 5px !important;
        min-height: 0 !important;
    }

    .selectInputNewFilter__option {
        padding: 6px !important;
        width: auto !important;
    }
}

.selectInputNewFilterC,
.selectInputNewFilter_single {
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $card-background-color;
        border-radius: 12px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $primary-color;
        opacity: 0.7;
        border-radius: 12px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        opacity: 1;
    }
}

.selectInputNewFilter__control {
    input[type="checkbox"] {
        -moz-appearance: none;
        -o-appearance: none;
        -webkit-appearance: none;
        // border: 1px solid white;
        // transition-duration: 0.3s;
        appearance: none;
        background: #ffff !important;
        border-radius: 4px;
        border: none;
        color: transparentize($secondary-color, 0.3) !important;
        content: url("/media/svg/popleads/check_mark_lighter.svg");
        cursor: pointer;
        height: 15px;
        outline: none;
        width: 15px;
    }
    input[type="checkbox"]:checked {
        // display: block;
        // left: 13px;
        // position: relative;
        // top: 0.2rem;
        background: $card-background-color !important;
        border: none;
        color: $secondary-color !important;
        content: url("/media/svg/popleads/check_mark_darker.svg");
        text-align: center;
    }
    input:active {
        border: 0;
    }
    input[type="checkbox"]:hover {
        // transition-duration: 0.3s;
        background: $card-background-color !important;
        color: $secondary-color !important;
        input[type="checkbox"] {
            border: none;
            background: $secondary-color !important;
            color: $card-background-color !important;
            height: 15px;
            width: 15px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: $common-border-style $secondary-color;
            border-radius: 4px;
            outline: none;
            // transition-duration: 0.3s;
            cursor: pointer;
        }
    }
}

.selectGroupInput {
    padding: 5px 0px;
    &:hover {
        background-color: $secondary-color !important;
        color: $card-background-color !important;
        border-radius: 12px;
        cursor: pointer;
        .selectInputNewFilter__group-heading {
            color: $card-background-color !important;
        }
        input[type="checkbox"] {
            background-color: $secondary-color !important;
            &:hover {
                background-color: $secondary-color !important;
            }
        }
    }
    input[type="checkbox"] {
        -moz-appearance: none;
        -o-appearance: none;
        -webkit-appearance: none;
        // border: 1px solid white;
        // transition-duration: 0.3s;
        appearance: none;
        background: $card-background-color !important;
        border-radius: 4px;
        border: none;
        color: transparentize($secondary-color, 0.3) !important;
        // content: url("/media/svg/popleads/check_mark_lighter.svg");
        content: "-";
        cursor: pointer;
        height: 15px;
        outline: none;
        width: 15px;
    }
    input[type="checkbox"]:checked {
        background: $secondary-color !important;
        border: none;
        color: $secondary-color !important;
        content: url("/media/svg/popleads/check_mark.svg");
        text-align: center;
        position: relative;
        top: 5px;
        left: 3px;
        &:hover {
            background-color: $secondary-color;
        }
    }

    input[type="checkbox"]:hover:checked {
        background-color: $secondary-color;
    }

    input:active {
        border: 0;
    }
    input[type="checkbox"]:hover {
        // transition-duration: 0.3s;
        background: $card-background-color !important;
        color: $secondary-color !important;
        input[type="checkbox"] {
            border: none;
            background: $card-background-color !important;
            color: $card-background-color !important;
            height: 15px;
            width: 15px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: $common-border-style $secondary-color;
            border-radius: 4px;
            outline: none;
            // transition-duration: 0.3s;
            cursor: pointer;
        }
    }
}

.selectInputNewFilter__group {
    // &:nth-child(1){
    //     padding-bottom: 3px;
    // }
    & div {
        padding-bottom: 2px;
        padding-top: 1px;
        cursor: pointer;
    }
}
.selectInputNewFilter__group-heading {
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    color: $secondary-color !important;
    padding-bottom: 2px;
}
.groupSelected {
    background-color: $secondary-color !important;
    color: #ffff !important;
    border-radius: 12px !important;
    .selectInputNewFilter__group-heading {
        color: #ffff !important;
    }
}
