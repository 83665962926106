.surveyHomeGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.trainer-img-line {
    line-height: 60px !important;
}


.survey-title {
    margin-bottom: 23px;
}


.selectRatingType {
    cursor: pointer;
    margin: 20px;
    border-radius: 12px;
    box-shadow: $common-box-shadow $box-shadow-color;
    width: 14.5vw;
    min-width: max-content;
    height: 15vh;
    svg {
        width: 30px !important;
        height: 29px !important;
    }
}

.surveyHomeGridItem {
    background: white;
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 25px 40px;
}
.surveyHomeGridItem_Header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.surveyHomeGridItem_Icon {
    // width: 80px;
    // height: 80px;
    background: #eee5ff;
    border-radius: 6px;
    // margin-top: 35px;
    // display: flex;
    // justify-content: flex-start;
}

.surveyHomeGridItem_Title {
    font-size: 1rem;
    max-height: 72px;
    margin-top: 15px;
    margin-left: 16px;
    text-align: left;
    padding: 0 20px;
    font-weight: 600;
    color: $secondary-color;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.coursesHomeGridItem_Title{
    @extend .surveyHomeGridItem_Title;
    margin: 0;
}

.surveyHomeGridItem_Infos {
    margin-top: 16px;
    // display: grid;
    // grid-template-columns: repeat(1, 1fr);
    // grid-row-gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 35px;
    font-size: 14px;
    color: #446644;
    width: 100%;
    .surveyHomeGridItem_InfoText {
        color: $disable-color;
        font-size: 0.75rem;
        min-width: 7rem;
    }
}

.surveyHomeGridItem_Info {
    display: flex;
    align-items: center;
    //margin-right: 66px;
}

.surveyHomeGridItem_InfoIcon {
    width: 55px;
    height: 55px;
    background: #c9f7f5;
    border-radius: 10px;
    margin-right: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.surveyHomeGridItem_ViewButton {
    color: #b5b5c3;
    font-size: 14px;
    font-weight: 600;
    align-self: center;
}

.surveyAddInputLabel {
    color: $disable-color;
    font-size: 14px;
    font-weight: 600;
}

.textInputSurveyAdd {
    font-size: 20px;
    height: 52px;
    width: 622px;
}

.selectedSurveyType {
    background: transparentize($primary-color, 0.9);
    border: $common-border-style $primary-color;
}

.surveyTypeItem {
    padding: 25px;

    img {
        max-width: 100%;
    }
}

.question {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}
.questionInputSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -2px;
}

.surveyAddDeleteIcon {
    position: relative;
    top: 4px;
}

@media (max-width: 580px) {
    .question {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .questionInputSection {
        display: flex;
        align-items: center;
    }
    .surveyQuestionDropDownContainer {
        left: 1px !important;
        top: 1px !important;
    }
    .surveyAddDeleteIcon {
        position: relative;
        left: 29px;
    }
}

.questionInput {
    height: 38px !important;
    width: max-content;
}

.questionNumberTD,
.questionNumber {
    width: 2.5rem;
}

.questionNumber {
    color: $secondary-color;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
}
.questionText {
    height: 38px;
    min-width: 38px;
    line-height: 38px;
    font-size: 14px;
    color: $secondary-color;
    font-weight: 600;
}

.questionText {
    background: none;
    font-weight: 400;
}

.questionDeleteIcon {
    height: 38px;
    line-height: 38px;
    margin-left: 10px;
}

.questionDeleteIcon:hover {
    cursor: pointer;
}

.newButton.addQuestion {
    margin-left: 4.5rem;
}

.addSurveyWizard {
    margin: 30px;

    .wizardNavButtons {
        margin-top: 100px;
    }
}

#surveyDetailMenu {
    h2,
    .accountMenuButton_info {
        color: #464e5f;
    }
    .accountMenuButton_info {
        font-weight: 600;
    }
    .svg-icon svg g [fill] {
        transition: none;
        fill: #464e5f !important;
    }
    .menuActive {
        h2 {
            color: #265f9a !important;
        }

        .accountMenuButton_info {
            color: #0bb783 !important;
        }
        .svg-icon svg g [fill] {
            transition: none;
            fill: #265f9a !important;
        }
    }
}

.surveyNameInfo {
    color: #464e5f;
    font-size: 20px;
    line-height: 30px;
    height: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.surveyInfoTitle {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.surveyInfoDesc {
    font-size: 1.75rem;
    font-weight: 600;
    margin-top: -5px;
}

.surveyAddIcon {
    margin-right: 25px;
    margin-left: 10px;
}

.campaignListSurveyTable {
    margin: 0 !important;
    max-width: 100%;

    /*td.campaignName {
        font-size: 18px;
        min-width: 0 !important;
    }*/

    span.score {
        //font-size: 18px;
        font-weight: 600;
        background: #c9f7f5;
        padding: 5px 10px;
        border-radius: 5px;
        min-width: 0px !important;
    }

    td.trainer {
        font-weight: 600;
        min-width: 0px !important;
    }

    td.type,
    td.lastSendDate {
        //font-size: 14px;
        min-width: 0px !important;
    }

    td.score,
    th.score {
        min-width: 70px;
        width: 70px;
    }
    th.tableCounter,
    td.tableCounter {
        min-width: 40px !important;
        width: 45px !important;
        max-width: 50px !important;
    }
}

span.trainerNameTag {
    background: #f3f6f9;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    font-size: 20px;
}

span.trainerNameTag60 {
    width: 60px;
    height: 60px;
}

span.trainerNameTagAuto {
    width: auto;
    height: auto;
    padding: 5px;
}

.searchSurveys {
    .form-control {
        background: #f3f6f9;
        font-size: 18px;
        width: 300px;
    }
}

.searchDelegates {
    .form-control {
        background: #f3f6f9;
        font-size: 13px;
        width: 180px;
        height: 30px;
    }
}

.surveyCampaignDetailLink {
    color: #3f4254 !important;
}

.wizardNavNew {
    margin-bottom: 30px;
    button {
        background: #eff2ff;
        color: #6a83ff;
    }
}

.selectInputSurvey {
    background: #f3f6f9;
    width: 400px;
}

.campaignScore {
    background: #c9f7f5;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    min-width: 135px;
    min-height: 93px;
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
    border-radius: 12px;
    justify-content: center;
    text-align: center;

    .scoreTitle {
        font-size: 14px;
    }

    .scoreOverall {
        font-size: 36px;
        margin-top: -5px;
    }
}

.subTitlesTrainerName {
    font-size: 13px;
    font-weight: 600;
    color: #b5b5c3;

    span.trainerScore {
        background: #c9f7f5;
        color: #464e5f;
        padding: 3px;
        border-radius: 5px;
        margin-left: 20px;
        font-size: 14px;
    }
}

.subTitlesTrainer {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    span {
        background: #ececec;
        padding: 3px;
        border-radius: 5px;
        margin-left: 20px;
        font-size: 14px;
    }
}

.trainerName {
    font-size: 12px;
    font-weight: 600;
}

.delegateSelected {
    background: transparentize($success-color, 0.85);
}

.delegateInfo_name {
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10rem;
}

.delegateInfo_email {
    font-weight: 600;
    font-size: 13px;
    color: #b5b5c3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10rem;
}

.delegateInfo_title {
    font-weight: 600;
    font-size: 13px;
    color: #b5b5c3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10rem;
}
.tooltip > div.tooltip-inner {
    .delegateInfo_tooltip {
        white-space: nowrap;
    }
}
.tooltip.delegateInfo_tooltip > div.tooltip-inner {
    max-width: none !important;
}

.tooltip.show {
    opacity: 1 !important;
}
.delegateScore {
    width: 50px;
    min-width: 50px;
    padding-left: 10px;
    color: #b5b5c3;
    font-size: 12px;

    span {
        color: #464e5f;
    }
}

table.questionTable {
    overflow: auto;
    display: block;

    tbody {
        tr {
            border-bottom: $common-border-style $border-rest-color;
            &:last-child {
                border-bottom: none;
            }
        }
        .faceSelected {
            .surveyFace {
                font-size: 12px !important;
                letter-spacing: 0px;
                color: #56CCB4;
                border: $common-border-style $success-color;
                border-radius: $common-border-radius;
                box-shadow: 0px 3px 8px #3050731A;
            }
        }
        .questionNumberTD {
            vertical-align: baseline;
        }

        .resultColumn {
            // min-width: 75px;
            font-size: 12px !important;
            letter-spacing: 0px;
            color: #A6B7CA;
        }

        .questionTextTD {
            min-width: 150px;
        }

        td {
            color: $secondary-color;
            line-height: 1.5;
            font-size: 1rem;            
        }
    }

    .inputContainer {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 25px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .inputContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 22px;
        height: 20px;
        width: 20px;
        background-color: #f3f6f9;
        border-radius: 5px;
    }

    /* On mouse-over, add a grey background color */
    .inputContainer:hover input ~ .checkmark {
        background-color: #f3f6f9;
    }

    /* When the checkbox is checked, add a blue background */
    .inputContainer input:checked ~ .checkmark {
        background-color: #f3f6f9;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .inputContainer input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .inputContainer .checkmark:after {
        left: 7px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid #04aa77;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.footerButtons {
    color: #8f8f8f;
    font-size: 12px;
    font-weight: 600;
    justify-content: flex-end;
    display: flex;
    margin: 30px 0;

    div {
        border-radius: 10px;
        padding: 7px 14px;
        margin: 0 10px;
    }

    span {
        color: #464e5f;
        margin-left: 15px;
    }

    .finalAverageRating {
        background: #ffe1c7;
    }

    .usersTotalScore {
        background: #ebf8f3;
    }
}

.nameAndScore {
    min-width: 120px;
}

.contentAndOrganization {
    min-width: 140px;
}

.surveyHomeButton {
    border: 0;
    background: #eff2ff;
    font-size: 14px;
    color: #464e5f;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

@media (max-width: 991px) {
    .surveyHomeGrid {
        margin-top: 10px;
    }
}
.assignSurvey .addPrecampaignInput__menu {
    max-width: 460px;
}

@media (max-width: 1500px) and (min-width: 1100px) {
    .surveyHomeGrid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1099px) and (min-width: 700px) {
    .surveyHomeGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 699px) {
    .surveyHomeGrid {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 991px) {
    .surveyHomeGrid {
        margin-top: 10px;
    }

    div.textInputSurveyAdd {
        width: 60vw;
    }

    input.textInputSurveyAdd {
        width: 100%;
    }

    .selectRatingType {
        margin: 20px 0px;
    }

    .surveyTypeItem {
        padding: 15px 0px;
    }

    .addSurveyWizard {
        margin: 10px 0px;
        padding: 10px !important;

        .wizardContent {
            padding: 15px !important;
        }
    }

    .surveyAddIcon {
        margin-right: 10px;
    }

    .newButton.addQuestion {
        margin-right: 0px;
    }

    .surveyInfoTitle {
        line-height: 18px !important; 
    }

    .campaignScore {
        min-height: 0;
        height: 90px;
        margin: 0 0 0 auto;
        align-self: center;
    }

    .subTitlesTrainer {
        span {
            margin-left: 10px;
        }
    }

    .surveyNameInfo {
        font-size: 16px;
        line-height: 24px;
        max-height: 72px;
        height: auto;
        -webkit-line-clamp: 3;
    }

    .campaignScore {
        height: 70px;
        width: 90px;
        min-width: 90px;
    }

    .scoreOverall {
        font-size: 24px !important;
    }

    .surveyInfoDesc {
        font-size: 20px;
        margin: 0;
    }
}

.detailHeaderCart {
    height: 60px;
    width: 60px;
    min-width: 60px;
    background-color: #eff2ff;
    overflow: hidden;
    text-align: center;
    color: #7e8299;
    font-size: 40px;
    font-weight: 600;
    border-radius: 5px;
}
.detailHeaderCart img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.delegateImg {
    height: 65px;
    width: 65px;
    flex: 0 0 65px;
    background-color: #eff2ff;
    overflow: hidden;
    text-align: center;
    color: #7e8299;
    font-size: 30px;
    font-weight: 600;
    border-radius: 5px;
}
.delegateImg img {
    width: 65px;
    height: 65px;
    object-fit: contain;
}
.deg {
    background: #f3f6f9;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    font-size: 20px;
    overflow: hidden;
}
.deg img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}
.imgSpan {
    line-height: 65px;
}

.outlined-view-button {
    font-weight: 600;
    font-size: 0.875rem;
    color: $primary-color;
    margin-right: 10px;

    // > .btn:hover {
    //   background: darken($primary-color, 15%) !important;
    //   color: $card-background-color !important;
    //   border: 1px solid darken($primary-color, 15%) !important;
    //   width: inherit;
    //   height: inherit;
    // }
}

.surveyHomeDeleteButton {
    // > .btn:hover {
    //   background: $error-color !important;
    //   color: $card-background-color !important;
    //   border: 1px solid $error-color !important;
    //   width: inherit;
    //   height: inherit;
    //   svg [fill] {
    //     stroke: $card-background-color;
    //     // fill: $card-background-color;
    //   }
    // }
}

// .surveyInfoSection {
//     display: grid;
//     grid-template-columns: repeat(5, 1fr);
//     grid-template-rows: repeat(5, 1fr);
//     grid-column-gap: 0px;
//     grid-row-gap: 0px;

//     .ratingStyle { grid-area: 1 / 1 / 3 / 3; }
//     .ratings { grid-area: 3 / 1 / 6 / 3; }
//     .questions { grid-area: 1 / 3 / 6 / 6; }
// }

.surveyMenuActive {
    @extend .menuActive;
    background-color: #60cccb1a !important;
    box-shadow: 3px 0px 8px transparentize($success-color, 0.9);
    color: $success-color !important;
    &::after {
        height: 89% !important;
        width: 6px !important;
        top: 5px !important;
        left: 12px !important;
    }
    svg [fill] {
        stroke: $success-color;
    }
}

.surveyButton {
    @extend .wizardNavButtons;
    justify-content: center !important;
    max-width: max-content !important;
    // button {
    //   background-color: transparent !important;
    //   color: $secondary-color !important;
    //   border: $common-border-style $secondary-color !important;
    //   // width: none !important;
    // }
    // & > .border-none{
    //   border: 0 !important;
    //   color: $disable-color !important;
    // }
    .surveyCancelButton {
        background-color: transparent !important;
        color: $disable-color !important;
        border: 0;
    }
}

.surveyMenuItem {
    @extend .accountMenuItem;
    cursor: default !important;
    justify-content: center !important;
}

.surveyFormMenuActive {
    @extend .surveyMenuActive;
    background-color: transparent !important;
    box-shadow: none;
    cursor: default !important;
    &::after {
        height: 74px !important;
        width: 6px !important;
        top: 5px !important;
        left: 12px !important;
    }
}

#page1 {
    display: flex;
    flex-direction: column;
    width: 50vw;
    position: relative;
    left: 25%;
}
@media (max-width: 1400px) {
    #page1 {
        left: 0;
    }
    #page2 {
        left: 0;
    }
}

.surveyContentPart {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-self: center;
    .ratingTypes {
        display: flex;
        flex-direction: row;
        position: relative;
        left: -19px;
    }
    @media (max-width: 665px) {
        .ratingTypes {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            left: 10%;
            > .selectRatingType {
                min-width: 190px;
            }
        }
    }
    .inputSection {
        width: 35vw !important;
        input {
            width: 31vw !important;
            padding-right: 5px !important;
        }
    }
}

@media (max-width: 425px) {
    .surveyContentPart {
        justify-content: center;
        align-items: center;
    }
}

.surveyAddInput {
    background-color: transparent;
    border: $common-border-style $border-rest-color;
    box-shadow: $common-box-shadow $box-shadow-color;
    border-radius: 12px;
    .surveyInputWidth {
        width: 31vw !important;
    }
    .surveyInput {
        @extend .textInputNew;
        color: $secondary-color !important;
        border-radius: 12px !important;
        background-color: transparent !important;
        // width: 31vw !important;
        // padding: 0;
        // width: calc(100% -10px);
        &::placeholder {
            color: $secondary-color !important;
            font-size: 1rem;
        }
    }
    @media (max-width: 425px) {
        .surveyInput {
            width: 100% !important;
        }
    }
    .surveyAddIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 14px;
        padding-right: 12px;
        padding-bottom: 10px;
        padding-top: 10px;
        border-right: $common-border-style $border-rest-color;
        margin: 0;
    }
}

.courseAddInput{
    @extend .surveyAddInput;
    .inputWidth {
        @extend .surveyInput;
        width: 100% !important;
    }
}

@media (max-width: 425px) {
    .btndiv {
        flex-direction: column !important;
    }
    .surveyAddInput {
        width: 100% !important;
        min-width: 250px;
    }
    .assignBtn {
        margin-left: 0px !important;
        margin-top: 5px;
        width: 85%;
    }
    .searchForm {
        width: 85% !important;
    }
    .infoEmpty {
        margin-top: -20px !important;
    }
}
@media (min-width: $mobile-breakpoint) and  (max-width: 555px) {
    .btndiv {
        flex-direction: column !important;
    }
    .assignBtn {
        margin-left: 0px !important;
        margin-top: 5px;
        width: 70%;
    }
    .searchForm {
        width: 70% !important;
    }
    .infoEmpty {
        margin-top: -20px !important;
    }
}
@media (max-width: 555px) {
    .newButton.addQuestion {
        line-height: 18px;
    }
}
@media (max-width: 750px) {
    .surveyContentPart {
        .inputSection {
            width: 35vw !important;
            input {
                width: 88% !important;
            }
        }
    }
}

#page2 {
    @extend #page1;
    left: 22% !important;
}
@media (max-width: 1400px) {
    #page2 {
        left: 0 !important;
    }
}
@media (max-width: 425px) {
    // #page2 {
    //     left: 0 !important;
    //     right: 0 !important;
    //     top: 0 !important;
    //     bottom: 0 !important;
    // }
    #page1 {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.iconShadow {
    .icon-button2 {
        box-shadow: 3px 0px 8px $box-shadow-color;
    }
}
.precampaignsTableItem_Buttons button .icon-button3 svg g text{
    font-family: 'Poppins_PL';
    font-weight: 700;
    stroke: none;
}
select.addQuestionInputs {
    border: $common-border-style $secondary-color !important;
    border-radius: 12px;

    option {
        // color: white !important;
        // background-color: $secondary-color;
        border-radius: 12px !important;
        &:hover {
            background-color: $secondary-color !important;
            color: white !important;
        }
    }
}

.addQuestionInputs {
    @extend .textInputNew;
    color: $secondary-color;
    background-color: transparent !important;
    height: 44px !important;

    // & > option{
    //   color: $secondary-color;
    //   border-radius: 12px;
    //   padding: 2px;
    //   background-color: $secondary-color;

    // }

    input {
        height: 44px !important;
        background-color: transparent !important;
        border-radius: 12px !important;
        border: $common-border-style $secondary-color !important;
        min-width: 30vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        &::placeholder {
            color: $secondary-color;
            font-weight: normal;
            background-color: transparent;
        }
    }
}

.controlCheckMark {
    position: absolute;
    left: 0;
}

.surveyQuestionDropDownContainer {
    position: relative;
    // top: -5px;
    left: 16px;
    margin-left: 1.5rem;
    margin-right: 2rem;
}

.surveyQuestionDropDown__control {
    width: 20vw;
    max-width: 156px;
    border-radius: 12px;
    height: 44px;
    border: $common-border-style $secondary-color !important;
}

.surveyQuestionDropDow__placeholder {
    color: $secondary-color !important;
    font-size: 0.875rem !important;
}
.surveyQuestionDropDown__option--is-focused {
    background-color: $card-background-color !important;
    color: red !important;
    padding: 0 !important;
    // padding: 4px 25px  !important;
}
.surveyQuestionDropDown__option {
    padding: 0 !important;

    color: $primary-color !important;
    border-radius: 12px;
    // margin: 10px auto;
    // padding: 4px 25px  !important;
    // transition-duration: 0.3s;
    //background: $primary-color !important;
    label {
        margin-bottom: 0.4rem !important;
        margin-top: 0.4rem !important;
        padding-left: 16px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    input[type="checkbox"] {
        border: none;
        background: $card-background-color !important;
        color: $card-background-color !important;
        height: 15px;
        width: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        // border: 1px solid white;
        border-radius: 4px;
        outline: none;
        // transition-duration: 0.3s;
        cursor: pointer;
    }
    input[type="checkbox"]:checked {
        border: none;
        background: $primary-color !important;
        color: $card-background-color !important;
        border: $common-border-style $primary-color;
        content: "\2713";
        // display: block;
        text-align: center;
        position: relative;
        left: 13px;
        top: 0.2rem;
    }
    input:active {
        border: $common-border-style $primary-color;
    }
    &:hover {
        // transition-duration: 0.3s;
        background: $primary-color !important;
        color: $card-background-color !important;
        input[type="checkbox"] {
            border: none;
            background: $primary-color !important;
            color: $card-background-color !important;
            height: 15px;
            width: 15px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: $common-border-style $primary-color;
            border-radius: 4px;
            outline: none;
            // transition-duration: 0.3s;
            cursor: pointer;
        }
    }
}

.surveyQuestionDropDown__option label {
    width: 91%;
    font-size: 0.875rem;
    font-weight: normal;
    //padding: 4px 4px  !important;
    padding-left: 16px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.surveyQuestionDropDown__option--is-selected {
    color: $card-background-color !important;
    background-color: $primary-color !important;
    font-size: 0.875rem;
    font-weight: normal;
    // padding: 4px 25px  !important;
    padding: 0 !important;

    input[type="checkbox"] {
        border: none;
        //background: $primary-color !important;
        color: $card-background-color !important;
    }
    input[type="checkbox"] {
        border: none;
        background: $primary-color !important;
        color: $card-background-color !important;
        height: 15px;
        width: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: $common-border-style $primary-color;
        border-radius: 4px;
        outline: none;
        // transition-duration: 0.3s;
        cursor: pointer;
    }
    input[type="checkbox"]:checked {
        border: none;
        background: $primary-color !important;
        color: $card-background-color !important;
        content: url("/media/svg/popleads/check_mark.svg");
        // display: block;
        text-align: center;
        // position: absolute;
        // left: 0.7rem;
        // top: 0.2rem;
    }
    &:hover {
        background: $primary-color !important;
        color: $card-background-color !important;
    }
}
.surveyQuestionDropDown__single-value {
    color: $secondary-color !important;
    font-weight: 600;
}
.surveyQuestionDropDown__control {
    min-height: 44px !important;
    box-shadow: none !important;
    cursor: pointer;
    border-radius: 12px !important;
    border: $common-border-style $secondary-color !important;
    transition: all 0.2s;
    margin: 5px;
}
.css-6q0nyr-Svg {
    fill: $secondary-color !important;
    transition: all 0.2s;
    transform: rotate(0deg);
}
.css-yk16xz-control {
    border-radius: 12px !important;
    border: $common-border-style $secondary-color !important;
    transition: all 0.2s;
}


.surveyQuestionDropDown__control--is-focused {
    min-height: 44px !important;
    border-color: hsl(0, 0%, 70%) !important;
    border-radius: 12px !important;
    border: $common-border-style $primary-color !important;
    .css-6q0nyr-Svg {
        transform: rotate(-90deg);
        fill: $secondary-color !important;
    }
}

.surveyQuestionDropDown__menu {
    // padding: 10px;
    border-radius: 12px !important;
    background-color: $card-background-color;
}
.surveyQuestionDropDown__menu-list {
    // padding: 10px;
    padding: 0 !important;
    max-width: 156px;
    border-radius: 20px;
    overflow-y: hidden !important;
    border-radius: 12px !important;
    border: $common-border-style $primary-color;
    background-color: $card-background-color;
    > div {
        margin-top: 4px !important;
        margin-right: 7px !important;
        padding-left: 28px !important;
        min-height: 34px !important;
        display: flex !important;
        // justify-content: center !important;
        align-items: center !important;
        align-content: center !important;
    }
    &:focus {
        background: $secondary-color !important;
        color: $card-background-color !important;
    }
}

.surveyQuestionDropDown__menu-list-multi {
    background-color: $primary-color;
    border-radius: 12px !important;

    &:hover {
        background-color: $primary-color;
    }
}
.surveyAddErrorMessage {
    color: red;
    font-size: 14px;
    font-weight: 600;
}

@media (min-width: 665px) and (max-width: 1024px) {
    .surveyAddErrorMessage {
        position: relative;
        right: 50vw;
        bottom: -20vh;
        display: flex;
        flex-wrap: nowrap;
        min-width: max-content;
        height: max-content;
    }

    .ratingTypes {
        > div {
            margin-left: 30px;
        }
    }
}

.forMobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 450px) {
    .forMobile {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        float: left;
    }
    .forMobileIcon {
        position: absolute !important;
        right: 29px !important;
        bottom: 21px !important;
    }
    .surveyInfoDesc {
        margin-top: 2rem;
    }
}
.resend-icon {
    svg{
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 992px){
    .mobileDisplayNone > * {
        display: none       
    }
}
@media (min-width: 993px){
    .desktopDisplayNone > * {
        display: none
    }
}