@import "../../../../../../src/app/popleads/assets/css/design_2.0/variables";
$mobile-breakpoint: 576px;

.rdrDateRangePickerWrapper {
    height: auto;
    width: 100%;

    gap: 35px;
    @media (max-width: $mobile-breakpoint) {
        position: relative;
    }
    :global(.rdrDefinedRangesWrapper) {
        width: auto !important;
        height: 80%;
        scroll-snap-type: x proximity;
        scroll-snap-align: center;
        display: grid;
        background: transparent;
        border: none;
        @media (max-width: $mobile-breakpoint) {
            width: 100% !important;
            height: unset !important;
            position: absolute;
            left: 0;
        }
        :global(.rdrStaticRanges) {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            flex-wrap: wrap;
            width: max-content;
            gap: 8px;
            @media (max-width: $mobile-breakpoint) {
                flex-direction: row !important;
                flex-wrap: nowrap;
                overflow-x: auto;
                padding-bottom: 4px;
            }
            :global(.rdrStaticRange) {
                border: 2px solid $border-rest-color;
                border-radius: 8px;
                scroll-snap-align: center;
                background: $card-background-color;
                @media (max-width: $mobile-breakpoint) {
                    gap: 12px;
                }
                &:hover {
                    border-color: white;
                    :global(.rdrStaticRangeLabel) {
                        background: $primary-color;
                        color: $card-background-color;
                        border-radius: 5px;
                    }
                }
                &:global(.rdrStaticRangeSelected) {
                    border-color: $primary-color;
                    background: $primary-color;
                    :global(.rdrStaticRangeLabel) {
                        color: $card-background-color;
                    }
                }
                :global(.rdrStaticRangeLabel) {
                    white-space: nowrap;
                    padding: 4px 6px;
                    font-size: 12px;
                }
            }
        }
    }
    :global(.rdrCalendarWrapper) {
        font-size: 14px;
        background: transparent;
        padding: 0;
        width: 100%;
        max-width: 320px;
        @media (max-width: $mobile-breakpoint) {
            position: relative !important;
            left: 0 !important;
            margin-top: 56px !important;
            align-self: center;
        }
        :global(.rdrDateDisplayWrapper) {
            background-color: transparent;
            margin-bottom: 16px;
            @media (max-width: $mobile-breakpoint) {
                margin-bottom: 0;
            }
            :global(.rdrDateDisplay) {
                margin: 0;
                :global(.rdrDateDisplayItem) {
                    input {
                        padding: 4px 6px;
                        font-size: 12px;
                    }
                    box-shadow: none;
                    border: $common-border-style $border-rest-color;
                    background-color: transparent;
                    border-radius: 8px;
                    &:global(.rdrDateDisplayItemActive) {
                        border-color: $primary-color;
                    }
                }
            }
        }
        :global(.rdrMonthAndYearWrapper) {
            padding-top: 0;
            margin-bottom: 10px;
            :global(.rdrMonthAndYearPickers) {
                order: 1;
                justify-content: flex-start;
                background: none;
                :global(.rdrYearPicker),
                :global(.rdrMonthPicker) {
                    line-height: 14px;
                    margin-right: 30px;
                }
                select {
                    font-weight: 600;
                    background: none;
                    text-align: left;
                    padding: 0;
                }
            }
            :global(.rdrNextPrevButton) {
                background: transparent;
                position: relative;
                width: 18px;
                height: 18px;
                i {
                    border: solid $secondary-color;
                    border-width: 0 3px 3px 0;
                    padding: 5px;
                    border-radius: 0px 0px 2px 0px;
                    position: absolute;
                    top: calc(50% - 6px);
                }
            }
            :global(.rdrPprevButton) {
                order: 2;
                i {
                    // top: calc(50% - 12px);
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                }
            }
            :global(.rdrNextButton) {
                order: 3;
                i {
                    // top: calc(50% - 12px);
                    transform: rotate(-49deg);
                    -webkit-transform: rotate(-49deg);
                }
            }
        }
        :global(.rdrMonths) {
            :global(.rdrMonth) {
                width: 100%;
                padding: 0;
                :global(.rdrWeekDays) {
                    span {
                        color: $secondary-color !important;
                    }
                }
                :global(.rdrDays) {
                    gap: 5px 0;
                    :global(.rdrDay) {
                        aspect-ratio: 1;
                        height: auto;
                        @media (max-width: $mobile-breakpoint) {
                            aspect-ratio: 1.25;
                        }
                        &:not(:global(.rdrDayPassive)) {
                            :global(.rdrStartEdge),
                            :global(.rdrEndEdge),
                            :global(.rdrInRange) {
                                color: $primary-color !important;
                                inset: 0;
                            }
                            :global(.rdrDayNumber) {
                                inset: 0;
                            }
                            :global(.rdrStartEdge.rdrEndEdge) {
                                color: transparent !important;
                                border: $common-border-style $primary-color;
                                & ~ :global(.rdrDayNumber) {
                                    span {
                                        color: $primary-color !important;
                                    }
                                }
                            }
                            :global(.rdrDayStartPreview.rdrDayEndPreview) {
                                border: none;
                                inset: 0;
                            }
                        }

                        &:global(.rdrDayPassive) {
                            span {
                                color: $disable-color !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
