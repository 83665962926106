$mobile-breakpoint: 450px;
$tablet-breakpoint: 769px;
$bootstrap-xl-breakpoint: 1200px;
$readyfortesting-color: #db6671;
$development-color: #305073;
$readyfordevelopment-color: #6490bf;
$testing-color: #b93d48;
$readyforproduction-color: #7263bf;
.activityInput {
    height: 43px;
    background-color: transparent;
    border: $common-border-style $border-rest-color;
    box-shadow: $common-box-shadow $box-shadow-color;
    border-radius: 12px;
    color: $secondary-color;
    font-size: 1rem;
    padding: 0 1.5rem;
    width: 500px;

    &:focus {
        outline: none;
        border-color: $secondary-color;
    }
    &::placeholder {
        color: #8a9eb4;
    }
}

.dynamicSchemaInputs {
    .activityInput {
        width: auto;
    }
}
input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
}

.dynamicSchemaGrid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 16px;
    @media (max-width: $mobile-breakpoint) {
        grid-template-columns: minmax(0, 1fr);
    }
}
.dynamicSchemaMobile {
    grid-template-columns: 1fr;
}
.activityTextarea {
    @extend .activityInput;
    padding: 0.75rem 1.5rem;
    height: auto;
}

.activitySelectInput {
    .customSelectInput__control {
        padding: 0 calc(1.5rem - 8px) !important;
    }
}

.duration-inputs-wrapper {
    .DateInput_wrapper__2voDM,
    .react-datepicker-wrapper {
        width: 100%;
    }
}
.activityDatePicker {
    &::placeholder {
        color: #8a9eb4;
    }
}

.editableInput {
    background-color: transparent;
    border: $common-border-style $border-rest-color;
    box-shadow: $common-box-shadow $box-shadow-color;
    color: $secondary-color;
    border-radius: 4px;
    padding: 0 0.2rem;
    &:focus {
        outline: none;
        border-color: $primary-color;
    }
}

.editableTextArea {
    @extend .editableInput;
    border-radius: 6px;
    padding: 0.25rem;
}

.activity-custom-scroll {
    ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }
    ::-webkit-scrollbar-track {
        background-color: inherit;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #e0e0e0;
        border: 3px solid transparent;
        border-radius: 6px;
        background-clip: content-box;
        min-height: 32px;
        &:hover {
            background-color: #c0c0c0;
            border-width: 2px;
        }
    }
}

.kanban-header-wrapper {
    background-color: $card-background-color;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-right: auto;
    width: calc(100% - 8px);
    height: 62px;
    display: flex;
    p {
        margin-bottom: 0;
    }
}
.kanban-columns {
    display: flex;
    gap: 15px;
    width: 100%;
    height: 100%;
    // max-height: 100vh;
    padding-bottom: 4px;
    overflow-x: auto;
}
.kanban-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 310px;
    min-width: 310px;
    height: 100%;
}
.kanban-header-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
}

.kanban-card {
    user-select: none;
    margin: 0 0 8px 0;
    min-height: 50px;
    background-color: $card-background-color;
    font-family: $font-family-base;
    border-radius: 12px;
    box-shadow: 0px 3px 8px #3050731a;
    width: 100%;
    position: relative;
}
.kanban-card-radio-button {
    accent-color: #439e8b;
    width: 26px;
    height: 26px;
}
.custom-radio {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-radio .outer-circle {
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid $border-rest-color;
    border-radius: 50%;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    background-color: white;
}

.custom-radio .inner-circle {
    position: absolute;
    top: 8px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.3s ease;
}
.custom-radio input:checked ~ .inner-circle {
    border-color: $success-color;
    background-color: $success-color;
    transform: scale(1.25);
}
.custom-radio:hover .inner-circle {
    background-color: $success-color;
    transform: scale(1.25);
}

.isActivityOverdue {
    background-color: #fa95a01a !important;
    border: 1px solid #fa95a0;
}
.kanban-card-text {
    font-weight: $font-weight-bolder;
    letter-spacing: 0;
    color: $secondary-color;
    flex: 1;
    font-family: $font-family-base;
    max-width: 280px;
    word-wrap: break-word;
}
.kanban-card-body {
    border-bottom: 1px solid $border-rest-color;
}
.dragging {
    opacity: 0.8;
}
.kanban-card-header-colors {
    display: flex;
    gap: 4px;
}
.activity-status-color-bar {
    width: 24px;
    height: 6px;
    border-radius: 12px;
    content: "";
    //margin-right: 4px;
}
.approved-by-me {
    background-color: #f0cfb7;
}
.created-by-me {
    background-color: #fa95a0;
}
.assigned-to-me {
    background-color: #305073;
}
.followed-by-me {
    background-color: #92beed;
}
.kanban-card-footer {
    .accordion {
        max-width: 400px;
        border-radius: 3px;
        // background: $card-background-color;
    }

    .accordion-visible {
        color: $secondary-color;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 7px 15px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        user-select: all;
        z-index: 10;
    }
    .accordion-visible span {
        padding: 15px 0;
        font-size: 20px;
    }

    .accordion-toggle {
        max-height: 0;
        overflow: hidden;
        font-size: 18px;
        opacity: 0;
        transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
        color: #333;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        background-color: $background-color;
    }

    .animated {
        opacity: 1;
        border-top: 1.5px solid $border-rest-color;
        border-bottom: 1.5px solid $border-rest-color;
        transition: max-height 0.3s ease-in-out, opacity 0.3s 0.3s ease-in-out;
        svg {
            transform: rotate(0deg);
        }
    }
}
.transform {
    transform: rotate(180deg);
    transition: rotate 0.3s ease-in-out;
}
.kanban-accordion-item {
    padding: 16px 32px;
    position: relative;
    &:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 32px;
        right: 32px;
        height: 1px;
        background-color: $border-rest-color;
    }
}
.kanban-accordion-item-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.kanban-accordion-item-title {
    color: $secondary-color;
    font-weight: $font-weight-bolder;
    margin-bottom: 0;
    /// limit one line
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    overflow: hidden;
    max-width: 67%;
}

.kanban-accordion-item-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    p {
        color: $secondary-color;
        margin-bottom: 0;
    }
}
.assignee-wrapper {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $disable-color;
    transition: 0.1s;
    position: relative;
    font-size: 0.875rem;
    font-weight: 600;
    color: #305073;
    line-height: 1.5;
    text-align: start;
    letter-spacing: 0;
}

.activities-sidebar-wrapper {
    @extend .activity-custom-scroll;
    width: 100% !important;
    height: calc(100vh - 120px);
    position: relative;
    top: 0;
    margin: 0;
    padding: 0 12px;
    border-radius: 0px 12px 12px 0px;
    background-color: $card-background-color;
    // transition: width 0.4s ease-in-out;
    overflow-x: hidden;
    z-index: 11;

    // bootstrap-xl breakpoint
    @media only screen and (min-width: $bootstrap-xl-breakpoint) {
        width: 350px !important;
        height: calc(100vh - 120px);
        position: fixed;
        top: 110px;
        margin-left: -35px;
        padding: 24px;
    }

    .sidebar-content {
        // transition: all 0.4s ease-in-out;
        width: 100% !important;
        height: 100%;
        display: flex;
        flex-direction: column;

        // bootstrap-xl breakpoint
        @media only screen and (min-width: $bootstrap-xl-breakpoint) {
            width: 335px !important;
        }
    }
    .sidebar-body {
        flex: 1;
        overflow-y: auto;
        padding-right: 0.5rem;
        margin-right: -0.75rem;
    }

    .sidebar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sidebar-chevron-left {
        position: absolute;
        top: 32px;
        left: 12px;
        width: 24px;
        height: 24px;
        z-index: 30;
        display: none;
        // transition: all 0.4s ease-in-out;
        transform: rotate(180deg);
        cursor: pointer;

        // bootstrap-xl breakpoint
        @media (min-width: $bootstrap-xl-breakpoint) {
            display: block;
        }
    }

    &.sidebar-open {
        // transition: width 0.4s ease-in-out;
        padding-right: 16px;
        width: 350px;
        padding-left: 12px;
        padding-bottom: 8px;
        .sidebar-content {
            padding-right: 16px;
        }
    }

    &.sidebar-close {
        // transition: width 0.4s ease-in-out;
        width: 36px !important;
    }
}

.selected-tab {
    background-color: $success-color-light;
    border-radius: 12px;
}
////activities table
///
.activities-table {
    // overflow: hidden !important;
    border-radius: 12px;
}
.custom-assignee {
    button {
        border-radius: 50% !important;
        border: 1px dashed $border-rest-color;
        width: 32px !important;
        height: 32px !important;
        min-width: 32px !important;
        svg {
            width: 14px !important;
        }
    }
    text-decoration: none !important;
}
.due-date {
    margin-bottom: 0;
}

.sub-activity-icons {
    width: 200px;
}
.sub-activity-name {
    width: 175px;
}
// .sub-activity-campaign {
//     width: 300px;
// }
.rdt_ExpanderRow {
    background-color: #f5f7fa !important;
}
// .sub-activity-type {
//     width: 232px;
// }
.sub-activity-assignee {
    width: 166px;
}
.sub-activity-due-date {
    width: 175px;
}

.sub-activity-priority {
    width: 130px;
}
.sub-activity-more {
    width: 6%;
}

.sub-activity {
    &:not(:last-child) {
        border-bottom: 1px solid RGB(166, 183, 202, 0.3);
    }
}
.dropdown-menu {
    padding: 0;
}

.horizontal-seperator {
    height: 1px;
    width: 100%;
    background-color: $border-rest-color;
    margin: 16px 0;
}
.more-dropdown-divider {
    &::after {
        content: "";
        display: block;
        border-bottom: 1px solid #ccc;
    }
}
.delete-activity {
    color: $danger-color;
    cursor: pointer;
    text-decoration: underline;
}
.rotate-90 {
    transform: rotate(90deg);
}
.delete-activity-dropdown-item {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.drawer-body {
    margin: 12px 0;
    overflow-y: auto;
    overflow-x: hidden;
}
.activity-note {
    padding-bottom: 12px;
    p {
        margin-bottom: 0;
        word-break: break-word;
    }
}

.activity-note-wrapper:not(:last-child) {
    border-bottom: 1px solid $card-border-color;
    ///last child
}

.activity-note:last-child {
    margin-bottom: 0;
    // padding-bottom: 0;
}

.drawer-footer {
    background-color: $card-background-color;
}

.activity-drawer-wrapper {
    width: 0px;
    background-color: $card-background-color;
    right: 12px;
    position: fixed;
    top: 105px;
    height: calc(100vh - 120px);
    border-radius: 12px;
    overflow-y: auto;
    // transition: all 0.3s ease-in-out;
    z-index: 15;
    margin-left: 24px;

    @extend .activity-custom-scroll;
}
.height-550px {
    height: 550px;
}
.height-500px {
    height: 500px;
}
.activity-notes-wrapper {
    ///add margin to scrollbar
    /// add margin to scrollbar
    margin-right: 10px;
}
.activity-detail-modal {
    @extend .activity-custom-scroll;
    .drawer-footer {
        background-color: $background-color;
        border-bottom-right-radius: 12px;
    }
    .details {
        background-color: $card-background-color;
        border-radius: 12px 0 0 12px;
    }
    .notes {
        background-color: $background-color;
        border-radius: 0 12px 12px 0;
    }
}
.select-button {
    width: 140px;
}
.activity-detail-active-tab {
    background-color: #60cccb1a;
    color: $success-color;
    border-radius: 12px;
}

.active-tab-bar {
    content: "";
    width: 3px;
    height: 30%;
    background-color: $success-color;
    border-radius: 12px;
    position: absolute;
}
.activity-detail-checkbox {
    input[type="checkbox"] {
        accent-color: $primary-color;
        width: 18px;
        height: 18px;
    }
}

.document-type-icon {
    fill: $error-color;
    width: 20px;
    height: 20px;
}
.activity-note-documents:hover .document-hover {
    opacity: 1;
}

.document-hover {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
// .activity-table {
//     height: calc(100vh - 500px);
//     overflow-y: auto;
// }
///table title
.activity-table-title {
    color: $disable-color;
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    // background-color: $card-background-color;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    @media (max-width: 767px) {
        font-size: 18px;
    }
}

// .activities-table-page-wrapper {
//     // height: calc(100vh - 165px);
// }

.activity-table-title-mobile {
    padding: 12px;
    font-size: 14px;
    font-weight: 600;
}
.activity-table-mobile {
    padding: 0 24px 24px;
    .custom-mobile-actions {
        justify-content: space-between;
        svg,
        rect,
        path,
        circle,
        line,
        g {
            stroke: $disable-color;
        }
    }
    .mobile-activity-table-move-icon .g {
        fill: $disable-color;
    }
    .mobileExpandedBox {
        &.open {
            background-color: #f5f7fa;
        }
    }
}

.text-decoration-line-through {
    text-decoration: line-through;
}

///table body
.activity-table-wrapper {
    border-radius: 12px;
    box-shadow: 0px 3px 8px #3050731a;
    background-color: $card-background-color;
}
.sub-activities-table {
    overflow-y: auto !important; // To show dropdowns of sub-activites
    .rdt_TableHeadRow {
        display: none;
    }
    .rdt_TableRow {
        background-color: $background-color;
    }
}

.activity-status-priority {
    background-color: $card-background-color;
    box-shadow: 0px 3px 8px #ca8a5e1a;
    border-radius: 12px;
    padding: 6px 12px;
    font-size: 12px;
    white-space: nowrap;
    border: 2px solid;

    font-weight: $font-weight-bolder;
    width: fit-content;
    height: fit-content;
    &.todo,
    &.low {
        color: $tertiary-color;
        border: 2px solid $border-rest-color;
    }
    &.inprogress,
    &.high {
        color: $warning-color;
        border: 2px solid $warning-color;
    }
    &.completed {
        color: $success-color;
        border: 2px solid $success-color;
    }
    &.normal {
        color: $secondary-color;
        border: 2px solid $secondary-color;
    }
    &.urgent {
        color: $error-color;
        border: 2px solid $error-color;
    }
    &.testing {
        color: $testing-color;
    }
    &.readyfortesting {
        color: $readyfortesting-color;
    }
    &.development {
        color: $development-color;
    }
    &.readyfordevelopment {
        color: $readyfordevelopment-color;
    }
    &.readyforproduction {
        color: $readyforproduction-color;
    }
}

.fill-danger {
    path,
    polyline {
        stroke: $error-color !important;
    }
}
.fill-disable {
    path,
    polyline,
    g,
    circle {
        stroke: $disable-color !important;
    }
}

.activity-mini-tag {
    background-color: $card-background-color;
    border-radius: 6px;
    padding: 0px 4px;
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 18px;
    .tag-name {
        max-width: 100px;
        display: block;
        overflow: hidden;
    }
}
.btn-close {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #c0cbd8;
    color: #305073;
    width: 30px;
    height: 30px;
    outline: 0;
    background: transparent;
    overflow: hidden;
    padding: 0;
    border-radius: 10px;
    svg {
        transform: rotate(45deg);
    }
}
.activity-tag {
    background-color: $card-background-color;
    border-radius: 12px;
    padding: 4px 8px;
    font-size: 0.75rem;
    font-weight: 600;
    height: 29px;
    // svg {
    //     display: inline-block;
    //     width: 18px;
    //     height: 18px;
    //     path {
    //         stroke: #fcfcfc;
    //     }
    // }
    // &.selected {
    //     background-color: $card-background-color;
    //     color: #34c5cb;
    //     svg {
    //         height: 20px;
    //         width: 20px;
    //     }
    // }
    .tag-name {
        max-width: 100px;
        display: inline-block;
        overflow: hidden;
        word-break: normal;
    }
    .activity-cross-icon {
        display: inline-grid;
        svg {
            path {
                stroke: white;
            }
        }
    }
    &.add {
        color: $tertiary-color;
        border-color: $tertiary-color;
        svg {
            display: inline-block;
            width: 10px;
            height: 10px;
            path {
                stroke: $tertiary-color;
            }
        }
    }
}

.more-dropdown-item {
    svg {
        path,
        circle,
        line {
            stroke: $secondary-color;
        }
    }
}
/////mind map
.edgebutton {
    width: 24px;
    height: 24px;
    background: white;
    border: 1px solid $border-rest-color;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
}

.edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}
.sc-bcXHqe.kVrXuC.rdt_Table {
    background-color: $card-background-color;
}
.edgebutton-foreignobject div {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
}
.edgebutton-foreignobject {
    border-radius: 5px;
}

.custom-activity-node {
    background-color: $card-background-color;
    padding: 10px;
    border-radius: 12px;
    border: 2px solid $border-rest-color;

    svg:nth-child(3) {
        path,
        line,
        circle {
            stroke: gray;
        }
    }
}
.react-flow__handle.react-flow__handle-left.nodrag.nopan.target.connectable {
    opacity: 0;
}
.react-flow__handle.react-flow__handle-right.nodrag.nopan.source.connectable {
    opacity: 0;
}

.react-flow__pane {
    top: 30px !important;
}

.zoom-panel {
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
    width: 160px;
    height: 60px;
    border-radius: 12px;
    background-color: $card-background-color;
    @media (max-width: $mobile-breakpoint) {
        bottom: 1rem;
    }
}

.custom-assignee-dropdown {
    min-width: 300px;
    max-width: 300px;
    max-height: 400px;
    overflow: auto;
    z-index: 999;
}

.activity-type-dropdown {
    @extend .activity-custom-scroll;
    max-height: 60vh;
    overflow-y: auto;
}

.readyfordevelopment {
    #Ellipse_1 {
        stroke: $readyfordevelopment-color;
    }
    #Ellipse_41 {
        fill: $readyfordevelopment-color;
    }
}
.development {
    #Ellipse_1 {
        stroke: $development-color;
    }
    #Ellipse_41 {
        fill: $development-color;
    }
}
.readyfortesting {
    #Ellipse_1 {
        stroke: $readyfortesting-color;
    }
    #Ellipse_41 {
        fill: $readyfortesting-color;
    }
}
.testing {
    #Ellipse_1 {
        stroke: $testing-color;
    }
    #Ellipse_41 {
        fill: $testing-color;
    }
}
.readyforproduction {
    #Ellipse_1 {
        stroke: $readyforproduction-color;
    }
    #Ellipse_41 {
        fill: $readyforproduction-color;
    }
}
.todo,
.low {
    #Ellipse_1 {
        stroke: $tertiary-color;
    }
    #Ellipse_41 {
        fill: $tertiary-color;
    }
}
.inprogress,
.high {
    #Ellipse_1 {
        stroke: $warning-color;
    }
    #Ellipse_41 {
        fill: $warning-color;
    }
}
.completed {
    #Ellipse_1 {
        stroke: $success-color;
    }
    #Ellipse_41 {
        fill: $success-color;
    }
}
.normal {
    #Ellipse_1 {
        stroke: $secondary-color;
    }
    #Ellipse_41 {
        fill: $secondary-color;
    }
}
.urgent {
    #Ellipse_1 {
        stroke: $error-color;
    }
    #Ellipse_41 {
        fill: $error-color;
    }
}

.priority-dropdown-menu .dropdown-item:hover {
    border-radius: 4px;
}
.priority-dropdown-menu.dropdown-menu.show {
    border-radius: 4px;
}
.layoutflow {
    width: 100%;
    height: calc(100vh - 106px);
}
.add-activity-button {
    background-color: $card-background-color !important;
    border-radius: 50% !important;
    //width
    width: 24px !important;
    height: 24px !important;
    svg {
        width: 14px;
        height: 14px;
        path {
            stroke: $success-color;
        }
    }
}
.custom-node-bottom {
    position: absolute;
    svg {
        line {
            stroke: #b7b8be;
            stroke-width: 2px;
        }
    }
}
.connect-x {
    margin-top: 2px;
    svg {
        line {
            stroke: #b7b8be;
            stroke-width: 3px;
        }
    }
}

.switch-buttons-wrapper {
    width: 360px;
    height: 60px;
    background-color: var(--color-card-background);
    border-radius: 12px;
    position: absolute;
    z-index: 10;
    right: 0;
}
.assignee-row:not(:last-child) .selected-follower {
    border-bottom: 1px solid $border-rest-color;
}
.assignee-row:hover .document-hover {
    opacity: 1;
}
.select-follower-dropdown {
    width: 290px;
}

.activity-proposal-item:not(:last-child) {
    border-bottom: 1px solid $disable-color;
}
.kanban-header-mobile {
    border-radius: 12px;
    height: 108px;
    width: 200px;
    background-color: $card-background-color;
}
.kanban-header-mobile-text {
    color: $info-color;
}
.kanban-accordion-mobile-date {
    font-size: 16px !important;
}

.mt-7 {
    .slick-dots {
        bottom: -16px !important;
    }
}
// .drawer-due-date {
//     .rdrMonthAndYearWrapper {
//         margin-top: -30px;
//     }
//     span.rdrMonthAndYearPickers {
//         margin-left: 25px;
//     }
//     // .DateInput_wrapper__2voDM,
//     // .react-datepicker-wrapper {
//     //     width: 100%;
//     // }
// }
.add-activity-assignee {
    .d-flex.flex-column.mt-4.mb-2 {
        margin-top: 0 !important;
    }
    .d-flex.justify-content-between {
        display: none !important;
    }
}
.tag-modal-height {
    height: auto !important;
    max-height: calc(100% - 30px);
}
// .rdrCalendarWrapper {
//     background-color: $card-background-color;
// }

// .rdrCalendarWrapper {
//     width: 100%;
// }
// .rdrMonth {
//     width: 100%;
// }
// .rdrNextPrevButton,
// .rdrNextButton {
//     display: none;
// }
// .rdrWeekDays {
//     display: none;
// }
// .rdrSelected {
//     color: $primary-color !important;
// }
// .rdrDayNumber > span:after {
//     background: $primary-color !important;
// }
// .rdrMonthAndYearPickers {
//     justify-content: start;
// }
// .rdrMonthPicker {
//     select {
//         padding-left: 0 !important;
//     }
// }
.rdrCalendarWrapper {
    padding: 0 2rem;
}
.stroke-success {
    svg,
    rect,
    path,
    circle,
    line,
    g {
        stroke: $success-color;
        path {
            stroke: $success-color;
        }
    }
}
.active-mobile-tab-bar {
    content: "";
    height: 4px;
    margin-top: 4px;
    background-color: #56ccb4;
    border-radius: 12px;
}
.followers-count {
    width: 15px;
    height: 15px;
    background-color: $warning-color;
    border-radius: 50%;
    bottom: 12px;
    right: -5px;
    border: 1px solid white;
    cursor: pointer;
}
.notes-count {
    width: 16px;
    height: 16px;
    background-color: $warning-color;
    border-radius: 50%;
    bottom: 14px;
    right: -8px;
    border: 1px solid white;
    cursor: pointer;
}
.followers-count-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    color: white;
    margin-top: 1px;
}
.custom-followers-dropdown {
    min-width: 340px;
    max-height: 400px;
    overflow: auto;
}
.follower-delete-icon {
    path {
        stroke: $error-color;
    }
    cursor: pointer;
}
.follower-add-icon {
    path {
        stroke: $success-color;
    }
    margin-right: 6px;
    cursor: pointer;
}
.custom-duration-dropdown {
    width: 385px;
}
.durations-section {
    background-color: $background-color;
    border-top: 1px solid $disable-color;
    border-bottom: 1px solid $disable-color;
}
.single-duration-part:not(:last-child) {
    border-bottom: 1px solid $disable-color;
}
.clock-icon {
    path {
        stroke: $secondary-color;
    }
}
path#Icon_feather-edit-2 {
    stroke: $secondary-color;
}
.selected-duration-tab {
    color: $success-color;
    background-color: $success-color;
}
.duration-tab-date-input {
    padding-right: 66px;
    width: 100%;
}
.drawer-header-title {
    &:hover {
        text-decoration: underline;
    }
}
.drawer-footer {
    bottom: -32px;
}
.activity-header-container {
    height: max-content;
    // height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.drawer-footer-active {
    height: 150px;
    background-color: $card-background-color;
}
.activity-drawer-text-area {
    padding: 12px 24px;
    width: 100%;
    border: 1px solid $border-rest-color;
    border-radius: 12px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: hidden;
    resize: none;
}
.height-inactive {
    height: 45px;
}
.height-active {
    height: 135px;
}
// .activity-detail-tabs{
//     position: sticky;
//     top: -60px;
//     background-color: white;
// }
.campaign-list-drawer-opener {
    width: 100%;
    height: 40px;
    background-color: $card-background-color;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.mobile-drawer {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    background-color: white;
    transition: bottom 0.3s ease-in-out;
    z-index: 101;
    max-height: calc(100% - 30px);

    background-color: $card-background-color;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow-y: auto;
}
.mobile-drawer-full {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    background-color: white;
    transition: bottom 0.3s ease-in-out;
    z-index: 99;
    height: calc(100% - 30px);

    background-color: $card-background-color;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow-y: clip;
}
.mobile-drawer-header {
    circle {
        fill: $primary-color;
    }
}
.mobile-drawer.open {
    bottom: 0;
}
.mobile-drawer-full.open {
    bottom: 0;
}

.mobile-drawer-top-section {
    height: 56px;
    background-color: $background-color;
    border-top: 1px solid #3050731a;
    border-bottom: 1px solid #3050731a;
}
.drawer-height-small {
    height: 30% !important;
}
.drawer-height-medium {
    height: calc(100% - 100px) !important;
}
// .drawer-height-medium-small {
//     height: 320px !important;
// }
.activity-detail-drawer-done-button {
    border: 0;
    background-color: white !important;
    color: $primary-color !important;
    width: fit-content;
}
.count-mobile {
    width: 20px;
    height: 20px;
    background-color: $warning-color;
    border-radius: 50%;
    border: 1px solid white;
}
.count-text-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
}
.mobile-drawer-title-container {
    position: sticky;
    top: 24px;
    z-index: 1;
    background-color: #fff;
}
// .scroll-container-new {
//     height: 82%;
// }
.medium-drawer-wrapper {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    ///hide scrollbar
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
.small-drawer-title {
    // width: 70%;
    text-align: center;
}
// .small-drawer-wrapper {
//     // height: 30%;
// }
.activity-detail-drawer-body {
    color: $card-background-color;
}
.mobile-add-assignee-button {
    border-radius: 50% !important;
    border: 1px dashed !important;
}
.total-duration-section {
    border-bottom: 1.5px solid rgba($disable-color, 0.3);
}
.small-assignee {
    width: 40px;
    height: 40px;
}
.mobile-drawer-body {
    height: calc(100% - 135px);
}
.mobile-drawer-bottom {
    box-shadow: 0px -3px 8px #3050731a;
    height: 64px;
}
.mobile-drawer-bottom-wrapper {
    justify-content: space-evenly;
}
.drawer-active-tab {
    content: "";
    height: 6px;
    width: 36px;
    background-color: $success-color;
    border-radius: 12px;
}
.drawer-icon-wrapper {
    width: 36px;
    path#Icon_feather-edit-2 {
        stroke: currentColor !important;
    }
}
.mobile-drawer-tab-wrapper {
    height: calc(100% - 64px) !important;
    overflow-y: scroll;
}
.mobile-table-names {
    border: 1.5px solid $secondary-color;
    border-radius: 12px;
    background-color: $card-background-color;
}
.mobile-table-name {
    border-right: 1.5px solid $secondary-color;
    padding: 12px 24px;
    font-size: 12px;
    color: $secondary-color;
    text-align: center;
}
.mobile-table-name:last-child {
    border-right: 0;
}
.table-count {
    height: 26px;
    border-radius: 8px;
    font-size: 12px;
    border: 1px solid $border-rest-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $border-rest-color;
    margin-left: 20px;
    padding: 0px 8px;
}
.fullsize-backdrop-100 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000002e;
    z-index: 100;
}
.follower-wrapper {
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}
#close-cross {
    path {
        stroke: white;
    }
}
.color-bars {
    min-width: 28px;
}
.delete-document-mobile-error-message {
    word-wrap: break-word;
}
.pinned-wrapper {
    border: 1.5px solid $border-rest-color;
    border-radius: 12px;
    padding: 6px 12px;
    filter: drop-shadow(0px 3px 8px #6490bf1a);
    background-color: $card-background-color;
}
.null-priority-button {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    border: 1px dashed $disable-color;
    border-radius: 50% !important;
    svg {
        width: 14px !important;
    }
    path {
        stroke: $disable-color;
    }
    //on hover color
    &:hover {
        border: 1px dashed $disable-color;
        path {
            stroke: white;
        }
        background: $disable-color;
    }
}

.invoiceCustomerInfoLabel {
    width: 150px;
}

.invoiceCustomerInfoValue {
    width: 300px;
}

.dealObj {
    border-bottom: 1px solid #a6b7ca;
}

.dynamicFieldsTitle {
    border-bottom: 1px solid #a6b7ca;
    color: #a6b7ca;
    padding: 4px 8px;
    margin: 16px 0px;
    font-size: 0.875rem;
}

// .dynamicFields {
//     //border-bottom:1px solid #A6B7CA;
// }

.note-input-spinner {
    .spinner-border {
        width: 20px;
        height: 20px;
    }
}
.activitiesContainer {
    padding: 0;
    height: calc(100vh - 120px);
    overflow-y: auto;
    margin-right: -2rem;
    padding-right: 16px !important;
    @media (max-width: $bootstrap-xl-breakpoint) {
        &:not(.mobileapp) {
            margin-right: 0 !important;
            padding-right: 0 !important;
            height: calc(100vh - 320px);
            &.mindmap {
                height: 100%;
            }
        }
    }
}

.activities-home {
    @extend .activity-custom-scroll;
    @media (max-width: $tablet-breakpoint) {
        width: 100% !important;
    }
}

.completedProposal:hover,
.notCompletedProposal:hover {
    cursor: pointer;
}

.notCompletedProposal #Ellipse_152 {
    fill: lightgray;
}
.activity-notes-bottom-wrapper,
.activity-note,
.description-wrapper {
    .ck-editor__editable {
        min-height: 50px !important;
        border: 1px solid #c0cbd8 !important;
        max-height: 390px !important;
        border-radius: 12px !important;
        width: 100% !important;
        background-color: #fcfcfc !important;
    }
}
.activity-notes-bottom-wrapper p {
    margin: 0 !important;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    border: 1px solid #c0cbd8 !important;
    padding-top: 12px !important;
    padding-bottom: 8px !important;
    display: flex !important;
    flex-direction: column;
}
.description-wrapper {
    .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
        min-height: 167px !important;
    }
}
.ck.ck-balloon-panel.ck-powered-by-balloon {
    display: none !important;
}
.add-activity-wrapper {
    .rdrCalendarWrapper {
        background-color: #fcfcfc;
        padding: 0;
        width: 100%;
    }
    .rdrMonth {
        width: 100%;
    }
}

.maxZIndex {
    z-index: 999 !important;
}

.activity-search {
    width: 100% !important;
    @media (min-width: 1200px) {
        min-width: 375px;
    }
}

.sorting-dropdown-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 0.5rem;
    margin: 0.2rem 0;
    background-color: transparent;
    color: $secondary-color;
    &.active {
        background-color: $primary-color;
        color: $card-background-color;
        border-radius: 4px;
        padding: 0.2rem 0.5rem;
    }
}
figure.image.ck-widget.ck-appear,
span.image-inline.ck-widget.ck-appear.ck-widget_selected,
figure.image.ck-widget.ck-widget_selected {
    display: none !important;
}

.subactivity-subject {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.activity-detail-modal .table-text,
.activity-table .table-text {
    font-weight: 400;
    font-size: 14px; //0.875rem;
}

@media (min-width: 1920px) {
}
@media (max-width: 1919px) {
}

.activity-campaign-name {
    @extend .line-clamp-1;
    @media (max-width: $mobile-breakpoint) {
        -webkit-line-clamp: 2;
    }
}
.drawer-due-date .rdrCalendarWrapper,
.drawer-due-date .rdrMonth {
    width: 100% !important;
    background-color: #fcfcfc;
}
.ck.ck-balloon-panel.ck-balloon-panel_arrow_n,
.ck.ck-balloon-panel.ck-balloon-panel_arrow_nw,
.ck.ck-balloon-panel.ck-balloon-panel_arrow_sw,
.ck.ck-balloon-panel.ck-balloon-panel_arrow_s {
    &.ck-balloon-panel_with-arrow.ck-toolbar-container {
        z-index: 9999999 !important;
        position: absolute;
    }
}
.drag-wrapper {
    display: none;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    pointer-events: none;
    border: 2px dashed #a6b7ca;
}
span.mention {
    font-weight: 700;
}
.ck-content .mention {
    color: #305073 !important;
    background: rgba($disable-color, 0.3) !important;
}
.ck.ck-list__item .ck-button.ck-on {
    background: $secondary-color !important;
    border-radius: 6px;
}
.ck.ck-mentions > .ck-list__item {
    padding: 4px;
}
.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
    border-radius: 6px;
}

.ui-dialog ~ .ck-body-wrapper {
    --ck-z-modal: 12610;
}
.ck.ck-balloon-panel.ck-balloon-panel_caret_se {
    z-index: 99999 !important;

    .calculated-close-button-width {
        width: calc(100% - 60px);
    }
}
.ck.ck-balloon-panel.ck-balloon-panel_caret_ne.ck-balloon-panel_visible {
    ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }
    ::-webkit-scrollbar-track {
        background-color: inherit;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #e0e0e0;
        border: 3px solid transparent;
        border-radius: 6px;
        background-clip: content-box;
        min-height: 32px;
        &:hover {
            background-color: #c0c0c0;
            border-width: 2px;
        }
    }
    z-index: 99999 !important;
    .calculated-close-button-width {
        width: calc(100% - 60px);
    }
}
.custom-checkbox-input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    height: 0;
    width: 0;
}

.custom-checkbox-checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ececec;
    border-radius: 3px;
    cursor: pointer;
}

.custom-checkbox-input:checked + .custom-checkbox-checkmark {
    background-color: $development-color;
}

.custom-checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox-input:checked + .custom-checkbox-checkmark:after {
    display: block;
}

.custom-checkbox-checkmark:after {
    content: "";
    left: 7px;
    top: 4px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
