.trainingDetail .card {
    margin: 5px !important;
}

.trainingDetail .col-lg-2 .cardHeader {
    padding-left: 15px;
    padding-right: 15px;
}

.trainingHomeCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    // margin-top: -35px;

    .trainingHomeCard--flexColumn {
        flex-direction: column !important;
    }
}

.centerEverything {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    align-content: center;
    text-align: center;
}

.trainingHomeCard .totalNumber {
    // font-size:27px;
    font-weight: bold;
    color: $secondary-color;
    margin-bottom: 20px;
    // max-width:40%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.trainingHomeCard .addNew {
    flex-direction: column;
    position: relative;
    top: 25px;
    right: 0px;
}

.trainingHomeCard .addNew button {
    background-color: #c4c4c4;
    font-size: 10px;
    border: 0;
    border-radius: 10px;
    padding: 7.5px;
    width: 72px;
}

.training {
    font-size: 10px;
    margin-bottom: 10px;
}

.training .trainingIcon {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 360px;
    background: #e1e9ff;
    align-self: center;
    margin-right: 10px;
    overflow: hidden;
    text-align: center;
    line-height: 30px;
    color: rgb(126, 130, 153);
    font-size: 13px;
    font-weight: 500;
}

.training .trainingIcon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.training .trainingName {
    color: #464e5f;
    font-weight: 600;
    margin-right: 10px;
    font-size: 12px;
}

.training .trainingType {
    min-width: 80px;
}

.portfolioCard .training .trainingType {
    width: auto;
}

.training .trainingDate {
    width: 70px;
}

.training .trainingLocation {
    min-width: 150px;
}

.training .trainingType,
.training .trainingLocation,
.training .trainerTitle {
    color: #b5b5c3;
}

.training .trainingDate,
.training .trainerName {
    color: #3699ff;
}

.training .trainer {
    text-align: right;
    width: 100px;
    max-width: 150px;
    margin-left: auto;
}

.topTrainers {
    font-size: 10px;
    margin: 20px 15px;
}

.topTrainers .averageScore {
    width: 90px;
    min-width: 90px;
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
}

.topTrainers .dailyRate {
    width: 110px;
    min-width: 110px;
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
}

.topTrainers .averageScore {
    text-align: center;
    margin-left: auto;
}

.averageScoreGreen text {
    fill: #40b953;
}

.totalRevenueGreen {
    color: #40b953;
}

.averageScoreRed text {
    fill: #8d0404;
}

.totalRevenueRed {
    color: #8d0404;
}

.topTrainers .dailyRate span {
    font-size: 10px;
    display: block;
    line-height: 10px;
    margin-bottom: -10px;
    color: #b5b5c3;
    text-align: right;
}

.topTrainers .dailyRate span.bottom {
    margin-bottom: 0px;
    margin-top: -10px;
}

.bottom_avgScore {
    font-size: 12px !important;
    font-weight: 400;
    color: #3699ff !important;
    text-align: left !important;
}

.bottom_totalCourses {
    color: #7b7b7b !important;
    font-weight: 400;
    text-align: left !important;
}

.dR_avgScore {
    width: 55px !important;
}

.dR_totalCourses {
    width: 70px !important;
    margin-left: 20px !important;
}

.dR_totalCourses,
.dR_avgScore {
    text-align: left !important;
    margin-top: -10px;
}

.topTrainers .dailyRate {
    text-align: right;
}

.topTrainers .trainerName {
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: 600;
    color: $secondary-color;
}

.trainerNameAllTrainersList {
    font-size: 14px;
    font-weight: 600;
}

.topTrainers .trainerExpertise {
    color: #b5b5c3;
}

.topTrainers .trainerImg {
    width: 40px;
    min-width: 48px;
    height: 40px;
    min-height: 40px;
    background: #c9f7f5;
    margin-right: 10px;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    line-height: 40px;
    color: rgb(126, 130, 153);
    font-weight: 600;
    font-size: 16px;
}

.topTrainers .trainerImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.topTrainers .trainerInfo {
    width: 80px;
    min-width: 80px;
    text-align: right;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    color: $secondary-color;
}

.topTrainers .trainerInfo.revenue {
    width: 110px !important;
    min-width: 110px !important;
    color: $success-color;
}

.topTrainersHeaders,
.topTrainersHeadersProposal {
    margin: 0 15px -10px 15px;
}

.topTrainersHeaders div:first-child,
.topTrainersHeadersProposal div:first-child {
    margin-left: auto;
}

.topTrainersHeaders div {
    width: 110px;
    font-size: 10px;
    color: #7b7b7b;
}

.topTrainersHeadersProposal .revenue {
    width: 110px !important;
}

.topTrainersHeadersProposal div {
    width: 80px;
    font-size: 10px;
    color: $disable-color;
}

.allTrainersListWrapper {
    background-color: #d9dbdc;
    padding-bottom: 20px;
}

.allTrainersList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
}

.allTrainersList .MuiTableCell-root {
    display: inline-block;
}

.allTrainersList .allTrainersListItem {
    padding: 15px;
    background-color: white;
    border-radius: 12px;
}

.allTrainersList .allTrainersListItem:hover {
    //cursor:pointer;
}

@media (max-width: 599px) {
    .mobile-p-0 {
        padding: 0 !important;
    }
    .allTrainersList {
        grid-template-columns: 1fr;
    }
    .allTrainersListItem_card {
        margin-left: 30px;
    }
    .trainingHomeCard {
        margin-top: 0;
    }

    .topTrainers .dailyRate {
        width: 70px !important;
        min-width: 70px !important;
        margin-left: auto;
    }

    .trainerNameAllTrainersList {
        margin-right: 10px;
    }

    .topTrainers .dailyRate a {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .trainerInfo,
    .topTrainers .averageScore {
        width: auto;
        max-width: 25% !important;
        min-width: 0 !important;
    }

    .totalRevenueCard {
        margin-top: 0 !important;
    }

    .topTrainers .averageScore {
        width: 50px !important;
        margin-left: 0 !important;
    }

    .trainerInfo,
    .topTrainers .averageScore,
    .topTrainers .dailyRate {
        font-size: 14px !important;
    }

    .allTrainerList_Input {
        width: 100% !important;
    }

    .profileModal .form-control {
        width: 110px !important;
    }

    .profileModal {
        justify-content: start !important;
    }

    .blankTitle {
        width: 0 !important;
    }

    .avgScoreTitle {
        width: 60px;
    }

    .topTrainers .trainerInfo.revenue {
        width: 70px !important;
        min-width: 70px !important;
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .topTrainersHeadersProposal .revenue {
        width: 70px !important;
    }

    .topTrainers .trainerInfo {
        width: 60px;
    }

    .topTrainersHeadersProposal div {
        width: 60px;
    }

    .training .trainingDate {
        width: auto;
    }

    //CARD mobil uzunlamasına dizilim
    .mobile-longitudinal {
        justify-content: start !important;
        align-items: start !important;
    }
    .mobile-top {
        flex-direction: row-reverse !important;
    }
    .mobile-icon-right {
        position: absolute;
        right: 2rem;
    }
}

@media (min-width: 600px) and (max-width: 1099px) {
    .allTrainersList {
        grid-template-columns: repeat(2, 1fr);
    }
    .allTrainersListItem_card {
        margin-left: 30px;
    }
    .totalRevenueCard {
        margin-top: 0 !important;
    }
    .profileModal .form-control {
        width: 220px !important;
    }
}

@media (min-width: 993px) and (max-width: 1099px) {
    .totalRevenueCard {
        margin-top: -35px !important;
    }
}

@media (min-width: 1201px) and (max-width: 1359px) {
    .training .trainingLocation {
        min-width: auto !important;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }
}

@media (min-width: 1100px) and (max-width: 1329px) {
    .allTrainersList {
        grid-template-columns: repeat(3, 1fr);
    }
    .allTrainersListItem_card {
        margin-left: auto;
    }

    .totalRevenueCard {
        margin-top: -35px !important;
    }
    .profileModal .form-control {
        width: 220px !important;
    }
}

@media (min-width: 1330px) and (max-width: 1649px) {
    .allTrainersList {
        grid-template-columns: repeat(4, 1fr);
    }
    .allTrainersListItem_card {
        margin-left: auto;
    }
    .profileModal .form-control {
        width: 220px !important;
    }
}

@media (min-width: 1650px) {
    .allTrainersList {
        grid-template-columns: repeat(4, 1fr);
    }
    .allTrainersListItem_card {
        margin-left: auto;
    }
    .profileModal .form-control {
        width: 220px !important;
    }
}

.allTrainersList .allTrainersListItem .allTrainersListItem_Image {
    flex: 0 0 85px;
    height: 85px;
    width: 85px;
    background-color: #eff2ff;
    margin-right: 15px;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    line-height: 85px;
    color: rgb(126, 130, 153);
    font-size: 22px;
    font-weight: 600;
}

.allTrainersList .allTrainersListItem .allTrainersListItem_Image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.allTrainersList .allTrainersListItem .allTrainersListItem_Title {
    //margin-left:20px;
}

.allTrainersList .allTrainersListItem .allTrainersListItem_Title a {
    color: #3f4254;
}

.allTrainersList .allTrainersListItem .allTrainersListItem_AccountName {
    font-size: 14px;
    line-height: 20px;
    /*height:40px;*/
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.allTrainersList .allTrainersListItem .allTrainersListItem_AccountIndustry {
    font-size: 0.75rem;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    color: #a6b7ca;
}

.allTrainersList .allTrainersListItem .allTrainersListItem_AvgScore span {
    font-size: 30px;
    font-weight: 600;
    margin-left: auto;
}

.allTrainersList .allTrainersListItem .allTrainersListItem_AvgScore .allTrainersListItem_AvgScoreTitle {
    font-size: 12px;
    color: #3699ff;
    text-align: right;
    font-weight: 400;
}

.allTrainersList .allTrainersListItem .allTrainersListItem_AvgScore {
    width: 85px;
}

.allTrainersList .allTrainersListItem .allTrainersListItem_Info {
    flex: 0 0 85px;
}

.allTrainersListItem_InfoMiniTitle {
    color: #7b7b7b;
    font-size: 10px;
    line-height: 13px;
}

.allTrainersListItem_InfoMiniSubinfo {
    margin-right: 10px;
}

.allTrainersListItem_InfoMiniSubtitle {
    color: #b5b5c3;
    font-size: 10px;
    line-height: 13px;
}

.allTrainerList_Input {
    width: 200px;
}

.trainerDetail .profilePicture {
    width: 109px;
    min-width: 109px;
    height: 109px;
    min-height: 109px;
    border-radius: 12px;
    background-color: #eff2ff;
    margin-right: 15px;
    border-radius: 5px;
    text-align: center;
    line-height: 109px;
    color: #7e8299;
    font-size: 26px;
    font-weight: 600;
    position: relative;
    z-index: 1;
    .svgContainer {
        position: absolute;
        right: -10px;
        bottom: -10px;
        width: 45px;
        height: 45px;
        border-radius: 12px;
        background-color: $success-color;
        .imageSideIcon {
            position: absolute;
            top: 30%;
            right: 30%;
            path {
                stroke: $card-background-color;
            }
        }
    }
}

.trainerDetail .profilePicture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.miniCardTitle {
    font-size: 13.5px !important;
}

.trainerImage {
    position: absolute;
    left: 0;
}

.trainerClamppedText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    margin-bottom: 10px;
}

.trainerClamppedTextAfter {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    margin-bottom: 10px;
}

.trainerDetailCard {
    padding: 10px;
}

.profileInfo {
    margin-left: 20px;
    width: 100%;
}

.profileInfo_trainer {
    width: 100%;
    justify-content: space-between;
}

.profileInfo_trainerName {
    color: #464e5f;
    font-size: 20px;
    font-weight: 700;
}

.profileInfo_trainerExpertise {
    font-size: 10px;
    color: #b5b5c3;
    //width:150px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.profileInfo_trainerBio {
    font-size: 10px;
    color: #b5b5c3;
    //width:150px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.totalRevenueCard {
    align-items: center;
    margin-top: -35px;
    padding-top: 35px !important;
    justify-content: center;
}

.totalRevenueCard .totalRevenue {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.totalRevenueCard .overallAverage {
    color: #464e5f;
    font-size: 12px;
    text-align: center;
    margin-top: 40px;
}

.totalRevenueCard .overallAverage span {
    font-weight: 600;
}

.dailyRateCard {
    justify-content: center;
    margin-top: -25px;
}

.dailyRateCard_title {
    color: #b5b5c3;
    font-size: 14px;
    font-weight: 600;
}

.dailyRateCard_ovrTitle {
    color: #b5b5c3;
    font-size: 10px;
    font-weight: 600;
}

.dailyRateCard_value {
    font-size: 18px;
    font-weight: 600;
    width: 95px;
}

.dailyRateCard_ovrValue {
    color: #464e5f;
    font-size: 10px;
    font-weight: 600;
}

.dailyRateCard_subtitle {
    color: #464e5f;
    font-size: 12px;
    margin-top: 40px;
    font-weight: 600;
}

.trainerDetailCard .roundedItem {
    width: 95px;
    height: 21px;
    line-height: 21px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    padding: 0 10px;
}

.trainerDetailCard .roundedItem.nationality {
    background-color: #e1e9ff;
}

.trainerDetailCard .roundedItem.country {
    background-color: #c9f7f5;
}

.validVisasCard {
    font-size: 12px;
    color: #464e5f;
    font-weight: 600;
}

.validVisasCard_title {
    color: #b5b5c3;
}

.validVisasCard .flex-row {
    margin-bottom: 10px;
}

.svg-icon-edit {
    height: 35px;
    line-height: 35px;
}

.svg-icon-edit:hover {
    cursor: pointer;
}

.trainingStatus {
    margin-left: 10px;
    text-transform: uppercase;
}

.trainingStatus_approved {
    color: #3adcd5;
}

.trainingStatus_pending {
    color: #e3c41d;
}

.trainerFilterButtons {
    // padding: 5px 20px;
    // border: 0;
    // border-radius: 7px;
    // background-color: #eff2ff;
    // color: #6a83ff;
    // font-size: 13px;
    //margin-left: 20px;
}

.totalTrainersWrapper .goToATL {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.profileModal {
    justify-content: space-between;
}

.profileModalLabel {
    width: 85px;
}

.editProfileModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.long-label {
    width: 110px !important;
}

.cardWithBg {
    background-image: url("/media/bg/bg-card.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
}

input.addModalDatePicker {
    width: 140px !important;
}

.addButtonModal {
    border: 0;
    border-radius: 3px;
    background: #dbfbd6;
    color: #38cd20;
    margin-left: 10px;
    line-height: 36px;
    padding: 0 7px;
    font-weight: 600;
}

.cancelButtonModal {
    background: transparent !important;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px !important;
    border: 0;
}

.saveButtonModal {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 10px 20px !important;
    border: $common-border-style $primary-color;
    border-radius: 12px;
    @extend .btn-outline-secondary;
}

.modalRow {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: keep-all;
}
.editVisasRow {
    display: grid;
    grid-template-columns: 4fr 3fr 1fr;
    padding-bottom: 10px;
    .card-table-image {
        width: 48px;
        border-radius: 12px;
        margin-right: 16px;
        overflow: hidden;
    }
}

.editPortfolioRow {
    display: grid;
    grid-template-columns: 40px 10fr 1fr;
    padding-bottom: 10px;
    .portfolioIndex {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7px;
    }
}
.editCountryRow {
    display: grid;
    grid-template-columns: 40px 10fr 1fr;
    padding-bottom: 10px;
}
.card-table-parent {
    .card-table-image {
        position: relative;
        font-size: 1rem;
        background-color: $disable-color;
        line-height: 3em;
        height: 3em;
        width: 3em;
        min-width: 3em;
        min-height: 3em;
        overflow: hidden;
        text-align: center;
        border-radius: 12px;
        color: $secondary-color;
        margin-right: 1.5rem;
        font-weight: 600;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.selectInput {
    width: 90%;
    height: 44px;
    font-weight: 400 !important;
}

.addModalDatePicker {
    height: 38px;
    border: rgb(235 235 235) 1px solid;
}

.titleInHouse {
    width: 70px;
}

.flexInHouse {
    width: 165px;
}

.portfolioRow {
    margin-bottom: 30px;
}

.trainingDetailTable .MuiTablePagination-root {
    float: right;
}

.trainingInfosMobil .trainingType,
.trainingInfosMobil .trainingLocation {
    width: auto;
    min-width: 0;
}

.mobil_expertise {
    color: #7b7b7b !important;
}

.trainerNameandExpertise a {
    color: #464e5f;
}

.trainerNameandExpertise .trainerName,
.trainerNameandExpertise .trainerExpertise {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.errorMessageModal {
    color: darkred;
    margin-bottom: 10px;
}

.topTrainersByScoreInput {
    display: flex;
    justify-content: end;
    position: absolute;
    right: 10%;
    top: 50px;
    input {
        min-width: 200px;
        width: 15vw;
        background: $card-background-color 0% 0% no-repeat padding-box;
        box-shadow: $common-box-shadow $box-shadow-color !important;
        border: $common-border-style $border-rest-color;
    }
    input::placeholder {
        text-align: left;
        font: normal normal 600 14px/21px;
        letter-spacing: 0px;
        color: $disable-color;
        padding-left: 5px;
    }

    .search-icon {
        box-shadow: 3px 0px 8px #5a81ab1a;
    }
}

@media (max-width: 768px) {
    .topTrainersByScoreInput {
        left: 0;
    }
}

input.descriptionInput {
    width: 90%;
    height: 44px;
    border: 1.5px solid $secondary-color;
    border-radius: 12px;
    box-shadow: 0px 3px 8px #49698c1a;
    margin-bottom: 10px;
    color: $secondary-color;
    &:focus {
        border-radius: 12px;
        border: 1.5px solid $secondary-color;
        color: $secondary-color;
    }
}

.selectInputModal {
    @extend .customSelectInput;
    &__control,
    &__control--is-focused,
    &__control--menu-is-open {
        border-radius: 12px !important;
        border: 1.5px solid #c0cbd8 !important;
    }
    .css-12jo7m5 {
        color: $card-background-color !important;
    }
    &__multi-value__label {
        color: $card-background-color !important;
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    &__multi-value {
        background: $secondary-color !important;
        color: $card-background-color !important;
        border: $common-border-style $secondary-color !important;
        border-radius: 12px !important;

        &__remove {
            border-radius: 0 !important;
            cursor: pointer;
            & svg {
                fill: $card-background-color !important;
            }
            &:hover {
                background-color: transparentize($secondary-color, 0.5) !important;
            }

            &:hover svg {
                fill: $card-background-color !important;
            }
        }
    }
    &__menu-list--is-multi::-webkit-scrollbar,
    &__menu::-webkit-scrollbar {
        display: none;
    }
    &__menu {
        border-radius: 12px !important;
        padding: 10px !important;
        border: 1.5px solid #c0cbd8;
    }
    &__option {
        color: $secondary-color !important;
        font-size: 14px !important;
        font-weight: normal !important;
        border-radius: 12px !important;
    }
    &__option--is-focused {
        background-color: $secondary-color !important;
        color: $card-background-color !important;
        border-radius: 12px !important;
    }
}

.editModalInput {
    width: 90%;
    box-shadow: 0px 3px 8px #49698c1a;
    border: 2px solid #c0cbd8;
    border-radius: 12px;
    color: $secondary-color;
    font-size: 16px;
    :focus {
    }
}

.editModalInput::-webkit-scrollbar {
    display: none;
}

.addEventFormTitle {
    color: $secondary-color;
    font-size: 1.375rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}

.eventFormWrapper {
    display: flex;
    flex-direction: column;
    max-width: 580px;
    margin: 0 auto;

    .input-box {
        > label {
            margin-left: calc(2rem + 5px);
            color: $disable-color;
            font-size: 0.875rem;
            font-weight: 600;
            margin-bottom: 6px;
            display: inline-flex;
        }
        .errorMessage {
            padding-left: calc(2rem + 5px);
        }
    }
    .borderBox {
        border: 1.5px solid #c0cbd8;
        box-shadow: 0px 3px 8px #49698c1a;
        border-radius: 12px;
        background-color: transparent;
    }

    .trainerFormInput {
        height: 45px;
        padding: 0 calc(2rem + 5px);
        color: $secondary-color;
        font-size: 1rem;
    }

    .trainerCheckBox {
        width: 28px;
        height: 28px;
        border: 1.5px solid #6490bf;
        border-radius: 6px;
        box-shadow: 0px 3px 8px #49698c1a;
        accent-color: #6490bf;
    }
    .Checkbox_formGroup__2ZxJ8 {
        display: inline-flex;
    }

    input::placeholder,
    .attachment__text,
    textarea {
        color: $secondary-color;
        font-size: 1rem;
        font-weight: 400;

        &__bolder {
            font-weight: 600;
        }
    }

    .attachment {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 330px;
        padding: 2rem;

        &__text {
            font-size: 16px;
            color: $secondary-color;
            &__bolder {
                font-weight: 600;
            }
        }
        &__xlink {
            height: 25px;
            margin: 0;
            padding: 0;
            color: #6490bf;
            font-size: 0.75rem;
            text-decoration: underline;
            cursor: pointer;
        }

        &__cancelIcon {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            color: $text-muted;
        }
    }
}
