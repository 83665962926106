.label {
    padding: 0.375rem 1.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 600;
    color: $card-background-color;
    background-color: $card-background-color;
    border: $common-border-style transparent;
    border-radius: 12px;
    width: auto;
    height: auto;
    display: block;

    // Do not apply label class without one of the following classes
    &.label-text-only {
        background-color: transparent;
        color: $disable-color;
    }

    &.label-text-primary {
        background-color: transparent;
        color: $primary-color;
    }

    &.label-primary {
        background-color: $primary-color;
    }

    &.label-secondary {
        background-color: $secondary-color;
        color: $card-background-color;
    }

    &.label-success {
        background-color: $success-color;
    }

    &.label-error {
        background-color: $error-color;
    }

    &.label-warning {
        background-color: $warning-color;
    }

    &.label-disable {
        background-color: $disable-color;
    }

    &.label-primary-outline {
        color: $primary-color;
        border-color: $primary-color;
    }

    &.label-secondary-outline {
        color: $secondary-color;
        border-color: $secondary-color;
    }

    &.label-success-outline {
        color: $success-color;
        border-color: $success-color;
    }

    &.label-error-outline {
        color: $error-color;
        border-color: $error-color;
    }

    &.label-warning-outline {
        color: $warning-color;
        border-color: $warning-color;
    }

    &.label-muted-outline {
        color: $secondary-color;
        border-color: #c0cbd8;
    }

    &.label-narrow {
        padding: 6px 12px;
        &.label-icon {
            padding: 0;
            .icon {
                display: inline-block;
                border-right: $common-border-style;
                border-color: inherit;
                padding: 6px 12px;
                svg {
                    height: 16px;
                    width: 16px;
                }
            }
            .content {
                display: inline-block;
                padding: 6px 12px;
            }
        }
    }
}
