@import "../../../../../src/app/popleads/assets/css/design_2.0/variables";

.chatItem {
    display: flex;
    gap: 4px;
    max-width: 85%;
    &.user {
        margin-left: auto;
    }
    &.assistant {
        margin-right: auto;
    }
}

.avatar {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    background-color: $tertiary-color;
    border-radius: $card-border-radius;
    color: $card-background-color;
    font-size: 1rem;
    overflow: hidden;
}

.messageBubble {
    padding: 12px;
    border-radius: $card-border-radius;
    display: inline-block;
    flex: 1;
}

.userBubble {
    background-color: $tertiary-color;
    color: white;
}

.assistantBubble {
    background-color: darken($success-color, 10%);
    color: white;
}

.message {
    margin-bottom: 4px;
    white-space: pre-wrap;
}

.timestamp {
    text-align: end;
    color: darken($background-color, 5%);
}
