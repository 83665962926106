.asideFilter {
    padding-bottom: 20px;

    h3 {
        color: #213040;
        font-size: 1.125rem;
        font-weight: 600;
        border-bottom: 1px solid #ccc;
        padding: 15px;
    }

    .asideFilter_section {
        padding: 10px 15px 20px;
        border-bottom: 1px solid #ccc;

        .asideFilter_sectionHeader {
            display: flex;
            justify-content: space-between;

            .searchInput {
                background-color: #f3f6f9;
                font-size: 14px;
                height: 27px;
                line-height: 27px;
                border: 0;
                border-radius: 5px;
                padding: 0 10px;
                font-weight: 500;
                margin-bottom: 10px;
                max-width: 65%;
            }

            .searchInput::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #d6d6d6;
                opacity: 1; /* Firefox */
            }
        }

        .asideFilter_sectionTitle,
        .flaticon2-search-1 {
            font-size: 1.125rem;
            font-weight: 600;
        }

        .openSearchInput {
            line-height: 27px;
        }

        .openSearchInput:hover {
            cursor: pointer;
        }

        .asideFilter_sectionBody {
            transition: height 1s;
            margin-top: 10px;
            .containerCheckboxInput {
                display: block;
                position: relative;
                padding-left: 25px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            /* Hide the browser's default checkbox */
            .containerCheckboxInput input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .containerCheckboxInput .checkboxLabel {
                font-size: 16px;
                font-weight: 600;
                color: #b5b5c3;
                line-height: 20px;
                display: block;
            }

            /* Create a custom checkbox */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 18px;
                width: 18px;
                background-color: #f3f6f9;
                border-radius: 5px;
                border: 1px solid #b5b5c3;
            }

            /* On mouse-over, add a grey background color */
            .containerCheckboxInput:hover input ~ .checkmark {
                background-color: #ccc;
            }

            /* When the checkbox is checked, add a blue background */
            .containerCheckboxInput input:checked ~ .checkmark {
                background-color: #2196f3;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the checkmark when checked */
            .containerCheckboxInput input:checked ~ .checkmark:after {
                display: block;
            }

            /* Style the checkmark/indicator */
            .containerCheckboxInput .checkmark:after {
                left: 6px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        .showMoreButton {
            font-size: 14px !important;
            font-weight: 600 !important;
            color: #b5b5c3;
            cursor: pointer;
            margin-top: 5px;
        }
    }
}
