@import "../../final-report-common.module.scss";

.textGreen {
    color: $primary-green;
}

.semiBold {
    font-weight: 500;
}

.highestItem {
    font-weight: 600 !important;
    color: $primary-green !important;
}

.itemTitle {
    font-size: 24px;
    font-weight: 600;
    color: $primary-text-color;
    line-height: 24px;
}

.yoyTable {
    border-right: 2px solid $secondary-gray;
}

.averageText {
    font-size: 14px;
    color: $primary-text-color;
    text-align: left;
}

.deliveryType {
    display: flex;
    align-items: center;
    margin-left: -32px;
    &::before {
        display: inline-block;
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 14px;
    }
    &.primary-green::before{
        background-color: #328456;
    }
    &.primary-orange::before{
        background-color: #DF8524;
    }
    &.primary-pink::before{
        background-color: #DB406C;
    }
    &.primary-darkblue::before{
        background-color: #3E4054;
    }
    &.primary-gray::before{
        background-color: #777987;
    }
    &.primary-purple::before{
        background-color: #A965E8;
    }
    &.primary-red::before{
        background-color: #ff4949;
    }
    &.primary-yellow::before{
        background-color: #ebb000;
    }
    &.secondary-green::before{
        background-color: #98C1AA;
    }
    &.secondary-orange::before{
        background-color: #c05300;
    }
    &.secondary-pink::before{
        background-color: #FF8DC7;
    }
    &.secondary-darkblue::before{
        background-color: #153462;
    }
    &.secondary-gray::before{
        background-color: #B2B2B2;
    }
    &.secondary-purple::before{
        background-color: #540375;
    }
    &.secondary-red::before{
        background-color: #962626;
    }
    &.secondary-yellow::before{
        background-color: #fff7a4;
    }
}

.salesThisMonthTitle {
    font-size: 18px;
    font-weight: 500;
    color: $primary-text-color;
}

.salesThisMonthAmount {
    font-size: 18px;
    font-weight: 600;
    color: $primary-green;
}