.header-fixed.subheader-fixed .subheader {
    // background-color: transparent;
    // box-shadow: none;
    // border-top: none;
    // @media (min-width: 992px) {
    //     top: 90px;
    // }
    @media (min-width: 768px) and (max-width: 991px) {
        position: sticky;
        height: 88px;
        top: 55px;
        left: 0;
        right: 0;
        transition: top 0.3s ease;
        z-index: 95;
        box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);
        background-color: #ffffff;
        border-top: 1px solid #ebedf3;
        margin: 0;
    }
    > div {
        > div {
            // background-color: $card-background-color;
            // border-radius: $card-border-radius;
            // box-shadow: $card-box-shadow;
            // padding: 1.6875em 2rem;
            // font-size: 0.5rem;

            // @media (min-width: 1200px) {
            //     font-size: 0.75rem;
            // }

            // @media (min-width: 1400px) {
            //     font-size: 1rem;
            // }

            .subHeaderPL {
                display: flex;
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
            }

            .breadcrumb {
                font-size: 1.075em !important;
                .breadcrumb-item {
                    display: flex;
                    align-items: center;
                    svg {
                        width: 1em !important;
                        height: 1em !important;
                    }
                }
            }

            .breadcrumb_subheaderTitle {
                font-size: 1.125em !important;
                max-width: 25em;
            }

            .subheader-action {
                display: flex;
                flex-wrap: nowrap;
                margin-left: 1rem;
                button {
                    margin-left: 1rem;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
