.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #fff !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon.svg-icon svg g [fill]{
    fill:#265F9A;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon.svg-icon svg g [fill]{
    fill:#ffffff;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
    background-color: #7dade0 !important;
}
