// 1 rem = 16 px
.text-heading,
.heading,
.subheading,
.page-heading,
.card-heading,
.filter-heading,
.table-heading,
.table-value,
.heading-item {
    color: $secondary-color;
    line-height: 1.5;
    text-align: start;
    letter-spacing: 0;
}

.table-value {
    font-weight: normal;
    font-size: 0.875rem; //1rem -> 0.875rem
}

@media (min-width: 1920px) {
    .heading {
        font-weight: 800;
        font-size: 3rem;
    }
    .subheading {
        font-weight: 700;
        font-size: 2rem;
    }
    .page-heading {
        font-weight: 600;
        font-size: 1.75rem;
    }
    .card-heading {
        font-weight: 600;
        font-size: 1.375rem;
    }
    .filter-heading {
        font-weight: 600;
        font-size: 1.25rem;
    }
    .table-heading {
        font-weight: 600;
        font-size: 1rem;
    }
    .text-card {
        font-size: 1rem;
    }
    .input-placeholder,
    .text-body,
    .table-text {
        color: $secondary-color;
        font-weight: 400;
        font-size: 1rem;
    }
    .table-sub-text {
        font-size: 0.875rem;
    }
    .small-text {
        font-size: 0.75rem;
    }
}

@media (max-width: 1919px) {
    .heading {
        font-weight: 800;
        font-size: 2rem;
    }

    .subheading {
        font-weight: 700;
        font-size: 1.5rem;
    }

    .page-heading {
        font-weight: 600;
        font-size: 1.25rem;
    }

    .card-heading {
        font-weight: 600;
        font-size: 1.125rem;
    }

    .filter-heading {
        font-weight: 600;
        font-size: 1rem;
    }

    .text-card {
        font-size: 0.875rem;
    }

    .table-heading {
        font-weight: 600;
        font-size: 0.875rem;
    }

    .input-placeholder,
    .text-body,
    .table-text {
        font-weight: 400;
        font-size: 0.875rem;
    }

    .table-sub-text {
        font-size: 0.75rem;
    }

    .small-text {
        font-size: 0.75rem;
    }
}
