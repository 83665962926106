.card {
    width: 100%;
    margin: 0.75rem 0.5rem;

    &.card2 {
        // Header
        box-shadow: $card-box-shadow;
        > .card-header {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
            padding: 0;
            margin-bottom: 1rem;
            background-color: transparent;
            border: none;

            // Title
            .card-title {
                display: flex;
                align-items: center;
                margin: 0;
                font-size: 1.125rem !important;
                font-weight: 600;
                line-height: 1.5;
                color: $secondary-color;

                .card-icon {
                    margin-right: 0.75rem;
                    line-height: 0;

                    i {
                        font-size: 1.25rem;
                        color: $secondary-color;
                        line-height: 0;

                        &:after,
                        &:before {
                            line-height: 0;
                        }
                    }

                    .svg-icon {
                        @include svg-icon-size(24px);
                        @include svg-icon-color($secondary-color);
                    }
                }

                &,
                .card-label {
                    font-weight: 600;
                    font-size: 1.375rem;
                    color: $secondary-color !important;
                    @media (max-width: 1919px) {
                        font-size: 1.125rem;
                    }
                }

                .card-label {
                    margin: 0 0.75rem 0 0;
                    flex-wrap: wrap;
                }

                // Description
                small {
                    color: $disable-color;
                    font-size: 1rem;
                }
            }

            // Toolbar
            .card-toolbar {
                display: flex;
                align-items: center;
                margin: $card-header-spacer-y 0;
                flex-wrap: wrap;
                .cardToolbarMuted {
                    @extend .small-text;
                    line-height: 1.5;
                    color: $primary-color;
                    text-decoration: underline;
                }
            }

            // Line tabs integration
            &.card-header-tabs-line {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                align-items: stretch;

                .card-toolbar {
                    margin: 0;
                }

                .nav {
                    border-bottom-color: transparent;

                    .nav-item {
                        align-items: stretch;
                    }

                    .nav-link {
                        padding-top: $card-spacer-y;
                        padding-bottom: $card-spacer-y;
                    }
                }
            }

            &.card-header-right {
                justify-content: flex-end;
            }
        }

        > .card-body {
            height: 100%;
            padding: 0;
        }

        > .card2-body {
            height: 100%;
        }

        .card-mini-title {
            color: $disable-color !important;
            // font-size: 0.75rem;
            @extend .small-text;
            line-height: 1.5;
            font-weight: normal;
            display: block;
            text-align: start;
        }

        .mini-table-counter {
            @extend .table-heading;
            line-height: 1.5;
            color: $secondary-color;
            width: 2rem;
            min-width: 2rem;
        }

        .card-table-image {
            position: relative;
            font-size: 1rem;
            background-color: $disable-color;
            line-height: 3em;
            height: 3em;
            width: 3em;
            min-width: 3em;
            min-height: 3em;
            overflow: hidden;
            text-align: center;
            border-radius: 12px;
            color: $secondary-color;
            margin-right: 1.5rem;
            font-weight: 600;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.card-heading {
                font-size: 1rem;
                font-weight: 600;
                @media (max-width < 1920px) {
                    font-size: 0.875rem;
                }
            }
            @media (max-width: $mobile-breakpoint) {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.875rem;
                margin-right: 1rem;
                height: 2rem;
                width: 2rem;
                min-width: 2rem;
                min-height: 2rem;
                border-radius: 8px;
                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
        }
    }
}
