@import "../../../../../src/app/popleads/assets/css/design_2.0/variables";

.chatInput {
    background-color: transparent;
    border: $common-border-style $border-rest-color;
    box-shadow: $common-box-shadow $box-shadow-color;
    border-radius: 12px;
    color: $secondary-color;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    flex: 1;

    &:focus {
        outline: none;
        border-color: $secondary-color;
    }
    &::placeholder {
        color: #8a9eb4;
    }
    &:disabled {
        color: #8a9eb4;
    }
}

.messages {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.drawerFooter {
    display: flex;
    width: 100%;
    gap: 8px;
}

.welcome {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
