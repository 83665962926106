.top2Accounts {
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 5rem;
    .accountRow {
        color: $success-color;
        font-size: 2rem;
        line-height: 1.5;
        margin-right: 1.5rem;
        font-weight: 800;
        width: 20px;
    }
    .accountBoxName {
        font-size: 1.25rem;
        padding-top: 0.75rem;
        margin-bottom: 1rem;
        line-height: 1.5;
        width: auto;
        -webkit-line-clamp: 1;
        color: $success-color;
        overflow: hidden;
    }
    .accountBox {
        background: none;
        padding: 0;
    }
    .accountBoxCount {
        color: $success-color;
        text-align: start;
        // font-size: 1.375rem;
        @extend .card-heading;
        line-height: 1.5;
    }

    @media(min-width:1400px) and (max-width:1919px){
        .accountBoxCount {
            font-size:0.85rem !important;
        }
    }

    @media (min-width: 768px) {
        .accountRow {
            font-size: 3rem;
        }
        .accountBoxName {
            font-size: 1.75rem;
            padding-top: 1.25rem;
        }
    }
}
.awmwd-container{
    @extend .row;
}
.awmwd-box {
    position: relative;

    &::before,
    &:nth-child(odd)::after {
        content: "";
        position: absolute;
        background-color: $border-rest-color;
    }

    // Border top
    &::before {
        height: $common-border-width;
        width: calc(100% - 4rem);
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }

    &:nth-child(1)::before {
        display: none;
    }

    // Border right
    &:nth-child(odd)::after {
        height: calc(100% - 4rem);
        width: $common-border-width;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    // Only hide the second border when boxes appear side by side
    &:nth-child(2)::before {
        display: none;
    }

    .awmwd-title {
        // (font-size) * (line-count) * (line-height)
        // 1.375 * 2 * 1.5 = 4.125
        height: 4.125rem;
        display: flex;
        align-items: flex-end;
    }

    @media (min-width: 1600px) {
        table {
            display: block;
        }
    }
    // @media (max-width: 600px) {
    //     table {
    //         display: none;
    //     }
    // }

    @media (max-width: 1919px) {
        .awmwd-title {
            // (font-size) * (line-count) * (line-height)
            // 1.125 * 2 * 1.5 = 3.375
            height: 3.375rem;
        }
        .small-text {
            line-height: 1.6875rem;
        }
    }
}


@media (max-width: 576px){
    .awmwd-container{
        display: flex !important;
        flex-direction: column !important;
    }
    .awmwd-box{
        width: 100% !important;
        max-width: none !important;

        &::before:not(:last-child) {
            height: $common-border-width;
            width: calc(100% - 4rem);
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }
    
        // &:nth-child(1)::before {
        //     display: none;
        // }
        &:nth-child(2)::before {
        display: block;
        }
        // Border right
        &:nth-child(odd)::after {
            height: 0;
            width: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        table {
            display: block;
        }
    }
}

.cardWithIconContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 1024px) {

    // .page-heading{
    //     display: flex !important; 
    //     justify-content: flex-start !important;
    // }

    .cardWithIconContainer{
        display: flex;
        justify-content: flex-start;
        align-content: space-between;
    }
}
@media (max-width: 1440px) { 
    .for-lg-design{
        display: flex !important;
        flex-direction: column !important;
        padding-right: 1.5rem;
        padding-left: 0;
        width: 100%;
    }
    .totalLeadsLg{
        position: absolute;
        left: 5vw;
        top: 5vh;
    }
    .totalLeadsNumberLg{
        position: absolute;
        left: 5vw;
        top: 10vh;
    }
    .totalLeadsIcon{
        position: absolute;
        right: 10%;
        bottom: 37%;
    }
    .gridBox{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

}