.aside {
    background-color: $primary-900;
}

.aside-menu {
    height: calc(100vh - 240px) !important;
    background-color: transparent !important;
}

@media screen and (max-width: 500px) {
    #kt_aside_menu_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.brand {
    background-color: transparent !important;
    position: relative;
    padding: 0;
    padding-top: 3rem;
    margin-top: 2rem;
    z-index: 2;
}

.brand-toggle {
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    transform: translateX(50%);
    padding: 0 !important;
    background-color: $primary-900;
    border-radius: 4px !important;
    box-shadow: $card-box-shadow;

    width: 36px !important;
    min-width: 36px !important;
    height: 36px !important;
    .svg-icon {
        margin-right: 0;
        svg {
            width: 24px !important;
            height: 24px !important;
        }
    }
}

.brand-logo {
    padding: 0 24px;
    padding-top: 0.75rem;
    margin-top: -60px;
    display: flex;
    svg {
        width: 36px;
        height: 36px;
    }
    .leads-text {
        margin-left: 8px;
        opacity: 1;
        transition: opacity 0.3s ease;
    }
    .aside-minimize:not(.aside-minimize-hover) & {
        .leads-text {
            opacity: 0;
        }
    }
}
.aside-left {
    transition: width 0.3s ease;
}

.aside-menu {
    .menu-nav > .menu-item {
        .menu-submenu {
            .menu-item > .menu-link .menu-text {
                color: $primary-100 !important;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: -0.12px;
            }
        }

        &.menu-item-open > .menu-link {
            background-color: transparent !important;
            position: relative;
        }

        > .menu-link {
            padding: 0px 32px;
            margin: 6px 0px;

            .menu-arrow:before {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fcfcfc' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
                width: 24px;
                height: 24px;
                font-size: 0.825rem !important;
            }
            .menu-text {
                color: $primary-100 !important;
                font-size: 0.875rem !important;
                font-weight: 500;
                line-height: 1.5;
                letter-spacing: -0.12px;
                opacity: 1;
                transform: translateX(0);
            }

            .menu-icon.svg-icon svg g [fill] {
                fill: none;
                &:hover {
                    fill: none;
                }
            }
        }
    }
}

.menu-text {
    white-space: nowrap;
    transition: opacity, transform 0.3s ease;
}

.aside-minimize:not(.aside-minimize-hover) .topbar-span-name {
    display: none !important;
    transform: translateX(5rem);
    opacity: 0;
}

.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav {
    > .menu-item {
        @media (min-width: 992px) {
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) > .menu-link .menu-text {
                    transform: translateX(#{$i * 5}rem);
                }
            }
            > .menu-link {
                .menu-text {
                    display: flex;
                    opacity: 0;
                }
            }
        }
    }
}

.menu-item {
    .reports-animate {
        line {
            transform-origin: 0 85%;
        }
    }

    &:hover {
        .campaign-animate {
            transform: rotate(-15deg);
        }
        .reports-animate {
            .bar-chart-first-bar {
                transform: scaleY(1.5);
            }

            .bar-chart-last-bar {
                transform: scaleY(0.75);
            }
        }
        .survey-animate {
            opacity: 0;
        }
    }

    .navbar-svg {
        stroke: $card-background-color;
        transition: all 300ms ease;
        g,
        line {
            transition: all 300ms ease;
        }
    }
}
.user-name-background {
    color: $neutral-50;
}
.brand-dark .header-mobile {
    background-color: $primary-900;
}

.topbar-mobile-on.header-mobile-fixed .topbar {
    margin-top: 82px;
}

.menu-link:hover {
    background-color: $primary-950;
}
.menu-item.menu-item-submenu a:hover {
    background-color: $primary-950 !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link.active,
// .aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link:hover,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link.active {
    background-color: $primary-700 !important;
    color: $neutral-50 !important;
}

.aside-menu-wrapper .aside-menu-user {
    width: 265px !important;
    text-align: center;
    transform: translateY(0);
    overflow: hidden;
    transition: transform 0.3s ease;
    .assignee-wrapper {
        background-color: $primary-700;
        border-color: $primary-700;
        color: $primary-100;
    }
    .aside-menu-user-detail {
        opacity: 1;
        transition: transform 0.3s ease;
        transform: translateY(0);
        margin-top: 8px;
        .signout {
            height: 50px;
            color: $primary-100;
            font-weight: 600;
            font-size: 12px;
            &:hover {
                cursor: pointer;
            }
            a:hover {
                color: #eff3f8;
            }
        }
    }
}

@media (min-width: 992px) {
    .aside-minimize:not(.aside-minimize-hover) .aside-menu-wrapper .aside-menu-user {
        .aside-menu-user-detail {
            transform: translateY(82px);
        }
        transform: translateY(61px);
    }
}
