.allTrainersListWrapper {
    background: none;
    &.card.card-custom {
        box-shadow: none;
    }

    .card-header.border-0.cardHeader {
        padding: 0.5rem;
    }

    .allTrainersList {
        padding: 0 !important;
    }
}

.allTrainersList {
    .allTrainersListItem {
        padding: $card-padding-y $card-padding-x;
        border-radius: $card-border-radius;
        color: $secondary-color;
        box-shadow: $card-box-shadow;
        background-color: $card-background-color;

        .allTrainersListItem_AvgScoreInfo {
            font-size: 1rem;
            line-height: 1.5;
            color: $success-color;
            font-weight: 600;
            padding-bottom: 8px;

            &.allTrainersListItem_AvgScoreInfo_Error {
                color: $error-color;
            }
        }

        .allTrainersListItem_Info {
            margin-top: 16px;
        }

        .allTrainersListItem_AccountName {
            display: flex;
            text-align: start;
            align-items: flex-end;
            font-size: 1rem;
            line-height: 1.5;
            span {
                max-height: 3rem;
                overflow: hidden;
            }
        }

        .allTrainersListItem_Image {
            font-size: 2rem;
            background-color: $disable-color;
            line-height: 70px;
            flex: 0 0 70px;
            height: 70px;
            width: 70px;
            border-radius: $card-border-radius;
            color: $secondary-color;
        }

        .allTrainersListItem_InfoMiniTitle {
            padding-top: 8px;
            font-size: 0.75rem;
            line-height: 1.5;
            color: $disable-color;
            font-weight: normal;
        }

        .allTrainersListItem_InfoMiniDesc {
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 600;
        }

        .allTrainersListItem_InfoMiniSubtitle {
            font-size: 0.75rem;
            line-height: 1.5;
            color: $secondary-color;
        }
    }
}

.topTrainers {
    display: grid;
    width: 100%;
    margin: 0;
    margin-bottom: 1.5rem;

    grid-template-columns: auto 1fr;

    &:last-child {
        margin-bottom: 0;
    }

    .tranierTableCounter {
        font-size: 1rem;
        line-height: 1.5;
        color: $secondary-color;
        font-weight: 600;
        width: 2rem;
        min-width: 2rem;
    }

    .trainerImg {
        font-size: 1rem;
        background-color: $disable-color;
        line-height: 3rem;
        height: 3rem;
        width: 3rem;
        border-radius: $card-border-radius;
        color: $secondary-color;
        margin-right: 1.5rem;
    }

    .trainerName {
        font-size: 1rem;
        line-height: 1.5;
        color: $secondary-color;
        font-weight: 600;
    }

    .trainerExpertise span {
        font-size: 0.75rem;
        line-height: 1.5;
        color: $disable-color;
        padding-bottom: 0.25rem;
    }
}

.trainingDetailTable {
    .tableScroll {
        margin: 0;
        width: 100%;
    }
}

#trainingDetailTable {
    width: 100%;
    margin: 0;
    .table-counter {
        width: 80px;
    }
    td:first-child,
    th:first-child {
        min-width: auto;
    }
    > tbody > tr > td {
        vertical-align: middle;
    }
}

.trainerDetail .profilePicture {
    width: 3em;
    min-width: 3em;
    height: 3em;
    min-height: 3em;
    line-height: 3em;
    border-radius: $card-border-radius;
    background-color: $disable-color;
    margin-right: 1.5rem;
    text-align: center;
    color: $secondary-color;
    font-size: 2.5rem;
    font-weight: 600;
}
